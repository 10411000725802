import {
    Box,
    Button,
    Container,
    FormControl,
    Heading,
    Input,
    VStack,
    Image,
    useToast,
  } from "@chakra-ui/react";
  import { useMutation } from "@tanstack/react-query";
  import React, { useState, useEffect } from 'react';
  import { useForm } from "react-hook-form";
  import { useParams } from "react-router-dom";
  import styled from 'styled-components';
  import { partnerPhoto, getUploadURL, uploadImage } from "../../api";
  import useHostOnlyPage from "../../components/HostOnlyPage";
  import ProtectedPage from "../../components/ProtectedPage";
  
  
  const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px;
    margin-top: 30px;
  `;
  
  const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;
  
  const DeleteButton = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #000;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      transform: scale(1.1);
    }
  `;
  
  const AddImageButton = styled.label`
    display: block;
    max-width: 300px;
    margin: 16px auto 0;
    margin-top: 50px;
    border-radius: 8px;
    border: 2px solid #000;
    padding: 16px;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    color: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  
    &:hover {
      background-color: #000;
      color: #fff;
    }
  
    input[type='file'] {
      display: none;
    }
  `;
  
  interface IForm {
    file: FileList;
  }
  
  interface IUploadURLResponse {
    id: string;
    uploadURL: string;
  }
  
  export default function PartnerPhotos() {
    const { register, handleSubmit, watch, reset } = useForm<IForm>();
    const { roomPk } = useParams();
    const toast = useToast();
    
    const createPhotoMutation = useMutation(partnerPhoto, {
      onSuccess: () => {
        toast({
          status: "success",
          title: "Image uploaded!",
          isClosable: true,
          description: "Feel free to upload more images.",
        });
        reset();
      },
    });
  
    const uploadImageMutation = useMutation(uploadImage, {
      onSuccess: ({ result }: any) => {
        if (roomPk) {
          createPhotoMutation.mutate({
            description: "I love react",
            file: `https://imagedelivery.net/oLYkULxzV08FzxSwstfR3w/${result.id}/partner`,
            roomPk,
          });
        }
      },
    });
  
    const uploadURLMutation = useMutation(getUploadURL, {
      onSuccess: (data: IUploadURLResponse) => {
        uploadImageMutation.mutate({
          uploadURL: data.uploadURL,
          file: watch("file"),
        });
      },
    });
  
    const onSubmit = () => {
      uploadURLMutation.mutate();
    };
  console.log(watch());
  
  {/*멀티 사진 업로드 삭제*/}
    const [images, setImages] = useState<File[]>([]);
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files) {
        const filesArray = Array.from(files);
        const newImages = [...images, ...filesArray].slice(0, 20);
        const totalSize = newImages.reduce((acc, image) => acc + image.size, 0);
        if (totalSize > 500000000) {
          alert('Total file size exceeds 500MB limit');
        } else {
          setImages(newImages);
        }
      }
    };
    const handleDeleteImage = (index: number) => {
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
    };
  
    return (
      <>
      <ProtectedPage>
      <Container>
            <Heading mt={10} textAlign={"center"}>Upload a Photo</Heading>
            <VStack
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              spacing={5}
              mt={10}
            >
  
  
        <FormControl>
          Add Images
          <Input {...register("file")} type="file" accept="image/*" onChange={handleFileChange} />
        </FormControl>
        <Grid>
          {images.map((image, index) => (
            <ImageContainer key={index}>
              <img src={URL.createObjectURL(image)} alt="preview" />
              <DeleteButton onClick={() => handleDeleteImage(index)}>X</DeleteButton>
            </ImageContainer>
          ))}
        </Grid>
        <Button
                isLoading={
                  createPhotoMutation.isLoading ||
                  uploadImageMutation.isLoading ||
                  uploadURLMutation.isLoading
             
                }
                type="submit"
                w="50%"
                bg="#6B4EFF"
                color={"white"}
              >
                Upload
              </Button>
              </VStack>
          </Container>
          </ProtectedPage>
      </>
    );
  };