import { useForm } from "react-hook-form";
import {
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  } from "@chakra-ui/react";
  import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
  import { useNavigate, useParams } from "react-router-dom";
  import { 
    IDeleteLeaderApplyVariables,
    deleteLeaderApply, 
  } from "../../api";
  import { useRef } from "react";


  interface DeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  interface IDeleteLeaderForm {
    leaderApplyId: string;
  }
  
  export default function DeleteLeaderModal({ isOpen, onClose }: DeleteModalProps) {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const { moimId, leaderApplyId } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm<IDeleteLeaderForm>();
      console.log(errors);
    const mutation = useMutation(deleteLeaderApply, {
      onSuccess: () => {
        toast({ 
          status: "success",
          title: "CANCEL",
          description: "리더 등록을 삭제했습니다.",
        });
        navigate(`/moims/${moimId}`);
        onClose();
        queryClient.refetchQueries(["me"]);

      },
    });

    const onSubmit = (data: IDeleteLeaderApplyVariables) => {
      if (leaderApplyId) {
          data["leaderApplyId"] = leaderApplyId;
          mutation.mutate(data);
      }
  };

    return (
        
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogHeader>지원서 삭제</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody >
            리더 등록을 삭제합니다. 리더를 원하면 다시 등록을 해주세요.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                아니오
              </Button>
              <Button 
                isLoading={mutation.isLoading}
                colorScheme="red" 
                ml={3}
                type="submit"
                >
                예
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
    )
  }

  