import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Heading,
    Input,
    InputGroup,
    Select,
    Text,
    Textarea,
    useToast,
    VStack,
    ButtonGroup,
  } from "@chakra-ui/react";
  import { ReactNode, useEffect, useRef } from "react";
  import { useForm } from "react-hook-form";
  import { useParams } from "react-router-dom";
  import { useMutation, useQuery } from "@tanstack/react-query";
  import {
    getAllConcepts,
    getCategories,
    IPostPartnersVariables,
    postPartners,
  } from "../../api";
  import useHostOnlyPage from "../../components/HostOnlyPage";
  import ProtectedPage from "../../components/ProtectedPage";
  import { IConcept, ICategory, IPartnerDetail } from "../../types";
  import { useNavigate } from "react-router-dom";
  import React, { useState } from "react";
  import CountryList from "react-select-country-list";
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import StyledButton from "../../components/market/StyledButton";

  {/*업로드 룸*/}
  export default function PartnerUpload() {
    const [step, setStep] = useState(1);

    const prevStep = () => {
        setStep((prev) => prev - 1);
    };

    const nextStep = () => {
        setStep((prev) => prev + 1);
    };

    const disabledBtnStyle = {
        backgroundColor: "#DFE1E1",
        cursor: "not-allowed",
    };

    interface IBaseProps {
        children: ReactNode;
    }

    const { 
      register,
      handleSubmit,
      getValues,
      setValue,
		  formState: { errors },
    } = useForm<IPostPartnersVariables>();
    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation(postPartners, {
      onSuccess: (data: IPartnerDetail) => {
        toast({
          status: "success",
          title: "create",
          position: "bottom-right",
        });
        navigate(`/partners/${data.id}`);
      },
    });
    const { data: concepts } = useQuery<IConcept[]>(["concepts"], getAllConcepts);
    const { data: categories } = useQuery<ICategory[]>(
      ["categories"],
      getCategories
    );
    const { roomPk } = useParams();
    const onSubmit = (data: IPostPartnersVariables) => {
        mutation.mutate(data);
      };
    {/*리액트 국가 패키지 */}
    const [country, setCountry] = useState("");

    const handleChange = (val: string) => {
        setCountry(val);
    };
    const Base = ({ children }: IBaseProps) => (
        <ProtectedPage>
    <VStack pb={20} mt={10} px={{ base: 10, lg: 40}}>
        <VStack p={10} pt={12} spacing={3}>
            {children}
            </VStack>
            </VStack>
        </ProtectedPage>
    );
        
    const Step1 = () => {
        useEffect(() => {
        window.scrollTo(0, 0);
        }, []);

    return (
        <>

            <HelmetProvider>
              <Helmet>
                <title>Partners</title>
              </Helmet>
            </HelmetProvider>
              <Box pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>Partner Registration</Heading>
              </Box>
        
            {/*네임 폼*/}
              <FormControl>
                <FormLabel>Company</FormLabel>
              <Input
                {...register("company", { required: true })}
                required
                type="text"
              />
                <FormHelperText>Write the name of your Company name.</FormHelperText>
              </FormControl>
            
            {/*카테고리 폼*/}
              <FormControl>
              <FormLabel>Category</FormLabel>
              <Select
                {...register("category", { required: true })}
                placeholder="Choose a category"
              >
                {categories?.map((category) => (
                  <option key={category.pk} value={category.pk}>
                    {category.name}
                  </option>
                ))}
              </Select>
              <FormHelperText>
              Please choose a category.
              </FormHelperText>
            </FormControl>

            {/*타입 폼*/}
            <FormControl>
                <FormLabel>Type</FormLabel>
              <Select
                {...register("type", { required: true })}
                placeholder="Choose a kind"
              >
                  <option value="showroom">Showroom</option>
                  <option value="multistore">Multistore</option>
                </Select>
                <FormHelperText>
                Please choose the type.
                </FormHelperText>
              </FormControl>

            {/*컨츄리 폼*/}
            <FormControl>
                <FormLabel>Country</FormLabel>
              <Select
                {...register("country", { required: true })}
                placeholder="Choose a country"
              >
                 {CountryList().getData().map((country) => (
                    <option key={country.value} value={country.label}>
                    {country.label}
                    </option>
                ))}
                </Select>
              </FormControl>

              {/*도시 폼*/}
              <FormControl>
                <FormLabel>City</FormLabel>
              <Input
                {...register("city", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*주소 폼*/}
              <FormControl>
                <FormLabel>Address</FormLabel>
              <Input
                {...register("address", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*전화 폼*/}
              <FormControl>
                <FormLabel>Tel</FormLabel>
              <Input
                {...register("tel", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*홈페이지 폼*/}
              <FormControl>
                <FormLabel>Homepage</FormLabel>
              <Input
                {...register("homepage", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*sns 폼*/}
              <FormControl>
                <FormLabel>SNS</FormLabel>
              <Input
                {...register("sns", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*브랜드 리스트 폼*/}
              <FormControl>
                <FormLabel>Brand List</FormLabel>
              <Textarea {...register("list", { required: true })} />
              </FormControl>

            {/*컨셉 폼*/}
            <FormControl>
              <FormLabel>Concept</FormLabel>
              <Grid templateColumns={"1fr 1fr"} gap={5}>
                {concepts?.map((concept) => (
                  <Box key={concept.pk}>
                    <Checkbox
                      value={concept.pk}
                      {...register("concept", { required: true })}
                    >
                      {concept.name}
                    </Checkbox>
                  </Box>
                ))}
              </Grid>
            </FormControl>

            {/*설명 폼*/}
            <FormControl>
                <FormLabel>Information</FormLabel>
              <Textarea {...register("description", { required: true })} />
              </FormControl>

            <ButtonGroup
            gap="4"
            w={"100%"}
            display={"grid"}
            gridTemplateColumns={"1fr 1fr"}
          >
            <Button
              mt={5}
              borderRadius={150}
              bg="#6B4EFF"
              color={"white"}
              isDisabled
              _disabled={disabledBtnStyle}
            >
              &larr; Previous
            </Button>
            <Button
              mt={5}
              borderRadius={150}
              bg="#6B4EFF"
              color={"white"}
              onClick={() => {
       
                  nextStep();
              }}
            >
              Next &rarr;
            </Button>
          </ButtonGroup>
      
        </>
    );
};
              
const Step2 = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
      <>
            <Box pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>Buyer registration</Heading>
            </Box>

              {/*바이어 이름 폼*/}
              <FormControl>
                <FormLabel>First Name</FormLabel>
              <Input
                {...register("first_name", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*바이어 성 폼*/}
              <FormControl>
                <FormLabel>Last Name</FormLabel>
              <Input
                {...register("last_name", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*이메일 폼*/}
              <FormControl>
                <FormLabel>E-mail</FormLabel>
              <Input
                {...register("email", { required: true })}
                required
                type="text"
              />
              </FormControl>
                
            {/*핸드폰 폼*/}
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <InputGroup>
                <Input
                  {...register("phone", { required: true })}
                  type="text"
                  min={0}
                />
                </InputGroup>
              </FormControl>

              <ButtonGroup
              gap="4"
              w={"100%"}
              display={"grid"}
              gridTemplateColumns={"1fr 1fr"}
            >
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                onClick={prevStep}
              >
                &larr; Previous
              </Button>
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                isDisabled
                _disabled={disabledBtnStyle}
              >
                Next &rarr;
              </Button>
            </ButtonGroup>
            <VStack py={6}>
            {mutation.isError ? (
                              <Text color={"red.500"}>Wrong</Text>
                          ) : null}
              <StyledButton
              btnName={"Upload"}
              hasArrow={true}
              onClick={handleSubmit(onSubmit)}
              isLoading={mutation.isLoading}
            />
          </VStack>
    </>
  );
};    
switch (step) {
  case 1:
    return (
      <Base>
        <Step1 />
      </Base>
    );
  case 2:
    return (
      <Base>
        <Step2 />
      </Base>
    );
  default:
    return null;
}
};