import { 
  Box, 
  Button, 
  Grid,
  GridItem, 
  Text, 
  useToast, 
  VStack, 
  Heading,
  useColorMode,
  AlertDialog, 
  AlertDialogBody, 
  AlertDialogFooter, 
  AlertDialogHeader, 
  AlertDialogContent, 
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { cancelBooking, getBookings } from "../api";
import { IBooking } from "../types";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useRef, useState } from "react";

export default function MyBookings() {
  const { colorMode } = useColorMode();
  const { isLoading, data: booking } = useQuery<IBooking[]>(["bookings"], getBookings);
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation(cancelBooking, {
    onSuccess: (data, variables) => {
      toast({
        status: "success",
        title: "Successfully canceled!",
        isClosable: true,
      });
      queryClient.setQueryData<IBooking[]>(
        ["bookings"],
        (oldData) => oldData?.filter((booking) => booking.id !== variables) ?? []
      );
    },
  });

  {/*캔슬버튼모달*/}
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();

  const onClick = (id: number) => {
  setSelectedId(id);
  setIsOpen(true);
};

const handleCancel = () => {
  if (selectedId !== null) {
    mutation.mutate(selectedId);
  }
  onClose();
};
  
  const filteredBookings = booking?.filter((booking) => booking.not_canceled);

  return (
    <>
    {booking?.map((booking) => (
    <AlertDialog
      key={booking.id}
      motionPreset='slideInBottom'
      isOpen={isOpen && selectedId === booking.id}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >

        <AlertDialogContent shadow="md" maxWidth={{ base: "90%", md: "40%" }}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Cancel Booking
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to cancel this booking?<br />
            If you want to book again, please make a new booking!
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button  bg={colorMode === "dark" ? "#C1D5C0" : "#C1D5C0"} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button onClick={handleCancel} ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
  
    </AlertDialog>
    ))}

    <Box
      mt={10}
      px={{
        base: 10,
        lg: 20,
      }}
    >
    <HelmetProvider>
      <Helmet>
        <title>PASHOP</title>
    </Helmet>
  </HelmetProvider>

      <Text 
      display={"block"} 
      mb={8} 
      as={"b"} 
      fontSize={{ base: "20px", lg: "30px" }}
      >
        My Bookings
      </Text>
      <Box mb={4}>
        <Text as={"b"}>
            Total of {filteredBookings?.length} 
        </Text>
      </Box>
      <Grid
         templateColumns={{
          base: "1fr 1fr",   
          md: "repeat(3, 1fr)",      
          lg: "repeat(4, 1fr)",    
        }}
        gap={3}
        w={"100%"}
        bg={colorMode === "dark" ? "#C1D5C0" : "#C1D5C0"}
        alignItems={"center"}
        justifyItems="center"
        borderTop={"1px solid gray"}
        borderBottom={"1px solid rgb(190,190,190)"}
        py={4}
        mb={2}
        color="black"
      >
        <GridItem as={"b"}>Company</GridItem>
        <GridItem as={"b"}>Guests</GridItem>
        <GridItem as={"b"}>In</GridItem>
        <GridItem as={"b"}>Out</GridItem>
      </Grid>
     
      {/* skeleton */}
      {booking?.filter((booking) => booking.not_canceled).map((booking) => (
        <Grid
          key={booking.id}
          templateColumns={{
            base: "1fr 1fr",    
            md: "repeat(3, 1fr)",      
            lg: "repeat(4, 1fr)",    
          }}
          gap={3}
          w={"auto"}
          bgColor="white.200"
          alignItems={"center"}
          justifyItems="center"
          borderTop={"1px solid rgb(190,190,190)"}
          borderBottom={"1px solid rgb(190,190,190)"}
          py={3}
          mb={1}
          fontWeight={"400"} fontSize={{ base: "12px", lg: "16px" }}
        >
          <GridItem fontWeight={"400"} noOfLines={1}>
          <Link to={`/rooms/${booking?.room?.id}`}>
  <Text noOfLines={1} _hover={{ color: "red.500" }}>
    {booking?.room?.name}
  </Text>
</Link>

          </GridItem>
          <GridItem fontWeight={"400"}>
            {booking.guests} Guest
            {booking.guests === 1 ? "" : "s"} 
          </GridItem>
          <GridItem fontWeight={"400"}>{booking.check_in}</GridItem>
          <GridItem fontWeight={"400"}>{booking.check_out}</GridItem>
          {booking.not_canceled ? (
            <GridItem
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              color={"blue.500"}
              fontWeight={"400"}
            >
              <Text as={"b"}>Available</Text>
              <Button
                onClick={() => onClick(booking.id)}
                color={"red.500"}
                mt={2}
              >
                Cancel
              </Button>
            </GridItem>
          ) : (
            <GridItem
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              color={"red.500"}
              fontWeight={"400"}
            >
              <Text as={"b"}>Canceled</Text>
              <Button disabled mt={2}>
                Cancel
              </Button>
            </GridItem>
          )}
        </Grid>
      ))}
     {filteredBookings?.length === 0 ? (
						<VStack>
							<Heading fontSize={"lg"}>No bookings found!</Heading>
						</VStack>
					) : null}
      
    </Box>
    </>
  );
}