import {
    Box,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Container,
    Heading,
    Text,
    useToast,
    VStack,
    InputRightElement,
    FormControl,
    FormLabel,
    FormErrorMessage,
  } from "@chakra-ui/react";
  import React from "react";
  import { useNavigate } from "react-router-dom";
  import {  FaLock, FaCheckCircle } from "react-icons/fa";
  import { useForm } from "react-hook-form";
  import useHostOnlyPage from "../components/HostOnlyPage";
  import ProtectedPage from "../components/ProtectedPage";
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import { useMutation, useQueryClient } from "@tanstack/react-query";
  import {
    changePW,
    IChangePwVariables,
} from "../api";
import { IPassword } from "../types";
  
  export default function ChangePassword() {
    const {
      register,
      handleSubmit,
      watch,
      reset,
      formState: { errors },
    } = useForm<IChangePwVariables>();
    const toast = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const refreshPage = ()=>{
      window.setTimeout("window.location.reload()",2000);
   }
    const mutation = useMutation(changePW, {
      onSuccess: (data: IPassword) => {
        toast({
          status: "success",
          title: "New Password!",
          description: "Password change is complete.",
          position: "bottom-right",
        });
    },
      onError: () => {
        toast({
            status: "error",
            title: "Error!",
            description: "You've already changed your password.",
            position: "bottom-right",
        });
    },
    });
    const onSubmit = (data: IChangePwVariables) => {
      mutation.mutate(data);
    };

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    return (
        <ProtectedPage>
                <HelmetProvider>
                <Helmet>
                    <title>Password</title>
                </Helmet>
                </HelmetProvider>
                <Box pb={40} mt={10} px={{ base: 10, lg: 40 }}>
                    <Container>
                        <Heading textAlign={"center"}>Change Password</Heading>
                        <VStack
                            spacing={10}
                            as="form"
                            mt={5}
                            onSubmit={handleSubmit(onSubmit)}
                        >
        {/*올드 비밀번호*/}
        <FormControl isRequired isInvalid={Boolean(errors.old_password)}>
            <FormLabel>Old Password</FormLabel>
            <InputGroup size={"md"}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaLock />
                    </Box>
                  }
                />
                <Input
            {...register("old_password", {
              required: "Password is at least 8 characters long",
              minLength: {
                value: 8,
                message:
                  "a–z + A–Z + 0-9 + !@#$%^&",
              },
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message:
                  "a–z + A–Z + 0-9 + !@#$%^&",
              },
            })}
            variant={"filled"}
            type={show ? "text" : "password"}
          />
        <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
        </InputRightElement>
        </InputGroup>  
        <FormErrorMessage>
          {errors.old_password && errors.old_password.message}
        </FormErrorMessage>
        </FormControl>

        {/*새로운 비밀번호*/}
        <FormControl isRequired isInvalid={Boolean(errors.new_password)}>
            <FormLabel> New Password</FormLabel>
            <InputGroup size={"md"}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaCheckCircle />
                    </Box>
                  }
                />
                <Input
            {...register("new_password", {
              required: "Password is at least 8 characters long",
              minLength: {
                value: 8,
                message:
                  "a–z + A–Z + 0-9 + !@#$%^&",
              },
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message:
                  "a–z + A–Z + 0-9 + !@#$%^&",
              },
            })}
            variant={"filled"}
            type={show ? "text" : "password"}
          />
        <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
        </InputRightElement>
        </InputGroup>  
        <FormErrorMessage>
          {errors.new_password && errors.new_password.message}
        </FormErrorMessage>
        </FormControl>

        {/*새로운 비밀번호확인*/}
          <FormControl isRequired isInvalid={Boolean(errors.re_new_password)}>
            <FormLabel>Confirm New Password</FormLabel>
            <InputGroup>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaCheckCircle />
                    </Box>
                  }
                />
          <Input
            {...register("re_new_password", {
              required: "Please reconfirm your password",
              validate: (value) => {
                return value === watch('new_password') || "Please check your password"
              }
            })}
            variant={"filled"}
            type={show ? "text" : "password"}
          />
        <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
        </InputRightElement>
        </InputGroup>  
        <FormErrorMessage>
          {errors.re_new_password && errors.re_new_password.message}
        </FormErrorMessage>
        </FormControl>


            {mutation.isError ? (
            <Text color="red.500" textAlign={"center"} fontSize="sm">
             Password are wrong
            </Text>
          ) : null}
            <Button
            isLoading={mutation.isLoading}
            type="submit"
            mt={4}
            bg={'#5538EE'}
            color={'white'}
            w="100%"
            onClick={refreshPage}
            >
              Change Password
            </Button>
            </VStack>
             </Container>
            </Box>
        </ProtectedPage>
    );
  }