import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Skeleton,
    Text,
    VStack,
    Stack,
  } from "@chakra-ui/react";
  import { useQuery } from "@tanstack/react-query";
  import { useParams } from "react-router-dom";
  import {  
    getRoom, 
  } from "../api";
  import { IRoomDetail } from "../types";
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import { PhotoProvider, PhotoView } from "react-photo-view";
  import "react-photo-view/dist/react-photo-view.css";
  
  export default function PhotoRoom() {
    const { roomPk } = useParams();
    const { isLoading, data } = useQuery<IRoomDetail>([`rooms`, roomPk], getRoom);
  
    return (
      <Box
        pb={40}
        mt={10}
        px={{
          base: 10,
          "2xl": 80,
        }}
      >
        {/*웹 상단 탭*/}
        <HelmetProvider>
        <Helmet>
          <title>{data ? data.name : "Loading..."}</title>
        </Helmet>
        </HelmetProvider>
        {/*제목명*/}
        <HStack spacing="25px">
        <Skeleton 
        height={"43px"}
        isLoaded={!isLoading}
        >
        <Heading>{data?.name}</Heading>
        </Skeleton>
        </HStack>
  
        {/*사진 그리드 시작*/}
        <Grid
          mt={8}
          rounded="xl"
          overflow={"hidden"}
          gap={2}
          height="100%"
          templateRows={"1fr 1fr"}
          templateColumns={"repeat(3, 1fr)"}
        >
        <PhotoProvider speed={() => 800}>
          {data?.photos.slice(0,100).map((photo, index) => (
            <GridItem
              colSpan={index === 0 ? 2 : 1}
              rowSpan={index === 0 ? 2 : 1}
              overflow={"hidden"}
              key={photo.pk}
            >
              <Skeleton isLoaded={!isLoading} h="100%" w="100%">
              {data?.photos && data.photos.length > 0 ? (

        <PhotoView key={photo.pk} src={photo.file}>
                  <Image
                    objectFit={"cover"}
                    w="100%"
                    h="100%"
                    src={photo.file}
                    alt=""
                    order={photo.order}
                  />
        </PhotoView>

                ) : null}
              </Skeleton>
            </GridItem>
          ))}
        </PhotoProvider>
      </Grid>
  
      </Box>
    );
  }