import {
	FormControl,
	FormHelperText,
	FormLabel,
} from "@chakra-ui/form-control";
import {
    Box,
    Button,
    Checkbox,
    Container,
    InputLeftAddon,
    InputGroup,
    Select,
    useToast,
    Stack,
    Center,
    Avatar,
    AvatarBadge,
    IconButton,
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/input";
import { Heading, Text, VStack } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { getPortfolio, postPortfolio, putPortfolio, IPortfolioVariables, IEditPortfolioVariables } from "../../api";
import { LoadingPage } from "../../components/market/LoadingPage";
import StyledButton from "../../components/market/StyledButton";
import useUserProfile from "../../lib/useUserProfile";
import { Iportfolio } from "../../types";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

export const PortfolioRoot = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Outlet />
		</>
	);
};

export const PortfolioCreate = () => {
	const { 
		register, 
		handleSubmit,
		formState: { errors },
	   } = useForm<IPortfolioVariables>();
	  const toast = useToast();
	  const navigate = useNavigate();
	  const mutation = useMutation(postPortfolio, {
		  onSuccess: (data: Iportfolio) => {
			  toast({
				  status: "success",
				  title: "Portfolio 추가",
				  description: "Your Portfolio has been updated.",
				  position: "bottom-right",
			  });
			  navigate(`/`);
		  },
		  onError: (error) => {
			toast({
				title: "포트폴리오 생성 실패",
				status: "error",
			});
		  },
	  });
	  const onSubmit = (data: IPortfolioVariables) => {
		mutation.mutate(data);
	  };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<VStack spacing={3}>
			<Heading size={"md"}>포트폴리오 추가하기</Heading>
			<FormControl>
				<FormLabel>제목</FormLabel>
				<Input
						type="text"
						placeholder={"포트폴리오 제목"}
						isInvalid={Boolean(errors.title?.message)}
						{...register("title", {
							required: "포트폴리오 제목을 입력해주세요.",
						})}
					/>
				<FormHelperText>
					포트폴리오를 대표하는 제목을 설정해주세요.
				</FormHelperText>
			</FormControl>
			<FormControl>
				<FormLabel>설명</FormLabel>
				<Textarea
						placeholder="포트폴리오에 대한 자세한 설명"
						isInvalid={Boolean(errors.description?.message)}
						{...register("description", {
							required: "포트폴리오의 설명을 써주세요.",
						})}
					/>
				<FormHelperText>포트폴리오에 대해서 설명해주세요.</FormHelperText>
			</FormControl>
			<FormControl>
				<FormLabel>URL</FormLabel>
				<Input
						type="url"
						placeholder={"https://example.com"}
						isInvalid={Boolean(errors.url?.message)}
						{...register("url", {
							required: "URL을 입력해주세요.",
						})}
					/>
				<FormHelperText>포트폴리오 URL을 첨부해주세요.</FormHelperText>
			</FormControl>
			<hr />
			<StyledButton 
			onClick={handleSubmit(onSubmit)}
			btnName={"추가"} 
			/>
		</VStack>
	);
};

export const PortfolioEdit = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { portfolioId } = useParams();
	const { isLoggedIn, userLoading, profile } = useUserProfile();
	const navigate = useNavigate();
	
	const { 
		register, 
		handleSubmit,
		formState: { errors },
	   } = useForm<IEditPortfolioVariables>();
	  const toast = useToast();
	  const mutation = useMutation(putPortfolio, {
		  onSuccess: (data: Iportfolio) => {
			  toast({
				  status: "success",
				  title: "Portfolio 수정",
				  description: "Your Portfolio has been updated.",
				  position: "bottom-right",
			  });
			  navigate(`/`);
		  },
		  onError: (error) => {
			toast({
				title: "포트폴리오 수정 실패",
				status: "error",
			});
		  },
	  });

	const { isLoading, data } = useQuery<Iportfolio>({
		queryKey: [`portfolios`, portfolioId, `edit`],
		queryFn: () => getPortfolio(portfolioId),
	});

	
	  const onSubmit = (data: IEditPortfolioVariables) => {
		mutation.mutate(data);
	  };

	if (!isLoggedIn) navigate("/signin");
	else if (isLoading) return <LoadingPage />;
	else if (!data.is_owner) navigate("/");
	return (
		<VStack spacing={3}>
			<Heading size={"md"}>포트폴리오 수정하기</Heading>
			<FormControl>
				<FormLabel>제목</FormLabel>
				<Input
						type="text"
						placeholder={"포트폴리오 제목"}
						defaultValue={data.title}
						isInvalid={Boolean(errors.title?.message)}
						{...register("title", {
							required: "포트폴리오 제목을 입력해주세요.",
						})}
					/>
				<FormHelperText>
					포트폴리오를 대표하는 제목을 설정해주세요.
				</FormHelperText>
			</FormControl>
			<FormControl>
				<FormLabel>설명</FormLabel>
				<Textarea
						placeholder="포트폴리오에 대한 자세한 설명"
						defaultValue={data.description}
						isInvalid={Boolean(errors.description?.message)}
						{...register("description", {
							required: "포트폴리오의 설명을 써주세요.",
						})}
					/>
				<FormHelperText>포트폴리오에 대해서 설명해주세요.</FormHelperText>
			</FormControl>
			<FormControl>
				<FormLabel>URL</FormLabel>
				<Input
						type="url"
						placeholder={"https://example.com"}
						defaultValue={data.url}
						isInvalid={Boolean(errors.url?.message)}
						{...register("url", {
							required: "URL을 입력해주세요.",
						})}
					/>
				<FormHelperText>포트폴리오 URL을 첨부해주세요.</FormHelperText>
			</FormControl>
			<hr />
			<StyledButton 
			onClick={handleSubmit(onSubmit)}
			btnName={"수정"} 
			/>
			<StyledButton
				btnName={"포트폴리오 삭제하기"}
				themeColor={"#E3E5E5"}
				btnNameColor={"red"}
			/>
		</VStack>
	);
};