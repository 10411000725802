import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Image } from "@chakra-ui/image";
import { Input } from "@chakra-ui/input";
import {
	HStack,
	Link,
	Flex,
	Box,
	VStack,
	Stack,
	Heading,
	Grid,
} from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import StyledButton from "../../components/market/StyledButton";
import { SubHeader } from "../../components/market/SubHeader";
import {
	Avatar,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import { PortfolioCard } from "../../components/market/PortfolioCard";
import { MoimCardNoImage } from "../../components/market/MoimCardNoImage";
import useUserProfile from "../../lib/useUserProfile";
import {
	getMyMoimListAsCrew,
	getMyMoimListAsLeader,
	getMyMoimListAsOwner,
	getMyPortfolios,
	getMyProfile,
} from "../../api";
import { LoadingPage } from "../../components/market/LoadingPage";
import { ImoimDetail, Iportfolio } from "../../types";
import { useQuery } from "@tanstack/react-query";
import ProtectedPage from "../../components/ProtectedPage";
import MoimAvatar  from "../../components/market/MoimAvatar";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const MyProfileRoot = () => {
	return (
		<ProtectedPage>
			<Box minW={"327px"}>
				<Outlet />
			</Box>
		</ProtectedPage>
	);
};

export const MyProfile = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<VStack>
			 <HelmetProvider>
              <Helmet>
                <title>Portfolio</title>
              </Helmet>
              </HelmetProvider>
			  
              <Box mt={20} pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>MOIM</Heading>
              </Box>
		
			<Flex justifyContent="center">
				<Box width={{ base: "100%", md: "auto" }}>
			<Flex mt={20} flexDirection={{ base: "column", md: "row" }} alignItems="center">
			<MoimAvatar />

			<Box width={{ base: "100%", md: "10rem" }} height={{ base: "2rem", md: "auto" }}></Box>
			
			<Box 
			p={5}
			>
			<MyMoimListAsOwner />
			</Box>
    </Flex>
  </Box>
</Flex>
			
			</VStack>
		
	);
};

export const MyPortfolioList = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const gridColumnSystem = {
		base: "1fr", md: "1fr 3fr",
	};

	const { isLoading, data } = useQuery<Iportfolio[]>({
		queryKey: [`my-profile`, `portfolios`],
		queryFn: getMyPortfolios,
	});
	if (isLoading) return <LoadingPage />;
	else
		return (
			<VStack>
				<SubHeader
					headerTitle={"포트폴리오 관리"}
					hasBtn={true}
					btnName={"추가"}
					to={`/portfolios/create`}
				/>
				<Grid templateColumns={gridColumnSystem} gap={3} pt={12} w={"100%"}>
					{data.map((portfolio) => (
						<PortfolioCard
							key={portfolio.id}
							id={portfolio.id}
							title={portfolio.title}
							description={portfolio.description}
							url={portfolio.url}
						/>
					))}
				</Grid>
			</VStack>
		);
};

export const MyMoimListAsCrew = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { isLoading, data } = useQuery<ImoimDetail[]>(
		["my-profile", "moims", "crew"],
		getMyMoimListAsCrew
	);

	if (isLoading) return <LoadingPage />;
	else
		return (
			<VStack mt= {10} spacing={6}>
				<SubHeader headerTitle={`크루로 참여한 모임`} />
				<Flex justifyContent="center">
				<Grid 
				pt={12} 
				width={"100%"} 
				gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
				gap={6}>
					<VStack>
						{data ? (
							data?.map((moim) => (
								<MoimCardNoImage
									key={moim.id}
									id={moim.id}
									title={moim.title}
									topics={moim.topics}
									moimTypes={moim.moim_types}
									hasLeader={moim.has_leader}
									isClosed={moim.is_closed}
									min={moim.min_participants}
									current={moim.current_number_of_participants}
									expirationDate={moim.expiration_date}
									isCrew={moim.is_crew}
									isLeader={moim.is_leader}
									isOwner={moim.is_owner}
									typeOfPage={"crew"}
									hasApplied={moim.has_applied}
								/>
							))
						) : (
							<VStack alignItems={"center"}>
								<Heading size={"md"}>참여 신청한 모임이 없습니다!</Heading>
							</VStack>
						)}
					</VStack>
				</Grid>
				</Flex>
			</VStack>
		);
};

export const MyMoimListAsLeader = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { isLoading, data } = useQuery<ImoimDetail[]>(
		["my-profile", "moims", "leader"],
		getMyMoimListAsLeader
	);

	if (isLoading) return <LoadingPage />;
	else
		return (
			<VStack mt={10} spacing={6}>
				<SubHeader headerTitle={`리더로 지원한 모임`} />
				<Flex justifyContent="center">
				<Grid 
				pt={12} 
				width={"100%"} 
				gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
				gap={6}>
					<VStack>
						{data ? (
							data?.map((moim) => (
								<MoimCardNoImage
									key={moim.id}
									id={moim.id}
									title={moim.title}
									topics={moim.topics}
									moimTypes={moim.moim_types}
									hasLeader={moim.has_leader}
									isClosed={moim.is_closed}
									min={moim.min_participants}
									current={moim.current_number_of_participants}
									expirationDate={moim.expiration_date}
									isCrew={moim.is_crew}
									isLeader={moim.is_leader}
									isOwner={moim.is_owner}
									typeOfPage={"leader"}
									hasApplied={moim.has_applied}
								/>
							))
						) : (
							<VStack alignItems={"center"}>
								<Heading size={"md"}>리더로 지원한 모임이 없습니다!</Heading>
							</VStack>
						)}
					</VStack>
				</Grid>
				</Flex>
			</VStack>
		);
};
export const MyMoimListAsOwner = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { isLoading, data } = useQuery<ImoimDetail[]>(
		["my-profile", "moims", "owner"],
		getMyMoimListAsOwner
	);

	if (isLoading) return <LoadingPage />;
	else
		return (
			<VStack mt={10} spacing={6}>
				<SubHeader headerTitle={`내가 생성한 모임`} />
				<Flex justifyContent="center">
				<Grid 
				pt={12} 
				width={"100%"} 
				gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
				gap={6}>
					
						{data ? (
							data?.map((moim) => (
								<MoimCardNoImage
									key={moim.id}
									id={moim.id}
									title={moim.title}
									topics={moim.topics}
									moimTypes={moim.moim_types}
									hasLeader={moim.has_leader}
									isClosed={moim.is_closed}
									min={moim.min_participants}
									current={moim.current_number_of_participants}
									expirationDate={moim.expiration_date}
									isCrew={moim.is_crew}
									isLeader={moim.is_leader}
									isOwner={moim.is_owner}
									typeOfPage={"owner"}
									hasApplied={moim.has_applied}
								/>
							))
						) : (
							<VStack alignItems={"center"}>
								<Heading size={"md"}>생성한 모임이 없습니다!</Heading>
							</VStack>
						)}
				</Grid>
				</Flex>
			</VStack>
		);
};