import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import {
	FormControl,
	FormHelperText,
	FormLabel,
} from "@chakra-ui/form-control";
import { 
	Divider,
	Grid,
	Heading,
	HStack,
	Link,
	Text,
	VStack,
	Box,
	Container,
} from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import { useToast } from "@chakra-ui/toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { 
	getMatchings,
	getEditMatchings, 
	IMatchingsVariable,
	postMatchings,
	IEditMatchingVariable, 
	putMatchings, 
	getRooms,
	getBuyerMatch,
} from "../../api";
import { 
	IMatchings,
	IRoomDetail,
	IBuyerManage,
} from "../../types";
import ProtectedPage from "../../components/ProtectedPage";
import StyledButton from "../../components/market/StyledButton";
import useUserProfile from "../../lib/useUserProfile";
import DeleteMatchings from "../../components/buyer/BuyerDelete";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Icon,
	InputGroup,
	InputLeftElement,
	Input,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { FaSearch } from "react-icons/fa";



interface IMatchingsModalBtn {
	pk: number;
	name: string;
	year: string;
	season: string;
}

const MatchingsCardModalBtn = ({
	pk,
	name,
	year,
	season,
}: IMatchingsModalBtn) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Button variant={"link"} onClick={onOpen}>
				{name}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent pb={3}>
					<ModalCloseButton />
					<ModalHeader>Details</ModalHeader>
					<ModalBody borderRadius={15} as={VStack} alignItems={"flex-start"}>
						
						<Divider />
						<Heading pt={3} size={"sm"}>
							Info.
						</Heading>
						<Box
							p={6}
							border={"1px solid #DCDCDC"}
							borderRadius={15}
							width={"100%"}
							h={"max-content"}
						>
							{year}{season}
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

interface IForm {
	description: string;
    room: number;
    buyerPk: string;
}

export const MatchingCreate = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { buyerPk, roomPk } = useParams();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IMatchingsVariable>();

	const toast = useToast();
	const mutation = useMutation(postMatchings, {
		onSuccess: (data: IMatchings) => {
			toast({
				title: "Matching successful. Buyer will contact you soon.",
				status: "success",
			});
			navigate(`/buyer/${buyerPk}`);
		},
		onError: (error) => {
			toast({
				title: "Failed: Please try again.",
				status: "error",
			});
		},
	});

	const onSubmit = ({ description, room }: IMatchingsVariable) => {
		mutation.mutate({ description, room, buyerPk });
	};



	const { data: rooms } = useQuery<IRoomDetail[]>(
		[ `buyers`, buyerPk, ]
		,getRooms);	

	const [searchValue, setSearchValue] = useState("");	
	const filteredRooms =
		rooms &&
		rooms.filter(
			(room) =>
			room.name.toLowerCase().includes(searchValue.toLowerCase()) ||
			room.year.toLowerCase().includes(searchValue.toLowerCase()) ||
			room.season.toLowerCase().includes(searchValue.toLowerCase())
			);	

	return (
		<ProtectedPage>
			<VStack spacing={3} >
				<Heading size={"md"}>Buyer matching</Heading>
				<VStack
					pt={12}
					spacing={3}
					width={"50%"}
					h={"max-content"}
					overflow={"hidden"}
					>
				<FormControl>
					<FormLabel>What I want to say:</FormLabel>
					<Textarea
						placeholder={"What I want to say"}
						isInvalid={Boolean(errors.description?.message)}
						{...register("description", {
							required: "What I want to say",
						})}
					/>
					<FormHelperText>
                    Please write your message to the buyer.
					</FormHelperText>
				</FormControl>

				<FormControl>
				<FormLabel>Choose your brand</FormLabel>
				<VStack
					spacing={5}
					maxH={"250px"}
					overflow={"hidden"}
					overflowY={"scroll"}
					border={"1px solid #DEE3EE"}
					borderRadius={6}
				>
				<VStack mt={30}>
      <Box w="100%">
          <InputGroup size="md">
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={FaSearch} color="gray.300" />}
            />
            <Input
              type="text"
              placeholder="Search Brands..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </InputGroup>
      </Box>  
      </VStack>	
					<CheckboxGroup>
						<Divider />
						{filteredRooms && filteredRooms.length && filteredRooms.map((room) => (
							<Container key={room.pk} px={6} mt={0}>
								<HStack justifyContent={"space-between"} width={"100%"} pb={3}>
									<VStack alignItems={"flex-start"}>
										<MatchingsCardModalBtn
											pk={room.pk}
											name={room.name}
											year={room.year}
											season={room.season}
										/>
										<Text fontSize={"14px"} color={"#72777A"}>
											{room.year}.{room.season}
										</Text>
									</VStack>
									<Checkbox
										colorScheme="purple"
										value={`${room.pk}`}
										{...register("room", { required: true })}
									>
										Ok
									</Checkbox>
								</HStack>
								<Divider />
							</Container>
						))}
					</CheckboxGroup>
				</VStack>
				<FormHelperText>
				Choose your brand and let the buyer know.
				</FormHelperText>
			</FormControl>			


				</VStack>
				
				<StyledButton
					btnName={"Match"}
					onClick={handleSubmit(onSubmit)}
					isLoading={mutation.isLoading}
				/>
			</VStack>

		</ProtectedPage>
	);
};

export const MatchingsEdit = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const { isLoggedIn, userLoading } = useUserProfile();

	const { buyerPk, matchPk } = useParams();
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IEditMatchingVariable>();
	const toast = useToast();
	const mutation = useMutation(putMatchings, {
		onSuccess: (data: IForm) => {
			toast({
				title: "Application has been amended.",
				status: "success",
			});
			navigate(`/buyers/${buyerPk}`);
		},
		onError: (error) => {
			toast({
				title: "Failed: Please try again.",
				status: "error",
			});
		},
	});
	const onSubmit = ({ description, room }: IEditMatchingVariable) => {
		mutation.mutate({ description, room, buyerPk, matchPk });
	};
	const { data: Url } = useQuery<IForm>([
		`buyer`, buyerPk ,"matchings", matchPk, "edit"]
		,getEditMatchings);

    {/*Delete Click */}
	const {
		isOpen: isDeleteOpen,
		onClose: onDeleteClose,
		onOpen: onDeleteOpen,
	} = useDisclosure();
	return (
		
		<ProtectedPage>
		<VStack spacing={6}>
			<Heading size={"md"}>Buyer matching</Heading>

			<VStack
					pt={12}
					spacing={3}
					width={"50%"}
					h={"max-content"}
					overflow={"hidden"}
					>
			<FormControl>
				<FormLabel>What I want to say:</FormLabel>
				<Textarea
					placeholder={"What I want to say"}
					isInvalid={Boolean(errors.description?.message)}
					defaultValue={Url?.description}
					{...register("description", {
						required: "What I want to say.",
					})}
				/>
				<FormHelperText>
                Please write your message to the buyer.
				</FormHelperText>
			</FormControl>
			</VStack>
			<hr />
			<StyledButton
				btnName={"Edit"}
				onClick={handleSubmit(onSubmit)}
				isLoading={mutation.isLoading}
			/>
			<Button
						color = "red"
						width={327}
						borderRadius={48}
						onClick={onDeleteOpen}
						>
							Delete
							<DeleteMatchings 
                    isOpen={isDeleteOpen} 
                    onClose={onDeleteClose} 
                    />
			</Button>
			
		</VStack>
	</ProtectedPage>
		
	);
};