import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    Divider,
    Container,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Highlight,
    Input,
    Text,
    useToast,
    VStack,
    HStack,
    Textarea,
    Link,
    InputGroup,
    InputLeftAddon,
} from "@chakra-ui/react";
import { ExternalLinkIcon, DragHandleIcon } from '@chakra-ui/icons'
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
    getBusiness,
    putBusiness,
    IEditBusinessVariables,
} from "../../api";
import useHostOnlyPage from "../../components/HostOnlyPage";
import ProtectedPage from "../../components/ProtectedPage";
import { IBusiness } from "../../types";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useUser from "../../lib/useUser";

interface IEditBusiness {
    pk: number;
    nameko: string;
    nameen: string;
    email: string;
    phone: string;
    hscode: string;
    productko: string;
    producten: string;
    handlers: string;
    references: string;
    target: string;
    competitors: string;
    clients: string;
    waiting: boolean;
    completed: boolean;
    results: boolean;
    roomPk: string;
  }

export default function EditBusiness() {
    const { user } = useUser();
    const { 
      register, 
      handleSubmit,
      formState: { errors },
     } = useForm<IEditBusinessVariables>();
    const toast = useToast();
    const { roomPk } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    {/* 
    const refreshPage = ()=>{
      window.setTimeout("window.location.reload()",2000);
   }
   */}
    const mutation = useMutation(putBusiness, {
        onSuccess: (data: IBusiness) => {
            toast({
                status: "success",
                title: "사업파트너 연결 지원",
                description: "수정이 완료되었습니다.",
                position: "bottom-right",
            });
            navigate(`/`);
        },
        onError: (error) => {
          console.log("error")
        },
    });

 
    const onSubmit = (data: IEditBusinessVariables) => {
        if (roomPk) {
            data["roomPk"] = roomPk;
            mutation.mutate(data);
        }
    };

    return (
        <Box>
                <HelmetProvider>
                <Helmet>
                    <title>Business Partnership</title>
                </Helmet>
                </HelmetProvider>
                <Box pb={40} mt={10} px={{ base: 10, lg: 40 }}>
                    <Container>
                        <Heading textAlign={"center"}>사업파트너 연결 지원</Heading>
                        <VStack
                            spacing={10}
                            as="form"
                            mt={5}
                            onSubmit={handleSubmit(onSubmit)}
                        >
            {/*담당자정보*/}
            <VStack mt={10} w="100%" alignItems={"flex-start"}>
            <HStack>
            <DragHandleIcon/> 
            <Text as="b" fontSize="20px" color="green">담당자정보</Text>
            </HStack>
            <Divider />
            </VStack>

            {/*유저네임 폼*/}
            <FormControl>
                <FormLabel>아이디</FormLabel>
              <Input
                defaultValue={user?.username}
                type="text"
                variant={"filled"}
                readOnly
              />
              </FormControl>

              {/*nameko*/}
            <FormControl isRequired>
                <FormLabel>성명</FormLabel>
              <Input
             
                {...register("nameko", { 
                    required: true, 
                })}
                required
                type="text"
                
              />
              </FormControl>

            {/*nameen*/}
            <FormControl isRequired>
                <FormLabel>성명(영문)</FormLabel>
              <Input
              
                {...register("nameen", { 
                    required: true, 
                })}
                required
                type="text"
                
              />
              </FormControl>

            {/*email*/}
            <FormControl isRequired>
                <FormLabel>이메일</FormLabel>
              <Input
                {...register("email", { 
                    required: true, 
                    pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "ex) pvmlkorea@pvml.kr",
                      },
                })}
                required
                type="text"
               
              />
              </FormControl>

            {/*phone*/}
            <FormControl isRequired>
                <FormLabel>핸드폰</FormLabel>
                <InputGroup>
                <InputLeftAddon children='+82' />
                <Input
                {...register("phone", { 
                    required: true, 
                })}
                required
                type="text"
                
              />
               </InputGroup>
              </FormControl>
            

            {/*사업정보*/}
            <VStack w="100%" alignItems={"flex-start"}>
            <HStack>
            <DragHandleIcon/> 
            <Text as="b" fontSize="20px" color="green">사업정보</Text>
            </HStack>
            <Divider />
            </VStack>
            {/*hscode*/}
            <FormControl isRequired>
                <HStack>
                <FormLabel>HSCODE</FormLabel>
                <Link 
                href="https://dream.kotra.or.kr/cms/frCom/actionHsSearchPop.do" 
                isExternal
                >
                <Button 
                colorScheme='messenger' 
                variant='outline' 
                size='sm'
                mb={2}
                >
                HSCODE찾기 <ExternalLinkIcon mx='3px' />
                </Button>
                </Link>
                </HStack>
              <Textarea
                {...register("hscode", { 
                    required: true, 
                    maxLength: { value: 350, message: "최대 350자내 입니다." },
                })}
                required
               
              />
                <FormHelperText>
                <Alert status='warning'>
                    <AlertIcon />
                    문의: 관세청 고객지원센터(1577-8577)
                </Alert>
                </FormHelperText>
              </FormControl>
              
            {/*productko*/}
            <FormControl isRequired>
                <FormLabel>제품명 및 품목설명(한글)</FormLabel>
              <Textarea
                {...register("productko", { 
                    required: true, 
                    maxLength: { value: 350, message: "최대 350자내 입니다." },
                })}
                required
        
              />
                <FormHelperText>(한글 350자내 / 특징, 차별성 및 용도 등)</FormHelperText>
              </FormControl>

            {/*producten*/}
            <FormControl isRequired>
                <FormLabel>제품명 및 품목설명(영문)</FormLabel>
              <Textarea
                {...register("producten", { 
                    required: true, 
                    maxLength: { value: 700, message: "최대 700자내 입니다." },
                })}
                required
               
              />
                <FormHelperText>(영문 700자내 / 특징, 차별성 및 용도 등)</FormHelperText>
              </FormControl>

            {/*handlers*/}
            <FormControl isRequired>
                <FormLabel>제품 주요 사용처 및 취급업체</FormLabel>
              <Textarea
                {...register("handlers", { 
                    required: true, 
                    maxLength: { value: 150, message: "최대 150자내 입니다." },
                })}
                required
            
              />
                <FormHelperText>(예: 의류바이어, 백화점, 복합쇼핑몰)(한글 150자내)</FormHelperText>
              </FormControl>

            {/*references*/}
            <FormControl isRequired>
                <FormLabel>조사목적 및 참고사항</FormLabel>
              <Textarea
                {...register("references", { 
                    required: true, 
                    maxLength: { value: 700, message: "최대 700자내 입니다." },
                })}
                required
             
              />
                <FormHelperText>(조사 시 유의사항 및 국내 마케팅 현황 등)(한글 700자내)</FormHelperText>
              </FormControl>

            {/*target*/}
            <FormControl isRequired>
                <FormLabel>타겟 바이어 유형</FormLabel>
              <Textarea
                {...register("target", { 
                    required: true, 
                    maxLength: { value: 700, message: "최대 700자내 입니다." },
                })}
                required
               
              />
                <FormHelperText>(예: 제조업체, 에이전트, distributor)(한글 700자내)</FormHelperText>
              </FormControl>

            {/*competitors*/}
            <FormControl isRequired>
                <FormLabel>국내외 경쟁 회사(회사명, 홈페이지기재)</FormLabel>
              <Textarea
                {...register("competitors", { 
                    required: true, 
                    maxLength: { value: 700, message: "최대 700자내 입니다." },
                })}
                required
             
              />
                <FormHelperText>(귀사 제품 이해를 위해 도움)</FormHelperText>
              </FormControl>

            {/*clients*/}
            <FormControl isRequired>
                <FormLabel>기존거래처 유무</FormLabel>
              <Textarea
                {...register("clients", { 
                    required: true, 
                    maxLength: { value: 350, message: "최대 350자까지 입니다." },
                })}
                required
              
              />
                <FormHelperText>(귀사에서 이미 거래중인 바이어 접촉을 피하기 위해 필요함)</FormHelperText>
              </FormControl>
            
            <Alert 
            status='info'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            >
            <AlertIcon boxSize='30px' mr={0}/>
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                Application submitted!
            </AlertTitle>
            
            <AlertDescription maxWidth='sm'>
            <Highlight query='브랜드 소개서(영문)' styles={{ px: '1', py: '1', bg: 'orange.100' }}>
            아래 "사업신청" 버튼 클릭 후 
            customerservice@pvml.kr으로 브랜드 소개서(영문)를 보내주셔야 최종 완료됩니다.
            </Highlight>
            </AlertDescription>
            <Text mt={5} as='i' fontSize='11px' color='red.500'  >
            첨부파일 용량은 최대 50MB입니다 (PDF파일).
            </Text>
            </Alert>
            

                            {mutation.isError ? (
                                <Text color={"red.500"}>Wrong</Text>
                            ) : null}
                            <Button
                                type="submit"
                                isLoading={mutation.isLoading}
                                colorScheme={"red"}
                                size="lg"
                                w={"100%"}
                  
                            >
                                사업신청
                            </Button>
                        </VStack>
                    </Container>
                </Box>
        </Box>
    );
}