import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    VStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    useToast,
    ToastId,
    Select,
    Grid,
    GridItem,
  } from '@chakra-ui/react';
  import {
    MdPhone,
    MdEmail,
    MdLocationOn,
    MdOutlineEmail,
  } from 'react-icons/md';
  import { BsPerson, } from 'react-icons/bs';

  export default function ContactForm() {
    const toast = useToast();
    const toastIdRef = React.useRef<ToastId>();
    function addToast() {
        toastIdRef.current = toast({ 
            title: 'Email sent successfully!',
            description: "We will reply as soon as possible.",
            status: 'success',
            duration: 9000,
            isClosable: true,
        });
        window.setTimeout("window.location.reload()",2000);
      }

    const form = React.useRef(null);
    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
      "service_mc1sjaj",
      "template_92ngc99",
      e.currentTarget,
      "p3Xjtd-4l8fGWA5YE"
    )
      .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
    <Container 
       
        maxW="full" 
        mt={0} 
        centerContent 
        overflow="hidden"
    >
      <Flex>
   
        <Box
          bg="#02054B"
          color="white"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}>
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem>
                <Box>
                  <Heading>Register</Heading>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                  <Text as="b" mt={3} color="white.500">
                  Buyer
                  </Text>
                  <Text mt={3} mb={3}>
                  Can register at the  our platform you will 
                  <br /> be able to OrderSheet of our brands! 
                  </Text>

                  <Text as="b" mt={3} color="white.500">
                  Designer
                  </Text>
                  <Text mt={3}>
                  Can register at the  our platform 
                  <br /> you will be contacted by our team to 
                  <br /> eventually determinate the market dates.
                  </Text>
                  </Box>

                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                      <Button
                        size="md"
                        height="48px"
                        width="300px"
                        variant="ghost"
                        color="#DCE2FF"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                        +82 70-8648-1903
                      </Button>
                      <Button
                        size="md"
                        height="48px"
                        width="300px"
                        variant="ghost"
                        color="#DCE2FF"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                        customerservice@pvml.kr
                      </Button>
                      <Button
                        size="md"
                        height="48px"
                        width="300px"
                        variant="ghost"
                        color="#DCE2FF"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
                        Seoul, Korea
                      </Button>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
  
              <WrapItem>
                <Box bg="white" borderRadius="lg">
                  <Box m={8} color="#0B0E3F">
                    <VStack spacing={5}>

                    <FormControl id="name">
                      <FormLabel>Register As</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                    <Select name="user_type">
                        <option value="buyer">Buyer</option>
                        <option value="designer">Designer</option>
                      </Select>
                    
                      </InputGroup>
                    </FormControl>

                      <FormControl id="name">
                        <FormLabel>Your Name</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BsPerson color="gray.800" />}
                          />
                          <Input type="text" size="md" name="user_name" />
                        </InputGroup>
                      </FormControl>

                      <FormControl id="name">
                        <FormLabel>Phone</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<MdPhone color="gray.800" />}
                          />
                          <Input type="text" size="md" name="user_phone" />
                        </InputGroup>
                      </FormControl>

                      <FormControl id="name">
                        <FormLabel>Mail</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<MdOutlineEmail color="gray.800" />}
                          />
                          <Input type="email" size="md" name="user_email" />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Message</FormLabel>
                        <Textarea
                          borderColor="gray.300"
                          _hover={{
                            borderRadius: 'gray.300',
                          }}
                          placeholder="message"
                          name="message"
                        />
                      </FormControl>
                        <Button
                          type="submit"
                          variant="solid"
                          bg="#0D74FF"
                          color="white"
                          _hover={{}}
                          onClick={addToast}
                          >
                          Send Message
                        </Button>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
    </form>
    
  );
};

