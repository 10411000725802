import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Skeleton,
    Text,
    VStack,
    Stack,
    Card, 
    CardHeader, 
    CardBody,
    StackDivider, 
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Tabs, 
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel,
    Spacer,
    Tag,
    useDisclosure,
  } from "@chakra-ui/react";
  import { FaPencilAlt, FaStar } from "react-icons/fa";
  import { MdDelete, MdAddCircleOutline, MdPhotoLibrary } from "react-icons/md";
  import { useMutation, useQuery } from "@tanstack/react-query";
  import { 
    getPartner, 
    getConcepts,
  } from "../../api";
  import { IConcept, IPartnerDetail } from "../../types";
  import React, { useEffect } from 'react';
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import { Link, useNavigate, useParams } from "react-router-dom";
  import useUser from "../../lib/useUser";
  import { PhotoProvider, PhotoView } from "react-photo-view";
  import "react-photo-view/dist/react-photo-view.css";
  import { useForm } from "react-hook-form";
  import DeletePartner from "../../components/partner/PartnerDelete";
  import useUserProfile from "../../lib/useUserProfile";
  import ProtectedPage from "../../components/ProtectedPage"
  
  
  export default function PartnerDetail() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const { profile } = useUserProfile();
    const { roomPk } = useParams();
    const { isLoading, data } = useQuery<IPartnerDetail>([`partners`, roomPk], getPartner);

  {/*개인컨셉 */}
    const { data: concepts, isLoading: isAmenitiesLoading } = useQuery<IConcept[]>
    ([`partners`, roomPk, `concepts`], getConcepts);
  
  {/*Delete Click */}
    const {
      isOpen: isDeleteOpen,
      onClose: onDeleteClose,
      onOpen: onDeleteOpen,
    } = useDisclosure();
  
  {/*Edit room Click */}
    const onEditClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      navigate(`/partners/${roomPk}/edit`);
  };

  {/*Upload Photo Click */}
  const onCameraClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/partners/${roomPk}/photos`);
};
  const addSchemaToUrl = (url: string | undefined) => {
    if (url && !url.startsWith('http')) {
      return `http://${url}`;
    }
    return url;
  };
  
    return (
      <ProtectedPage>
      <Box
        pb={40}
        mt={10}
        px={{
          base: 10,
          "2xl": 80,
        }}
      >
        {/*웹 상단 탭*/}
        <HelmetProvider>
        <Helmet>
          <title>{data ? data.company : "Loading..."}</title>
        </Helmet>
        </HelmetProvider>
        {/*제목명*/}
        <HStack spacing="25px">
        <Skeleton 
        height={"43px"}
  
        isLoaded={!isLoading}
        >
          <Heading>{data?.company}</Heading>
        </Skeleton>
        </HStack>

        <HStack mt={5} spacing="10px">
        {/*카메라버튼*/}
        {data?.is_owner ? (
                    <Button onClick={onCameraClick}>
                    <MdPhotoLibrary />
                    </Button>
                ) : null}

        {/*수정버튼*/}
        {data?.is_owner ? (
                      <Button onClick={onEditClick}>
                          <FaPencilAlt />
                      </Button>
                  ) : null}
        {/*삭제버튼*/}
        {data?.is_owner ? (
                      <Button onClick={onDeleteOpen}>
                      <MdDelete />
                      <DeletePartner 
                      isOpen={isDeleteOpen} 
                      onClose={onDeleteClose} 
                      />
                      </Button>
                  ) : null}
        </HStack>

        {/*사진 그리드 시작*/}
        <Grid
          mt={8}
          w={"100%"}
          rounded="xl"
          overflow={"hidden"}
          gap={2}
          templateRows={"1fr"}
          templateColumns={"repeat(1fr)"}
        >
          <PhotoProvider speed={() => 800}>
          {data?.photos.slice(0,1).map((photo, index) => (
            
              <Skeleton isLoaded={!isLoading} h="100%" w="100%">
              {data?.photos && data.photos.length > 0 ? (
        <PhotoView src={photo.file}>
                  <Image
                    objectFit={"cover"}
                    rounded={'md'}
                    align={'center'}
                    w={'100%'}
                    h={{ base: '100%', sm: '400px', lg: '500px' }}
                    src={photo.file}
                    alt=""
                  />
        </PhotoView>
                ) : null}
              </Skeleton>
           
          ))}
          </PhotoProvider>
        
      </Grid>
  
        {/*사진 아래 호스트 명*/}
    
        <Grid
        mt={10}
        px={{
          base: 10,
          lg: 10,
          "2xl": 30,
        }}
    
        gap={30}
        templateColumns={{
          base: "1fr",
          sm: "1fr",
          md: "2fr 1fr",
          lg: "3fr 1fr",
          xl: "4fr 1fr",
          "2xl": "5fr 1fr",
        }}
        justifyContent="center"
      >
          <Box  
          w='100%'
          px={{ base: 5 }}
          >
            <Box p="3" >
            <HStack justifyContent={"flex-start"}>
              <VStack alignItems={"flex-start"}>
              <Text
              bgGradient='linear(to-l, #7928CA, #FF0080)'
              bgClip='text'
              fontSize='6xl'
              fontWeight='extrabold'
                >
                Hello
            </Text>
                <Skeleton isLoaded={!isLoading} height={"30px"}>
                  <Heading
                  bgGradient='linear(to-l, #7928CA, #FF0080)'
                  bgClip='text'
                  fontWeight='extrabold'       
                  fontSize="2xl"
                  textTransform='uppercase'
                  >
                  @{data?.company}
                  </Heading>
                </Skeleton>
                <Skeleton isLoaded={!isLoading} height={"30px"}>
                  <HStack justifyContent={"flex-start"} mt={"10%"}>
                  <Tag
                    borderRadius={100}
                    fontSize={"xs"}
                    bg={"#6B4EFF"}
                    color={"white"}
                    textTransform='uppercase'
                        >
                            #{data?.type}
                    </Tag>
                    <Tag 
                    fontSize={"xs"}  
                    borderRadius={100}
                    textTransform='uppercase'
                    >
                            #{data?.category.name}
                    </Tag>
                  </HStack >
                  </Skeleton>
              </VStack>
              <Spacer />
             
            </HStack>
            </Box>
      </Box>
  <VStack />
  
  {/*설명 부분 */}
  <Box px={{ base: 5 }}>
  <Box 
    w="100%" 
    borderWidth="1px" 
    borderRadius="lg" 
    overflow="hidden"
    >
    <Tabs size={{ base: "sm", md: "md" }} isFitted variant="enclosed">
      <TabList>
        <Tab as="i">Company</Tab>
        <Tab as="i">Buyer</Tab>
      </TabList>
  
      <TabPanels>
      <TabPanel>
        <Card mt={"4"}>
    <CardHeader>
      <Heading size='md'>Brand Report</Heading>
    </CardHeader>
    <CardBody>
      <Stack divider={<StackDivider />} spacing='4'>
      <Box>
          <Heading size='xs' textTransform='uppercase'>
            Company
          </Heading>
          <Text pt='2' fontSize='sm'>
          {data?.company}
          </Text>
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
          Country & City
          </Heading>
          
          <Text pt='2' fontSize='sm'>
          {data?.country}∙{data?.city}
          </Text>
      
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
            Address
          </Heading>
          <Text pt='2' fontSize='sm' textTransform='uppercase'>
          {data?.address}
          </Text>
        </Box>
        <Box>
        <Heading size='xs' textTransform='uppercase'>
      Homepage
    </Heading>
    <Text pt='2' fontSize='sm'>
      <span
        onClick={() => window.open(addSchemaToUrl(data?.homepage), '_blank')}
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        {data?.homepage}
      </span>
    </Text>
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
            Tel
          </Heading>
          <Text pt='2' fontSize='sm'>
          {data?.tel}
          </Text>
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
            SNS
          </Heading>
          <Text pt='2' fontSize='sm'>
          {data?.sns}
          </Text>
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
            Brand Lists
          </Heading>
            <Grid
                templateColumns="repeat(1, 1fr)"
                gap={4}
                mt={4}
            >
            <Text>{data?.list}</Text>
            </Grid>
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
            Concepts
          </Heading>
            <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                mt={4}
            >
                {concepts && concepts.map((concept) => (
                    <Tag 
                    fontSize={"xs"}  
                    borderRadius={100}
                    textTransform='uppercase'
                    justifyItems={"center"}
                    key={concept.pk}>
                       
                        #{concept.name}
                        
                    </Tag>
                ))}
            </Grid>
        </Box>

      </Stack>
    </CardBody>
  </Card>
  </TabPanel>
  
  {/*바이어 부분 */}
  <TabPanel>
  <Card mt={"4"}>
    <CardHeader>
      <Heading size='md'>Buyers</Heading>
    </CardHeader>
    <CardBody>
      <Stack divider={<StackDivider />} spacing='4'>
      <Box>
          <Heading size='xs' textTransform='uppercase' >
          Name
          </Heading>
          {profile?.is_host ? (
          <Text pt='2' fontSize='sm'>
          {data?.first_name}{data?.last_name}
          </Text>
          ) : "*********"}
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
          Email
          </Heading>
          {profile?.is_host ? (
          <Text pt='2' fontSize='sm'>
          {data?.email}
          </Text>
          ) : "******@***** ***"}
        </Box>
        <Box>
          <Heading size='xs' textTransform='uppercase'>
          Phone
          </Heading>
          {profile?.is_host ? (
          <Text pt='2' fontSize='sm'>
          {data?.phone}
          </Text>
          ) : "**********"}
        </Box>
    </Stack>
    </CardBody>
  </Card> 
  </TabPanel>
        </TabPanels>
    </Tabs>
  </Box>
  </Box>
  
  {/*오른쪽 설명 부분 */}
  <Box px={{ base: 5 }}>
  <VStack>
    <Box 
    p="4" 
    w={"sm"} 
    borderWidth="1px" 
    borderRadius="lg" 
    overflowY="scroll"
    >
        <Box  display="flex" alignItems="baseline">
            <Text as="b" borderRadius='full' px='2' colorScheme='teal'>
            INFORMATIONS
            </Text>
        </Box>
        <Box
            p="3"
            as='h4'
            lineHeight='tight'
            h={"lg"}
          >
            {data?.description} 
        </Box>
        </Box>
  
            </VStack>
            </Box>
          </Grid>
      </Box>
      </ProtectedPage>
    );
  }