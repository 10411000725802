import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    InputLeftAddon,
    InputGroup,
    Select,
    Text,
    useToast,
    VStack,
    HStack,
    Textarea,
    ButtonGroup,
    Stack,
    Center,
    Avatar,
    AvatarBadge,
    IconButton,
  } from "@chakra-ui/react";
  import { SmallCloseIcon } from '@chakra-ui/icons';
  import { useMutation, useQuery } from "@tanstack/react-query";
  import { useForm } from "react-hook-form";
  import { useNavigate } from "react-router-dom";
  import {
    postBuyers,
    IBuyersVariables,
    putBuyers,
    IEditBusinessVariables,
    getPkBuyers,
  } from "../../api";
  import useHostOnlyPage from "../../components/HostOnlyPage";
  import ProtectedPage from "../../components/ProtectedPage";
  import { IProprofile } from "../../types";
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import useUserProfile from "../../lib/useUserProfile";
  import useUser from "../../lib/useUser";
  import { ReactNode, useEffect, useState, useRef } from "react";
  import StyledButton from "../../components/market/StyledButton";
  
  export default function BuyerPost() {
  
    const [step, setStep] = useState(1);
  
    const prevStep = () => {
      setStep((prev) => prev - 1);
    };
  
    const nextStep = () => {
      setStep((prev) => prev + 1);
    };
  
    const disabledBtnStyle = {
      backgroundColor: "#DFE1E1",
      cursor: "not-allowed",
    };
  
    interface IBaseProps {
      children: ReactNode;
    }
  
    const { user } = useUser();
    const { profile } = useUserProfile();
    const { 
      register, 
      handleSubmit,
      getValues,
      formState: { errors },
     } = useForm<IBuyersVariables>();
  
    const toast = useToast();
    const navigate = useNavigate();
  
    const mutation = useMutation(postBuyers, {
        onSuccess: (data: IProprofile) => {
          if (!mutation.isLoading) {
            toast({
                status: "success",
                title: "Registration Completed",
                description: "Matching will begin shortly..",
                position: "bottom-right",
            });
            navigate(`/buyer/home`);
            }
          },
        onError: (error) => {
          console.log("error")
        },
    });
    const onSubmit = ({
        pk,
        user,
        company,
        ceo,
        address,
        country,
        city,
        homepage,
        year,
        employees,
        annual,
        introduction,
        title,
        category,
        type,
        usage,
        volume,
        offering,
        expiration_date,
        first_date,
        match,
        created_at,
        updated_at,
    }: IBuyersVariables) => {
      mutation.mutate({
        pk,
        user,
        company,
        ceo,
        address,
        country,
        city,
        homepage,
        year,
        employees,
        annual,
        introduction,
        title,
        category,
        type,
        usage,
        volume,
        offering,
        expiration_date,
        first_date,
        match,
        created_at,
        updated_at,
      });
    };
  
    const Base = ({ children }: IBaseProps) => (
      <ProtectedPage>
   <VStack pb={20} mt={10} px={{ base: 10, lg: 40}}>
        <VStack p={10} pt={12} spacing={3}>
            {children}
            </VStack>
          </VStack>
      </ProtectedPage>
    );
        
    const Step1 = () => {
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  
    return (
        <>
  
                <HelmetProvider>
                <Helmet>
                  <title>Buying Offer</title>
                </Helmet>
                </HelmetProvider>
                <Box pb={5}  px={{ base: 10, lg: 40 }}>
                <Heading textAlign={"center"}>Buying Offer</Heading>
                </Box>
  
            {/*유저네임 폼*/}
            <FormControl>
                <FormLabel>Username</FormLabel>
              <Input
                defaultValue={user?.username}
                type="text"
                variant={"filled"}
                readOnly
              />
              </FormControl> 
  
              <ButtonGroup
              gap="4"
              w={"100%"}
              display={"grid"}
              gridTemplateColumns={"1fr 1fr"}
            >
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                isDisabled
                _disabled={disabledBtnStyle}
              >
                &larr; Previous
              </Button>
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                onClick={() => {
         
                    nextStep();
                }}
              >
                Next &rarr;
              </Button>
            </ButtonGroup>
        
          </>
      );
  };
  
  const Step2 = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
      <>
              <Box pb={5}  px={{ base: 10, lg: 40 }}>
                <Heading textAlign={"center"}>CORPORATE PROFILE</Heading>
              </Box>
  
            {/*회사이름 폼*/}
            <FormControl>
                <FormLabel>Company Name</FormLabel>
              <Input
                {...register("company", { required: false })}
                required
                type="text"
              />
              <FormHelperText>Write the name of the company.</FormHelperText>
              </FormControl>
  
            {/*대표자 폼*/}
            <FormControl>
                <FormLabel>CEO</FormLabel>
              <Input
                
                {...register("ceo", { required: false })}
                required
                type="text"
              />
                <FormHelperText>Write the name of the representative.</FormHelperText>
              </FormControl>
  
            {/*주소 폼*/}
            <FormControl>
                <FormLabel>Address</FormLabel>
              <Input
                
                {...register("address", { required: false })}
                required
                type="text"
              />
                <FormHelperText>Please write your company address.</FormHelperText>
              </FormControl>

            {/*국가 폼*/}
            <FormControl>
                <FormLabel>Country</FormLabel>
              <Input
                
                {...register("country", { required: false })}
                required
                type="text"
              />
                <FormHelperText>Write the country where the company is located.</FormHelperText>
              </FormControl>

            {/*도시 폼*/}
            <FormControl>
                <FormLabel>City</FormLabel>
              <Input
                
                {...register("city", { required: false })}
                required
                type="text"
              />
                <FormHelperText>Write the city where the company is located.</FormHelperText>
              </FormControl>

            {/*홈페이지 폼*/}
            <FormControl>
                <FormLabel>Homepage</FormLabel>
                <InputGroup size='sm'>
              <InputLeftAddon children='https://' />
              <Input
                {...register("homepage", { required: false })}
                required
                type="text"
              />
              </InputGroup>
                <FormHelperText>Write the company's homepage.</FormHelperText>
              </FormControl>

            {/*설립년도 폼*/}
            <FormControl>
                <FormLabel>Established Year</FormLabel>
              <Input
                {...register("year", { required: false })}
                required
                type="text"
              />
                <FormHelperText>ex:2009</FormHelperText>
              </FormControl>

            {/*고용인원 폼*/}
            <FormControl>
                <FormLabel>No. of Employees</FormLabel>
              <Input
                {...register("employees", { required: false })}
                required
                type="text"
              />
                <FormHelperText>How many employees are employed in the company?</FormHelperText>
              </FormControl>

            {/*매출 폼*/}
            <FormControl>
                <FormLabel>	Annual Sales (1,000USD)</FormLabel>
              <Input
                {...register("annual", { required: false })}
                required
                type="text"
              />
                <FormHelperText>What is the company's annual sales?</FormHelperText>
              </FormControl>

            {/*소개 폼*/}
            <FormControl>
                <FormLabel>Introduction</FormLabel>
              <Textarea
                {...register("introduction", { required: false })}
              />
                <FormHelperText>Please introduce the company.</FormHelperText>
              </FormControl>      
          
              <ButtonGroup
                      gap="4"
                      w={"100%"}
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr"}
                  >
                      <Button
              mt={5}
                          borderRadius={150}
                          bg="#6B4EFF"
                          color={"white"}
                          onClick={prevStep}
                      >
                          &larr; Previous
                      </Button>
                      <Button
              mt={5}
                          borderRadius={150}
                          bg="#6B4EFF"
                          color={"white"}
                          onClick={() => {
                          
                                  nextStep();
                          }}
                      >
                          Next &rarr;
                      </Button>
                  </ButtonGroup>
              </>
          );
      };
  
  const Step3 = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return (
      <>
      
          <Box pb={5}  px={{ base: 10, lg: 40 }}>
                <Heading textAlign={"center"}>Offer Detail</Heading>
                </Box>
  
            {/*타이틀 폼*/}
            <FormControl isRequired>
                <FormLabel>Title</FormLabel>
              <Input
                {...register("title", { 
                  required: true,
                })}
                required
                type="text"
              />
                <FormHelperText>
                Write the title.
                </FormHelperText>
              </FormControl> 

            {/*카테고리 폼*/}
            <FormControl>
                <FormLabel>Category</FormLabel>
              <Select
                {...register("category", { required: true })}
                placeholder="Choose a kind"
              >
                  <option value="womens">Womens</option>
                  <option value="mens">Mens</option>
                  <option value="mens">Unisex</option>
                </Select>
                <FormHelperText>
                Please select a category to offer.
                </FormHelperText>
              </FormControl>

            {/*아이템 폼*/}
            <FormControl>
                <FormLabel>Item</FormLabel>
              <Select
                {...register("type", { required: true })}
                placeholder="Choose a kind"
              >
                  <option value="clothing">Clothing</option>
                  <option value="jewelry">Jewelry</option>
                  <option value="bag">Bag</option>
                  <option value="shoes">Shoes</option>
                </Select>
                <FormHelperText>
                What is the item?
                </FormHelperText>
              </FormControl>

            {/*사용방법 폼*/}
            <FormControl isRequired>
                <FormLabel>Usage</FormLabel>
              <Input
                {...register("usage", { 
                  required: true,
                })}
                required
                type="text"
              />
                <FormHelperText>
                ex: Sample
                </FormHelperText>
              </FormControl>

            {/*주문 볼륨 폼*/}
            <FormControl>
                <FormLabel>Volume of order</FormLabel>
              <Select
                {...register("volume", { required: true })}
                placeholder="Choose a kind"
              >
                  <option value="moq">Minimum Order Quantity</option>
                  <option value="mpq">Minimum Purchase Quantity</option>
                  <option value="mcq">Minimum color Quantity</option>
                  
                </Select>
                <FormHelperText>
                Please select volume of order
                </FormHelperText>
              </FormControl>

            {/*유효기간 폼*/}
          <FormControl>
              <FormLabel> Available Period</FormLabel>
              <Box p={5} borderWidth="1px" rounded="md">
                  <HStack spacing={5}>
              <Input
                type="date"
                {...register("first_date", {
                  required: "Until when do you want to match it?",
                })}
              />
              <Input
                type="date"
                {...register("expiration_date", {
                  required: "Until when do you want to match it?",
                })}
              />  
                </HStack>
            </Box>
              <FormHelperText>
              Until when do you want to match it?
              </FormHelperText>
            </FormControl>      

            {/*오퍼내용 폼*/}
            <FormControl>
                <FormLabel>Offer</FormLabel>
              <Textarea
                {...register("offering", { required: false })}
              />
                <FormHelperText>Please write the details of the offer.</FormHelperText>
              </FormControl>          
              
  
              <ButtonGroup
                gap="4"
                w={"100%"}
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
              >
                <Button
                  mt={5}
                  borderRadius={150}
                  bg="#6B4EFF"
                  color={"white"}
                  onClick={prevStep}
                >
                  &larr; Previous
                </Button>
                <Button
                  mt={5}
                  borderRadius={150}
                  bg="#6B4EFF"
                  color={"white"}
                  isDisabled
                  _disabled={disabledBtnStyle}
                >
                  Next &rarr;
                </Button>
              </ButtonGroup>
              <VStack py={6}>
              {mutation.isError ? (
                                <Text color={"red.500"}>Wrong</Text>
                            ) : null}
                <StyledButton
                btnName={"Upload"}
                hasArrow={true}
                onClick={handleSubmit(onSubmit)}
                isLoading={mutation.isLoading}
              />
            </VStack>
      </>
    );
  };              
  switch (step) {
    case 1:
      return (
        <Base>
          <Step1 />
        </Base>
      );
    case 2:
      return (
        <Base>
          <Step2 />
        </Base>
      );
    case 3:
      return (
        <Base>
          <Step3 />
        </Base>
      );
    default:
      return null;
  }
  };