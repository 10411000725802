import React, { useState } from "react";
import { ReactNode } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td,
  GridItem,
  Grid,
  Tooltip,
  Flex,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';
import { AiFillThunderbolt } from 'react-icons/ai';
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import ThreeTierPricingHorizontal from "../footer/ServicePricing";

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      width={'100%'} 
      height={'100%'} 
      maxWidth={'100%'}
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('blue.200', 'blue.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

export default function Pricing() {
    const [isLabelOpen, setIsLabelOpen] = useState(false)
  return (
    <Box py={12}>
      
      <Tabs defaultIndex={0}>
        <TabList>
          <Tab fontWeight="bold">요금</Tab>
          <Tab fontWeight="bold">서비스</Tab>
          <Tab fontWeight="bold">패키지</Tab>
          <Tab fontWeight="bold">GLOBAL</Tab>
        </TabList>
      
        <TabPanels>
         
          {/* Pricing options for the "Price" tab */}
          <TabPanel>
            
            <VStack mt= {3} spacing={2} textAlign="center">
            <Heading as="h1" fontSize="4xl">
            전문가 버전
            </Heading>
            <Text fontSize="lg" color={'gray.500'}>
            맞춤 솔루션으로 편리하게, 자유자재로 운영하는 비즈니스 (VAT별도)
            </Text>
            </VStack>

            <Stack
              mt={10}
              direction={{ base: 'column', md: 'row' }}
              textAlign="center"
              justify="center"
              spacing={{ base: 4, lg: 10 }}
              py={10}>
              <PriceWrapper>
                <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl">
                Standard
                </Text>
                <Text fontSize="1xl" color="gray.500">
                가입만으로 무료로 바이어 매칭
                </Text>
                <HStack mt={1} justifyContent="center">
                <Text fontSize="5xl" fontWeight="900">
                    Free
                </Text>
                </HStack>
                </Box>
                <VStack
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    py={4}
                    borderBottomRadius={'xl'}>
                    <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        브랜드 등록.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        임의 바이어 리스트 5개.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        바이어 매칭 서비스.
                    </ListItem>
                    </List>
                    {/*
                    <Box w="80%" pt={7}>
                    <Button w="full" colorScheme="red" variant="outline">
                        Start trial
                    </Button>
                    </Box>
                    */}
                </VStack>
                </PriceWrapper>

                <PriceWrapper>
                    <Box position="relative">
                        <Box
                        position="absolute"
                        top="-16px"
                        left="50%"
                        style={{ transform: 'translate(-50%)' }}>
                        <Text
                            textTransform="uppercase"
                            bg={useColorModeValue('red.300', 'red.700')}
                            px={3}
                            py={1}
                            color={useColorModeValue('gray.900', 'gray.300')}
                            fontSize={['10px', 'sm']}
                            fontWeight="600"
                            rounded="xl">
                        <Box display="flex" alignItems="center">
                        <AiFillThunderbolt style={{ marginRight: '5px' }} />
                         Most Popular
                        </Box>
                        </Text>
                        </Box>
                        <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            Business
                        </Text>
                        <Text fontSize="1xl" color="gray.500">
                            바이어 매칭과 브랜드 홍보를 시작하는 기업
                        </Text>
                        <HStack mt={1} justifyContent="center">
                            
                            <Text fontSize="3xl" fontWeight="900">
                            18만원
                            </Text>
                            <Text fontSize="2xl" color="gray.500">
                            /6개월
                            </Text>
                        </HStack>
                        </Box>
                        <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            무료 서비스 포함.
                            </ListItem>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            임의 바이어 리스트 추가 10개.
                            </ListItem>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            플랫폼 상위 노출.
                            </ListItem>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            기업 홍보 자료 번역 서비스.
                            </ListItem>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            브랜드 뱃지.
                            <Tooltip label="브랜드 뱃지는 1개월 동안 NEW, HOT, BEST 같은 아이콘으로
                            기업의 특징을 부각시키는 서비스입니다." 
                            placement="top" 
                            >
                            <QuestionOutlineIcon boxSize={4} ml={1} />
                            </Tooltip>
                            </ListItem>
                        </List>
                       {/*
                       <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red">
                            Start trial
                            </Button>
                        </Box>
                         */}
                        </VStack>
                    </Box>
                    </PriceWrapper>
                    <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                        Premium
                        </Text>
                        <Text fontSize="1xl" color="gray.500">
                            해외수출을 전문적으로 하는 기업
                        </Text>
                        <HStack mt={1} justifyContent="center">
                        <Text fontSize="3xl" fontWeight="900">
                            180만원
                        </Text>
                        <Text fontSize="2xl" color="gray.500">
                            /6개월
                        </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                        <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            베이직 서비스 포함.
                        </ListItem>
                        <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                           파트너 바이어 정보 오픈.
                        </ListItem>
                        <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                           무제한 뱃지 + 상위 노출.
                            <Tooltip label="브랜드 뱃지를 제한 없이 자유롭게 이용하실 수 있습니다. 
                            또한 언제나 최상위로 노출되어 바이어의 관심을 유도합니다." 
                        placement="top" 
                        >
                        <QuestionOutlineIcon boxSize={4} ml={1} />
                        </Tooltip>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            바이어 통역 서비스.
                        </ListItem>
                        <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            지사화 사업 지원.
                        </ListItem>
                        <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            수출 지원 서비스.
                        </ListItem>
                        </List>
                        {/*
                        <Box w="80%" pt={7}>
                        <Button w="full" colorScheme="red" variant="outline">
                            Start trial
                        </Button>
                        </Box>
                        */}
                    </VStack>
              </PriceWrapper>
            </Stack>

            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(6, 1fr)" }} gap={2}>
                <GridItem colSpan={{ base: 1, md: 6 }} overflowX="auto">
                    <Table mt={20} variant="simple">
                    <Thead>
                        <Tr 
                        bg={useColorModeValue('blue.200', 'blue.500')}
                        color={useColorModeValue("black", "white")}
                        >
                        <Th color={useColorModeValue("black", "white")} textAlign="center">요금제</Th>
                        <Th color={useColorModeValue("black", "white")} textAlign="center">브랜드 등록</Th>
                        <Th color={useColorModeValue("black", "white")} textAlign="center">바이어 리스트</Th>
                        <Th color={useColorModeValue("black", "white")} textAlign="center">뱃지 및 노출
                        <Tooltip label="NEW, HOT, BEST 뱃지를 추가하여 바이어의 클릭을 유도하고,
                        상위 페이지 노출을 유지합니다." 
                        placement="top" 
                        >
                        <QuestionOutlineIcon boxSize={4} ml={1} />
                        </Tooltip>
                        </Th>
                        <Th color={useColorModeValue("black", "white")} textAlign="center">
                            파트너 바이어
                        <Tooltip label="파트너 바이어는 PASHOP과 함께 업무협약을 맺은 파트너사의 주요 빅바이어입니다. 
                        신뢰를 바탕으로 형성된 바이어 DB와 파트너 바이어를 통해 수출시장을 확대하고 경쟁력을 강화할 수 있습니다." 
                        placement="top" 
                        >
                        <QuestionOutlineIcon boxSize={4} ml={1} />
                        </Tooltip>
                        </Th>
                        <Th color={useColorModeValue("black", "white")} textAlign="center">통역 서비스
                        <Tooltip label="해외 수출 진행 시 통역서비스를 지원합니다." 
                        placement="top" 
                        >
                        <QuestionOutlineIcon boxSize={4} ml={1} />
                        </Tooltip>
                        </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                        <Td textAlign="center">스탠다드</Td>
                        <Td textAlign="center">무료</Td>
                        <Td textAlign="center">5개</Td>
                        <Td textAlign="center">X</Td>
                        <Td textAlign="center">X</Td>
                        <Td textAlign="center">X</Td>
                        </Tr>
                        <Tr>
                        <Td textAlign="center">비즈니스</Td>
                        <Td textAlign="center">무료</Td>
                        <Td textAlign="center">15개</Td>
                        <Td textAlign="center">O</Td>
                        <Td textAlign="center">X</Td>
                        <Td textAlign="center">X</Td>
                        </Tr>
                        <Tr>
                        <Td textAlign="center">프리미엄</Td>
                        <Td textAlign="center">무료</Td>
                        <Td textAlign="center">무제한</Td>
                        <Td textAlign="center">O</Td>
                        <Td textAlign="center">O</Td>
                        <Td textAlign="center">O</Td>
                        </Tr>
                    </Tbody>
                    </Table>
                </GridItem>
                </Grid>
  

            <Box mt={20} p={5} width={"100%"}>
            <Heading as="h2" size="lg" mb={4}>
                자주 묻는 질문
            </Heading>
            <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        전문가 버전의 각 솔루션 차이는 무엇인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                바이어 매칭 서비스를 받기 위해서는 각 요금별로 받을 수 있는 서비스가 상이합니다.
                먼저 스탠다드 요금은 플랫폼에 브랜드를 무료로 등록하여 해외 바이어에게 상품을 소개합니다. 
                또한 임의 바이어 리스트 5개를 제공하여 직접 바이어와 접촉할 수 있는 기회를 제공합니다.
                반면 비즈니스 요금은 무료버전에서 받은 바이어 리스트 5개 외에 추가로 10개를 더 제공합니다. 
                또한 신상품, 인기상품을 알릴 수 있는 뱃지와 플랫폼 상위 노출을 통하여 바이어의 클릭 횟 수를 유도합니다.
                마지막으로 프리미엄 서비스는 모든 혜택을 받을 수 있으며 PASHOP의 파트너 바이어 리스트를 상시 공개합니다. 
                자유롭게 바이어와 소통할 수 있으며 통역 서비스를 지원 받을 수 있습니다.           
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        바이어 리스트가 무엇인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    바이어 리스트는 그동안 PASHOP이 바이어 발굴을 통해 수집하고 관리한 바이어 DB입니다.
                    제공되는 바이어는 오로지 패션 바이어 위주로 구성되어 있으며,  해외 유명 편집숍, 백화점, 쇼룸 그리고 온라인 바이어 리스트입니다. 
                    귀사에 맞는 맞춤형 해외 잠재파트너 리스트를 추천드립니다.
                </AccordionPanel>
                </AccordionItem>


                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        서비스 기간이 궁금해요
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    전문가 버전에서 제공하는 유료 서비스 기간은 6개월 부터이며, 최대 1년 까지 입니다. 
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        VAT 부가세 별도인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    네, 모든 요금은 VAT 부가세 별도이며 전자세금계산서를 발행합니다.
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                       결제 방법이 궁금해요.
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    결제 방법은 모두 100% 입금을 원칙으로 하며 VAT 부가세 포함 금액입니다. 
                    <br/> 하나은행 182-910026-20104 (주)피브이엠엘코리아
                </AccordionPanel>
                </AccordionItem>
            {/* Add more AccordionItems here */}
            </Accordion>
            </Box>

          </TabPanel>

         
          {/* Pricing options for the "Service" tab */}
          <TabPanel>
          <VStack mt={3} spacing={2} textAlign="center">
            <Heading as="h1" fontSize="4xl">
            별도 서비스
            </Heading>
            <ThreeTierPricingHorizontal />
            </VStack>

            <Box mt={20} p={5} width={"100%"}>
            <Heading as="h2" size="lg" mb={4}>
                자주 묻는 질문
            </Heading>
            <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        별도 서비스는 무엇인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                별도 서비스는 해외 타겟 시장에 대한 정보, 바이어 파트너 발굴, 바이어 미팅등 
                기업에서 필요한 전문 서비스를 제공하는 1회성 서비스입니다.        
                기업에서 해결하지 못한 부분을 PASHOP의 전문인력을 통해 글로벌 발전을 지원합니다.
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        단일 제공 상품은 무엇인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                   별도 서비스의 각각의 상품을 말하며 모두 1회성으로 제공합니다. 
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        VAT 부가세 별도인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    네, 모든 요금은 VAT 부가세 별도이며 전자세금계산서를 발행합니다.
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                       결제 방법이 궁금해요.
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    결제 방법은 모두 100% 입금을 원칙으로 하며 VAT 부가세 포함 금액입니다. 
                    <br/> 하나은행 182-910026-20104 (주)피브이엠엘코리아
                </AccordionPanel>
                </AccordionItem>

                {/* Add more AccordionItems here */}
            </Accordion>
            </Box>

          </TabPanel>
          
          {/* Pricing options for the "Packaging" tab */}
          <TabPanel>
          <VStack spacing={2} textAlign="center">
            <Heading as="h1" fontSize="4xl">
            패키지 버전
            </Heading>
            <Text 
            fontSize="lg" 
            color={'gray.500'}
            width={{
                base: '90%',
              }}
            >
            모든 서비스가 필요하다면 할인패키지 상품구성으로 운영하는 비즈니스 (VAT별도)
            </Text>
            </VStack>

            <Stack
              direction={{ base: 'column', md: 'row' }}
              textAlign="center"
              justify="center"
              spacing={{ base: 4, lg: 10 }}
              py={10}>
              <PriceWrapper>
                <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl">
                Business + 별도 서비스
                </Text>
                <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="900">
                520 만원
                </Text>
                <Text fontSize="2xl" color="gray.500">
                /3개월
                </Text>
                </HStack>
                </Box>
                <VStack
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    py={4}
                    borderBottomRadius={'xl'}>
                    <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Business 요금 3개월.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        단일 서비스 3개월 지속 사용 가능. (월1회)
                    </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                    <Button w="full" colorScheme="red" variant="outline">
                        -590,000원 할인
                    </Button>
                    </Box>
                </VStack>
                </PriceWrapper>

                <PriceWrapper>
                    <Box position="relative">
                        <Box
                        position="absolute"
                        top="-16px"
                        left="50%"
                        style={{ transform: 'translate(-50%)' }}>
                        <Text
                            textTransform="uppercase"
                            bg={useColorModeValue('red.300', 'red.700')}
                            px={3}
                            py={1}
                            color={useColorModeValue('gray.900', 'gray.300')}
                            fontSize={['10px', 'sm']}
                            fontWeight="600"
                            rounded="xl"
                            ml={1}
                            >
                        <Box display="flex" alignItems="center">
                        <AiFillThunderbolt style={{ marginRight: '5px' }} />
                         Most Popular
                         </Box>
                        </Text>
                        </Box>
                        <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                        Premium + 별도 서비스
                        </Text>
                        <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="900">
                        590 만원
                        </Text>
                        <Text fontSize="2xl" color="gray.500">
                        /3개월
                        </Text>
                        </HStack>
                        </Box>
                        <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            Premium 요금 3개월.
                            </ListItem>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            단일 서비스 3개월 지속 사용 가능. (월1회)
                            </ListItem>
                            <ListItem>
                            <ListIcon as={FaCheckCircle} color="green.500" />
                            프리미엄 기업 인증 마크.
                            <Tooltip label="프리미엄 기업 인증 마크는 기업의 품질, 신뢰성, 혁신성 등을 바이어에게 알리는 효과적인 서비스입니다. 
                            프리미엄 기업 인증 마크를 사용하면 기업의 브랜드 가치와 경쟁력을 높일 수 있습니다. " 
                            placement="top"
                            >
                            <QuestionOutlineIcon boxSize={4} ml={1} />
                            </Tooltip>
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red">
                            -700,000원 할인
                            </Button>
                        </Box>
                        </VStack>
                    </Box>
                    </PriceWrapper>
            </Stack>

            <Box mt={20} p={5} width={"100%"}>
            <Heading as="h2" size="lg" mb={4}>
                자주 묻는 질문
            </Heading>
            <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        패키지 버전 & 전문가 버전의 각 솔루션 차이는 무엇인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                패키지 버전은 전문과 버전과 별도 서비스를 함께 제공하는 할인 상품입니다. 
                패키지 버전은 3개월 단위이며, 글로벌 진출이 필요한 기업이 모든 서비스를 이용하고 싶을때
                유용한 상품입니다. 또한 1회성으로 제공하는 별도 서비스도 3개월간 1회씩 총 3번 지원 받을 수 있으며
                할인 혜택이 가장 큰 상품입니다. Premium + 별도 서비스는 프리미엄 기업 인증 마크를 제공하며
                바이어에게 더 특별한 관심을 받을 수 있습니다.
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        각 솔루션의 차이는 무엇인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                Business + 별도 서비스는 전문가 버전의 Business 상품과 별도 서비스를 동시에 제공합니다. 
                Premium + 별도 서비스는 전문가 버전의 Premium 상품과 별도 서비스를 동시에 제공합니다. 
                </AccordionPanel>
                </AccordionItem>


                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        서비스 기간이 궁금해요
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    패키지 버전에서 제공하는 유료 서비스 기간은 3개월 부터이며, 최대 1년 까지 입니다. 
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                        VAT 부가세 별도인가요?
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    네, 모든 요금은 VAT 부가세 별도이며 전자세금계산서를 발행합니다.
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                <h2>
                    <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="bold">
                       결제 방법이 궁금해요.
                    </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    결제 방법은 모두 100% 입금을 원칙으로 하며 VAT 부가세 포함 금액입니다. 
                    <br/> 하나은행 182-910026-20104 (주)피브이엠엘코리아
                </AccordionPanel>
                </AccordionItem>

                {/* Add more AccordionItems here */}
            </Accordion>
            </Box>
          </TabPanel>
 
        {/*해외요금시작*/}
        <TabPanel>
        <Flex  justify="center" align="flex-start" >
            <Box
                borderWidth="1px"
                borderRadius="lg"
                p="10"
                m="10"
                textAlign="center"
                bgGradient="linear(to-r, blue.500, cyan.500)"
                color="white"
            >
                <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                    Global Brand Rate Plan
                </Text>
          
                <Text mt="5">
                Do you want to enter your brand into the Korean market?<br/> 
                We connect you with buyers from famous department stores, select shops, 
                and online shops in Korea.<br/> 
                If you request a quote by email, we will provide you with the best matching service.
                </Text>
                <Link
                    as={RouterLink}
                    to={`/contactus`}
                    _hover={{ textDecoration: "none" }}
                >
                <Button colorScheme="whiteAlpha" mt="10">
                    Request
                </Button>
                </Link>
            </Box>
            </Flex>
            </TabPanel>
            {/*해외요금끝*/}

        </TabPanels>
      </Tabs>
    </Box>
  );
}
