import { useForm } from "react-hook-form";
import {
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  } from "@chakra-ui/react";
  import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
  import { useNavigate, useParams } from "react-router-dom";
  import { 
    IDeleteMatchingsVariables,
    deleteMatchings, 
  } from "../../api";
  import { useRef } from "react";


  interface DeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  interface IDeleteMatchForm {
    matchPk: string;
  }
  
  export default function DeleteMatchings({ isOpen, onClose }: DeleteModalProps) {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const { buyerPk, matchPk } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm<IDeleteMatchForm>();
      console.log(errors);
    const mutation = useMutation(deleteMatchings, {
      onSuccess: () => {
        toast({ 
          status: "success",
          title: "CANCEL",
          description: "Delete the application.",
        });
        navigate(`/buyers/${buyerPk}`);
        onClose();
        queryClient.refetchQueries(["me"]);

      },
    });

    const onSubmit = (data: IDeleteMatchingsVariables) => {
      if (matchPk) {
          data["matchPk"] = matchPk;
          mutation.mutate(data);
      }
  };

    return (
        
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogHeader>Delete</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody >
            Delete the application.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button 
                isLoading={mutation.isLoading}
                colorScheme="red" 
                ml={3}
                type="submit"
                >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
    )
  }

  