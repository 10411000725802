import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';
import ContactForm from "./ContactUs";

type Faq = {
  question: string;
  answer: string;
};

const faqs: Faq[] = [
  {
    question: 'What kind of services do you provide?',
    answer:
      'We provide a range of services to help up-and-coming designers export their brands to different regions. Our services include market research, logistics, marketing, and legal support.',
  },
  {
    question: 'What kind of designers do you work with?',
    answer:
      'We work with up-and-coming designers from various fields, including fashion, product design, and graphic design. We believe in supporting new and innovative talent and helping them succeed on a global stage.',
  },
  {
    question: 'How do you select the designers you work with?',
    answer:
      'We select designers based on their talent, creativity, and potential for success in the global market. We also look for designers who are committed to building their brand and willing to work collaboratively with our agency.',
  },
  {
    question: 'What kind of markets do you export to?',
    answer:
      'We export to a range of markets, including Europe, the United States, and Asia. We also actively support clients who are looking to expand into new markets.',
  },
  {
    question: 'What kind of products do you export?',
    answer:
      'We export a range of products, including clothing, accessories, furniture, and home decor. We work with designers to develop products that are tailored to the specific needs and preferences of the target market.',
  },
  {
    question: 'Where do you have customers?',
    answer:
      'We have customers in various regions, including Europe, the United States, and Asia. We actively support clients who are looking to expand into new markets.',
  },
  {
    question: 'How do you manage the risks associated with exporting?',
    answer:
      'We make every effort to minimize the risks associated with exporting in every transaction. This includes compliance with export regulations and laws, an understanding of the culture and customs of the target country, and the implementation of advanced risk management strategies to protect our clients interests.',
  },
  {
    question: 'What conditions need to be met for export?',
    answer:
      'To export, a range of conditions must be met, including product quality and safety, compliance with international export regulations and laws, and knowledge of tariffs and taxes in the target country. We provide detailed guidance and support to help our clients meet these conditions.',
  },
  {
    question: 'How long does it take to export?',
    answer:
      'The time required for export varies depending on the type of product, target country, and export method. Generally, export preparation can take several weeks to several months. We support clients who require fast export and provide detailed guidance on the export preparation process.',
  },
  {
    question: 'How can we cooperate with your agency?',
    answer:
      'To cooperate with us, please complete the inquiry form on our website or contact us by email. Our representatives will contact you as soon as possible. We seek partnerships that promote mutual benefit and growth through collaboration with our clients.',
  },
];

export default function FaqForm() {
  return (
    <Box>
      <Heading textAlign={"center"} mt={10} as="h2" size="xl" mb={6} color="teal.500" textShadow="1px 1px #d1d1d1">
        FAQ
      </Heading>
      <Accordion mt={10} p={10} allowMultiple width="100%">
        {faqs.map(({ question, answer }, index) => (
          <AccordionItem key={index} border="none">
            <AccordionButton
              _hover={{ background: 'gray.200' }}
              _focus={{ boxShadow: 'none' }}
              borderRadius="md"
              borderWidth="1px"
              borderColor="gray.300"
              py={5}
              px={5}
              fontWeight="medium"
              textAlign="left"
            >
              <Box flex="1">{question}</Box>
              <AccordionIcon color="teal.500" opacity={0.6} _expanded={{ opacity: 1 }} />
            </AccordionButton>
            <AccordionPanel pb={4}>{answer}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}
