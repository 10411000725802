import { Box, Heading, Text } from '@chakra-ui/react';

export default function Terms() {
  return (
    <Box white-space= "pre-wrap" py={10} px={6}>
 
      <Heading textAlign="center" as="h2" size="xl" mt={6} mb={2}>
      Terms of service 
      </Heading>
      <Text  text-align="justify" color={'gray.500'}>
      <br />
      <br />
      <br />
      <br />
      Article 1 (Purpose)

 
      <br />
This Agreement aims at stipulating rights, obligations and responsibilities of the Cyber Mall (hereinafter referred to as the "Mall") and the User in respect of the use of internet-related services provided by the Mall (hereinafter referred to as the "Service") in the operation of  PVMLKOREA. (e-commerce business).
<br />
<br />
<br />
※「In addition, this Agreement shall be applied to the e-commerce using PC communication, mobile, et cetera, unless it is not against the its nature.」
<br />
<br />
<br />
Article 2 (Definition)
<br />
<br />
<br />
① "Mall" means the virtual business place on which the Company set for transacting goods and services by using information and communication facilities such as computer so that it may provide goods and services (hereinafter referred to as “Goods and Services” to the User. The term is also used as a business operating a cyber mall.
    <br />
    <br />
    <br />
② "User" means a member or a non-member who uses Service provided by the “Mall” in accordance with this Agreement through the access to the "Mall".
<br />
<br />
<br />
③ “Member” means a person who subscribed the membership by providing his or her personal information to the "Mall", for which the member may be informed of the "Mall" on a continuous basis and use the Service offered by the “Mall”.
<br />
<br />
<br />
④ “Non-Member” means a person who use the Service offered by the “Mall” not subscribing to the membership.
<br />
<br />
<br />
Article 3 (Display, Explanation & Modification of User Agreement)
<br />
<br />
<br />
① The "Mall" shall, for easier recognition by Users, display the contents of this Agreement, name of company and president, business address (including the address where customer complaints may be treated), telephone number, fax number, email address, business registration number, mail-order business registration number, staff in charge of privacy management, et cetera on the initial service page of the cyber mall. Notwithstanding the foregoing, the contents of this Agreement may be exposed to Users through a link page.
<br />
<br />
<br />
② The "Mall” shall obtain the confirmation of a User for important matters such as cancellation of purchase, delivery obligation, refund terms, et cetera through a separate link page or pop-up window before the User agrees on this Agreement so that the User may understand such important matters.
<br />
<br />
<br />
③ The "Mall" may modify this Agreement within the extent that it does not violate applicable laws, e.g. the Law related to Protection of Consumer on E-commerce, Et Cetera, the Law related to Limitation of Agreement, Basic Law of Electronic Transaction, Electronic Signature Law, the Law related to Stimulation of Use of Information and Communication Network & Information Protection, the Law related to Call Sales, Et Cetera, the Consumer Protection Law, et cetera.
<br />
<br />
<br />
④ In the event of any modification of this Agreement, the announcement stipulating the date of application and the cause of modification shall be displayed on the initial page from 7 days prior to the date of application to the previous day of the same date. Notwithstanding the foregoing, in the event that such modification becomes disadvantageous Users, such modification shall be announced at least 30 days prior to the date of application. The announcement shall include a table specifying the modification on a before-and-after basis for easier understanding of Users.
<br />
<br />
<br />
⑤ In the event of any modification of this Agreement, the modified Agreement shall be applied only to the contracts to be concluded after the date of application, whereas the provisions of this Agreement prior to the modification shall be applied to the contracts which have been previously concluded prior to the same date. Notwithstanding the foregoing, in the event that a User who already entered into the Contract sends his or her intent to be applied by the provisions of the modified Agreement to the “Mall” within the period of announcement for the modification of this Agreement in accordance with Clause ③ and acquires the approval of the "Mall", the provisions of the modified Agreement shall be applied accordingly.
<br />
<br />
<br />
⑥ The matters not stipulated herein and the interpretation of this Agreement shall be in accordance with the Law related to Protection of Consumer on E-commerce, Et Cetera, the Law related to Limitation of Agreement, the Consumer Protection Policy on E-commerce, Et Cetera stipulated by the Fair Trade Commission, and other applicable laws and commercial practices.
<br />
<br />
<br />
Article 4 (Provision & Replacement of Service)
<br />
<br />
<br />
① The "Mall" shall perform the following duties:
<br />
1. Provision of information regarding product or service and conclusion of purchase contracts
<br />
2. Delivery of product or service on purchase contract
<br />
3. Other duties designated by the "Mall"
<br />
<br />
<br />
② The “Mall” may replace product or service provided by the contract to be concluded in the event that product or service is sold out or technical specifications are changed. In this case, the “Mall” shall immediately announce the replacement of the product or service and the date of application on the page where the present product or service is displayed.
<br />
<br />
<br />
③ In the event that product or service on the contract with a User needs to be replaced because the product or service is sold out or technical specifications are changed, The “Mall” shall immediately notify the cause to the address of the User.
<br />
<br />
<br />
④ In the event of the occurrence of the previous clause, the “Mall” shall compensate the User for damages. Notwithstanding the foregoing, this shall not apply if the “Mall” proves that such event is not caused by its intention or negligence.
<br />
<br />
<br />
Article 5 (Suspension of Service)
<br />
<br />
<br />
① The “Mall” may temporarily suspend the provision of Service in the event of any repair, inspection, replacement, breakdown of information and communication equipment such as computers, or interruption of communication.
<br />
<br />
<br />
② The “Mall” shall compensate User or 3rd party for damages caused by the temporary suspension of provision of Service due to the cause(s) in Clause ①. Notwithstanding the foregoing, this shall not apply if the “Mall” proves that such event is not caused by its intention or negligence.
<br />
<br />
<br />
③ In the event of any unavailability of provision of Service due to the conversion of business item, abandon of business, integration between businesses, et cetera, the “Mall” shall notify the fact to Users in the manner stipulated in Article 8, and compensate consumers in accordance with the conditions which are initially suggested by the “Mall”. Notwithstanding the foregoing, in the event that the “Mall” has not notified the criteria for compensation, the “Mall” shall pay Users for their mileage or reserve in kind or cash of which value corresponds to the currency being used at the “Mall”.
<br />
<br />
<br />
 Article 6 (Membership)
 <br />
 <br />
 <br />
① The User shall apply for the membership by expressing his or her intent to agree on this Agreement after filling out the form designated by the “Mall” with the member information.
<br />
<br />
<br />
② The “Mall” shall register the User who applied for the membership in the manner stipulated in Clause ① as a Member provided that the User is not engaged in one of the following items. In the event that:
<br />
1. the applicant ever lost its membership in the past in accordance with Clause 3 of Article 7 of this Agreement. Notwithstanding the foregoing, this shall not apply to those acquired the approval of the “Mall” for re-subscription of the membership since 3 years has passed from the date of loss of membership in accordance with Clause 3 of Article 7 of this Agreement;
<br />
2. there is false information or omission in the registered contents; or
<br />
3. the “Mall” deems that the applicant may cause significant inconvenience to be registered as a Member.
<br />
<br />
<br />
③ The Membership shall be effective at the time that the Member receives the approval of the “Mall”.
<br />
<br />
<br />
④ The Member shall notify any change of its information to the “Mall” in accordance with Clause 1 of Article 15 through email or other communication method.
<br />
<br />
<br />
Article 7 (Withdrawal from Membership & Loss of Eligibility)
<br />
<br />
<br />
① The Member may at any time request for the withdrawal from its membership to the “Mall”, and the “Mall” shall proceed with the withdrawal on the request of the Member.
<br />
<br />
<br />
② In the event that a Member is engaged in one of the following items, the “Mall” may limit or suspend the eligibility of the Member. In the event that the Member:
<br />
1. registered false information at the time of the application for membership;
<br />
2. has not paid the price of goods it purchased through the “Mall” or other liability borne by the Member in respect of the use of the “Mall”;
<br />
3. interrupts others to use the “Mall” or threats the order of e-commerce, e.g. illegal of personal information; or
<br />
4. takes any action being against the laws, this Agreement and good public order and customers by using the “Mall”.
<br />
<br />
<br />
③ The “Mall” may cancel the membership of the Member whose membership was suspended or limited, and repeated the same action twice or more, or the cause is not corrected within 30 days.
<br />
<br />
<br />
④ In the event of the cancellation of membership, the “Mall” shall notify it to the Member, and give the Member 30 days or more to grant an opportunity to explain the cause prior to the cancellation.
<br />
<br />
<br />
Article 8 (Notification to Members)
<br />
<br />
<br />
① In the event of any notification of the “Mall” to a Member, it may be delivered via an email address designated by the Member through the agreement with the “Mall”.
<br />
<br />
<br />
② The “Mall”, in the event of the notification to unspecified Members, may replace individual notification by displaying such notification on the board linked in the website of the “Mall” for 1 week or more. Notwithstanding the foregoing, the “Mall” shall give individual notice to a Member in respect of any matter which may have a significant influence on the Member regarding his or her transaction.
<br />
<br />
<br />
Article 9 (Request for Purchase)
<br />
<br />
<br />
The User shall apply for purchase in accordance with the following manner or other similar manner at the “Mall”, and the “Mall” shall provide the User with the following information in the form which the User may easily understand in his or her request for purchase. Notwithstanding the foregoing, the Member may be exempted from the application of Item 2 or Item 4 below.
<br />
1. Search and selection of goods, et cetera;
<br />
2. Entering name, address, telephone number, email address (or mobile phone number);
<br />
3. Confirmation of matter(s) in respect of the contents of this Agreement, the service(s) of which right of cancellation of purchase is limited, obligation of delivery fees and installation fees, et cetera;
<br />
4. Expression to agree on this Agreement, or confirm or refuse the Item 3 above (e.g. mouse click);
<br />
5. Application for purchase of goods and confirmation of such application, or agreement on the confirmation of the “Mall”; or
<br />
6. Selection of payment method.
<br />
<br />
<br />
Article 10 (Conclusion of Contract)
<br />
<br />
<br />
① The “Mall” may not accept the request for purchase in Article 9 if it falls one of the following items.. Notwithstanding the foregoing, in the event of the conclusion of contract with a minor, the “Mall” shall notify that the failure to acquire the agreement of the legal representative may cause the cancellation of the contract by the minor him(her)self or the legal representative. In the event that:
<br />
1. there is false information or omission in the registered contents;
<br />
2. the minor purchase any product or service restricted in the Youth Protection Law, e.g. cigarette or liquor;
<br />
3. the “Mall” deems that the applicant may cause significant inconvenience to accept the application for purchase; or
<br />
<br />
<br />
② The contract is deemed to be concluded when the acceptance of the “Mall” is delivered to the User in the form of the confirmation of receipt stipulated in Clause 1 of Article 12.
<br />
<br />
<br />
③ The acceptance of the “Mall” shall include the information such as confirmation of the application for purchase of the User, availability of sales, and correction or cancellation of the application for purchase.
<br />
<br />
<br />
Article 11 (Payment Method)
<br />
<br />
<br />
The method of payment for a product or service purchased through the “Mall” may be selected among the following items. Notwithstanding the foregoing, the “Mall” may not collect any additional fees on the amount of the product or service.
<br />
1. Account transfer, e.g. phone banking, internet banking, mail banking, et cetera;
<br />
2. card payment, e.g. prepaid card, debit card, credit card, et cetera;
<br />
3. Online non-bankbook deposit;
<br />
4. Electronic money;
<br />
5. Pay-on-receipt;
<br />
6. Point offered by the “Mall”, e.g. mileage;
<br />
7. Gift voucher contracted or approved by the “Mall”; or
<br />
8. Other payment by electronic means.
<br />
<br />
<br />
Article 12 (Notice of Receipt, Change and Cancellation of Application for Purchase)
<br />
<br />
<br />
① In the event of an application for purchase of a User, the “Mall” shall notify the User with the receipt of the application.
<br />
<br />
<br />
② In the event that there is any discordance between expressions of intent, the User who received the notice of receipt may change or cancel the application for purchase immediately after the User receives the notice, and in the event of any request of a User prior to the delivery, the “Mall” shall deal with the request without any delay. Notwithstanding the foregoing, in the event that the payment has been already made, it shall be in accordance with the stipulation related to the cancellation of purchase in Article 15.
<br />
<br />
<br />
Article 13 (Provision of Goods)
<br />
<br />
<br />
① Unless otherwise stipulated herein in respect of the schedule for the provision of goods, the “Mall” shall take necessary measures to deliver the goods within 7 days from the date of purchase by a User such as customized production or packaging. Notwithstanding the foregoing, in the event that the “Mall” has received the whole or the part of the payment for the goods, such measures shall be taken within 2 business days from the date of receipt of the payment. At that time, the “Mall” shall take necessary measures to make the User check the procedures of provision of the goods and delivery process.
<br />
<br />
<br />
② The “Mall” shall specify, for the goods purchased by a User, the delivery method, payer of delivery fees by method, period of delivery by method, et cetera. In the event that the “Mall” exceeds the designated period of delivery, it shall compensate for damages to the User. Notwithstanding the foregoing, this shall not apply if the “Mall” proves that such event is not caused by its intention or negligence.
<br />
<br />
<br />
Article 14 (Refund)
<br />
<br />
<br />
In the event that the product or service which a User applied for purchase may not be provided or delivered due to sold-out or other cause, the “Mall” shall notify the situation to the User without any delay, and in the event that it has received the payment for the product or service in advance, it shall refund the payment or take necessary measures within 2 business days from the date of receipt of the payment.
<br />
<br />
<br />
Article 15 (Cancellation of Purchase)
<br />
<br />
<br />
① The User who concluded the contract for the purchase of goods with the “Mall” may cancel the purchase within 7 days from the date on which the User received the notice of receipt.
<br />
<br />
<br />
② The User may not return or change the good which he or she received through delivery in the event of one of the following items. In the event that:
<br />
1. the goods delivered is lost or damaged with the responsibility of the User (Notwithstanding the foregoing, the cancellation of purchase may be acceptable in the event of the damage on packaging for checking the contents in the box.);
<br />
2. the value of the goods is significantly decreased due to the use or consumption by the User;
<br />
3. the value of the goods is significantly decreased thus not available for re-sale due to the lapse of time;
<br />
4. the packaging is damaged, if the goods may be reproduced to the goods showing the same performance;
<br />
<br />
<br />
③ The cancellation of purchase by User shall not be limited if, in the case of Item 2 or Item 4 of Clause 2, the “Mall” did not specify the fact that the cancellation of purchase is limited for easier recognition by consumer or not take necessary measures, e.g. provision of a sample.
<br />
<br />
<br />
④ Notwithstanding Clause 1 and Clause 2, the User may cancel his or her purchase of goods within 3 months from the date of receipt of the goods or within 30 days from the date on which he or she recognized or could recognize that the contents of goods differ from the advertisement or the provision of contract.
<br />
<br />
<br />
Article 16 (Effect of Cancellation of Purchase)
<br />
<br />
<br />
① In the event of the return of goods from a User, the “Mall” shall refund the payment for the goods within 3 business days from the date on which it had received the payment. In the event that the “Mall” caused a delay of the refund to the User, it shall pay interest calculated with overdue interest rate announced by the Fair Trade Commission for the number of days of delay.
<br />
<br />
<br />
② Regarding the above-mentioned refund and in the event that the User made a payment for the goods with credit card or electronic money, the “Mall” shall request the business who provided such payment method to suspend or cancel the payment without any delay.
<br />
<br />
<br />
③ In the event of any cancellation of purchase, the User shall bear the cost for return of the goods provided. The “Mall” shall not claim for cancellation charge or compensation for damages to the User in respect of the cancellation of purchase. Notwithstanding the foregoing, in the event of the cancellation of purchase caused by that the contents of goods differ from the advertisement or the provision of contract, the “Mall” shall bear the cost for return of the goods.
<br />
<br />
<br />
④ In the event that User bore the delivery fees when he or she received the goods, the “Mall” shall specify who would bear the cost for the cancellation of purchase for easier recognition of the User.
<br />
<br />
<br />
Article 17 (Protection of Personal Information)
<br />
<br />
<br />
① The “Mall” collects minimum information necessary for the execution of the purchase contract with Users. The following items shall be necessary information and others are optional.
<br />
1. Name;
<br />
2. National ID Number (not for Members) or foreigner registration number;
<br />
3. Address;
<br />
4. Telephone number;
<br />
5. ID (for Members);
<br />
6. Password (for Members); or
<br />
7. Email address (or mobile phone number).
<br />
<br />
<br />
② In the event that the “Mall” collects personal information available for identification of User, it must obtain the approval of the User.
<br />
<br />
<br />
③ The personal information may not be used for any purpose and provided to any 3rd party without the approval of the User, and the “Mall” shall be responsible for the matter. Notwithstanding the foregoing, it shall not apply to the following items. In the event that:
<br />
1. the “Mall” informs the delivery service provider with minimum information of User for performing delivery duty (e.g. name, address, telephone number);
<br />
2. such personal information is necessary for statistics, academic research or market research, and provided in the form that a certain individual may not be identified;
<br />
3. such personal information is necessary for the settlement of payment for transacting products, et cetera;
<br />
4. such personal information is necessary for the identification against illegal use; or
<br />
5. there is inevitable cause by regulation or law.
<br />
<br />
<br />
④ In the event that the “Mall” is required to acquire the approval of User by Clause 2 and 3, it shall specify or notify the provision stipulated in Clause of Article 22 of the Law related to Stimulation of Use of Information and Communication Network & Information Protection, e.g. identity of the personal information manager (post, name, telephone number, other contact), purpose of collection and use of information, matters related to the provision of information to 3rd party (recipient, purpose of provision and information to be provided), et cetera.
<br />
<br />
<br />
⑤ Users may at any time request for the confirmation and correction of error on their personal information possessed by the “Mall”, the “Mall” shall be responsible for taking any necessary measures without any delay. In the event that a User requests for the correction of an error, the “Mall” shall not use the applicable personal information until it corrects the error.
<br />
<br />
<br />
⑥ The “Mall” shall limit the number of managers for protecting personal information, and be responsible for any damages of User caused by loss, disclosure or falsification or personal information of the User including credit card and bank account.
<br />
<br />
<br />
⑦ The “Mall” or any 3rd party who received personal information from the “Mall” , shall without any delay destroy personal information after it achieves its purpose of collection of the personal information.
<br />
<br />
<br />
Article18 (Providing information and publishing advertisement )
<br />
<br />
<br />
① “Mall” can provide various marketing communications that customer need with email, post, sms, phone, Push Notification, SNS service that interlocked with member’s address and et cetera, and members will agree. In this case, by member’s communication environment or payment structure, member can burden data payment, and et cetera. 
<br />
<br />
<br />
②  Member can always reject about  previous section’s email receive other than except trade related information by related law or customer inquiry’s answer. In this case “Mall” should stop previous section’s marketing communications providing. 
<br />
<br />
<br />
Article 19 (Obligations of “Mall”)
<br />
<br />
<br />
① The “Mall” shall not take any action restricted by the law and this Agreement or being against the good public order and customs, and put its best efforts to provide products and services on a stable basis in accordance with the provisions in this Agreement.
<br />
<br />
<br />
② The “Mall” shall equip the security system for the protection of personal information of Users (including credit information) so that the Users may safely use online services.
<br />
<br />
<br />
③ The “Mall” shall be responsible for the compensation to User(s) if it has caused damages to the User(s) by displaying or adding unjust or unreasonable advertisement for a certain product or service in accordance with Article 3 of the Law related to Fairness of Display and Advertisement.
<br />
<br />
<br />
④ The “Mall” shall not send any profit-making emails which are not wanted by Users.
<br />
<br />
<br />
Article 20 (Obligations for ID & Password of Member)
<br />
<br />
<br />
① Each Member shall be responsible for the management of his or her ID and password, except the case in Article 17.
<br />
<br />
<br />
② Each Member shall not allow any 3rd party to use his or her ID and password.
<br />
<br />
<br />
③ In the event that a Member recognizes that his or her ID and / or password is stolen or used by a 3rd party, the Member shall immediately notify the fact to the “Mall”, and follow the instruction of the “Mall”, if required.
<br />
<br />
<br />
Article 21 (Obligations of User)
<br />
<br />
<br />
Users shall not:
<br />
1. register false information at the time of its application or change of information;
<br />
2. steal others’ personal information;
<br />
3. change of information displayed on the “Mall”;
<br />
4. remit or display any information other than the information selected by the “Mall” (computer program, et cetera);
<br />
5. infringe the copyright or the intellectual property right of the “Mall” or a 3rd party;
<br />
6. take any action to bring disgrace on or interrupt the operation of the “Mall” or a 3rd party; or
<br />
7. disclose or display any information containing indecent or violent message, video, voice, and other information being against the good public order and customs.
<br />
<br />
<br />
Article 22 (Relationship between Liking Mall & Linked Mall)
<br />
<br />
<br />
① In the event that the main mall and the sub-mall are linked with the hyperlink (e.g. the subject of hyperlink includes text, image and video), the former is called as the Linking Mall (Website) and the latter is called as the Linked Mall (Website).
<br />
<br />
<br />
② The Liking Mall shall not be responsible for any transaction with the User for any product or service independently provided by the Linked Mall if the Liking Mall specify its intent of such non-guarantee on the initial page at the website of the Liking Mall or pop-up window.
<br />
<br />
<br />
Article 23 (Copyright & Limitation of Use)
<br />
<br />
<br />
① The copyright and other intellectual property right for the works produced by the “Mall” shall be belonged to the “Mall”.
<br />
<br />
<br />
② The User may not use or cause any 3rd party to use the information of which intellectual property right is belonged to the “Mall”
<br />
among those acquired in the course of the use of the “Mall” for the reproduction, transmission, publishing, distribution, broadcasting or other profit-making use without the prior consent of the “Mall”.
<br />
<br />
<br />
③ The copyright and intellectual property rights for posts (User contents) such as evaluation scores, reviews, replies, and photos created and registered by a User directly belong to the relevant User. And the User shall grant the right to use the relevant user contents at the “Mall” free of charge. Regarding the content (e.g., product reviews) created and shared by Users, the “Mall” may reprocess Users’ content within the stores in order to promote products and permanently cite it.
<br />
<br />
<br />
Article 24 (Resolution of Dispute)
<br />
<br />
<br />
① The “Mall” shall reflect the reasonable opinion or complaint made by User(s), and install and operate an organization for dealing with compensation for damages.
<br />
<br />
<br />
② The “Mall” shall give priority to any opinion or complaint made by User(s). Notwithstanding the foregoing, in the event that it may not promptly deal with the matter, the “Mall” shall notify the User with the cause and the schedule to make the treatment done.
<br />
<br />
<br />
③ In the event that there is any application for remedy by a User regarding the dispute raised between the “Mall” and the User, the matter may be treated in accordance with the settlement of the Fair Trade Commission or the arbitration commissioned by the Mayor or the jurisdiction.
<br />
<br />
<br />
Article 25 (Jurisdiction & Governing Law)
<br />
<br />
<br />
① Any and all e-commerce-related lawsuits raised between the “Mall” and a User shall be in accordance with the address of the User at that time of the suing, and in the event of there is no address, such lawsuit shall be exclusively controlled by the competent court of jurisdiction. Notwithstanding the foregoing, in the event that the address or residence of the User at that time of the suing is not clear or in the event of the foreigner, the lawsuit shall be filed to the competent court on the Civil Procedures Code.
<br />
<br />
<br />
② Any and all e-commerce-related lawsuits raised between the “Mall” and a User shall be governed by the law of the Republic of Korea.
<br />
<br />
<br />
** Supplementary Provision (Effective Date)
<br />
This Agreement shall be effective from 18, 01, 2021. 
      </Text>
    </Box>
  );
}