import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
    useBreakpointValue,
  } from '@chakra-ui/react'
  import { ReactNode } from 'react'
  import { BsFillPeopleFill } from 'react-icons/bs'
  import { GiClothes } from 'react-icons/gi'
  import { FaDollarSign } from 'react-icons/fa'
  
  interface StatsCardProps {
    title: string
    stat: string
    icon: ReactNode
  }
  
  function HomeClient(props: StatsCardProps) {
    const { title, stat, icon } = props
    const fontSize = useBreakpointValue({ base: 'lg', md: '2xl' })
  
    return (
      <Stat
        px={{ base: 2, md: 4 }}
        py={'5'}
        shadow={'xl'}
        border={'1px solid'}
        borderColor={useColorModeValue('gray.800', 'gray.500')}
        rounded={'lg'}>
        <Flex justifyContent={'space-between'}>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={'medium'}>{title}</StatLabel>
            <StatNumber fontSize={fontSize} fontWeight={'medium'}>
              {stat}
            </StatNumber>
          </Box>
          <Box
            my={'auto'}
            color={useColorModeValue('gray.800', 'gray.200')}
            alignContent={'center'}
            // 여기에 패딩을 추가합니다.
            pr={5} // Buyers 제목에 오른쪽 패딩 추가
          >
            {icon}
          </Box>
        </Flex>
      </Stat>
    )
  }
  
  export default function BasicStatistics() {
    const headingFontSize = useBreakpointValue({ base: '2xl', md: '4xl' })
  
    return (
      <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
        <chakra.h1
          textAlign={'center'}
          fontSize={headingFontSize}
          py={10}
          fontWeight={'bold'}
          fontFamily="Patricia"
          transform="skew(-10deg)"
          >
          Grow with us and 
          <br /> Achieve your potential
        </chakra.h1>
        <SimpleGrid p={3} columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
          <HomeClient
            title={'Buyers'}
            stat={'149+'}
            icon={<BsFillPeopleFill size={'3em'} />}
          />
          <HomeClient
            title={'Items'}
            stat={'1,159+'}
            icon={<GiClothes size={'3em'} />}
          />
          <HomeClient
            title={'Transactions'}
            stat={'810,595'}
            icon={<FaDollarSign size={'3em'} />}
          />
        </SimpleGrid>
      </Box>
    )
  }
  