import { 
  Grid, 
  Box,
  Center,
  VStack,
  Heading,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getUnisex } from "../../api";
import Room from "../../components/Room";
import RoomSkeleton from "../../components/RoomSkeleton";
import { IRoomList } from "../../types";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Unisex() {
  const { isLoading, data: getrooms } = useQuery<IRoomList[]>(["rooms"], getUnisex);
  const [searchValue, setSearchValue] = useState("");

  const filteredRooms =
  getrooms &&
  getrooms.filter(
      (room) =>
      room.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      room.year.toLowerCase().includes(searchValue.toLowerCase()) ||
      room.season.toLowerCase().includes(searchValue.toLowerCase())
    );
  return (
<Box>
<HelmetProvider>
    <Helmet>
      <title>UNISEX</title>
  </Helmet>
</HelmetProvider>
<VStack mt={30}>
    <Box w="50%">
        <InputGroup size="md">
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={FaSearch} color="gray.300" />}
          />
          <Input
            type="text"
            placeholder="Search Brands..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </InputGroup>
    </Box>  
</VStack>

    <Grid
      mt={10}
      px={{
        base: 10,
        lg: 40,
      }}
      columnGap={4}
      rowGap={8}
      templateColumns={{
        sm: "1fr 1fr",
        md: "1fr 1fr",
        lg: "repeat(3, 1fr)",
        xl: "repeat(4, 1fr)",
        "2xl": "repeat(5, 1fr)",
      }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      {isLoading ? (
        <>
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
        </>
      ) : null}

      {filteredRooms?.map((room) => (
        <Room
          key={room.pk}
          pk={room.pk}
          isOwner={room.is_owner}
          imageUrl={room.photos[0]?.file}
          name={room.name}
          rating={room.rating}
          year={room.year}
          season={room.season}
          nationality={room.nationality}
          order={room.order}
          free={room.free}
          isNew={room.new}
          hot={room.hot}
          best={room.best}
          premium={room.premium}
        />
      ))}
    </Grid>
          {getrooms?.length === 0 ? (
          <VStack>
            <Heading fontSize={"xl"}>There are no brands in the Unisex category..</Heading>
          </VStack>
        ) : null}
    </Box>
  );
}