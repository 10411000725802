import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  InputRightElement,
  Text,
  } from "@chakra-ui/react";
  import { FaUserCircle, FaLock, FaEnvelope,FaCheckCircle } from "react-icons/fa";
  import { useMutation, useQueryClient } from "@tanstack/react-query";
  import SocialLogin from "./SocialLogin";
  import { 
    usernameSignUp, 
  } from "../api";
  import React from "react";
  import { useNavigate } from "react-router-dom";

  interface SignUpModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  
  interface IForm {
    username: string;
    email: string;
    password: string;
    re_password: string;
  }

  export default function SignUpModal({ isOpen, onClose }: SignUpModalProps) {
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      watch,
    } = useForm<IForm>();
    console.log(errors);

    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const mutation = useMutation(usernameSignUp, {
      onSuccess: () => {
        toast({ 
          title: "Welcome!", 
          status: "success",
          description: "Please, Create your account", 
          position: "bottom-right",
        });
        navigate(`users/myeditprofile`);
        onClose();
        reset();
        queryClient.refetchQueries(["me"]);
      },
      onError: () => {
        toast({
            status: "error",
            title: "Error!",
            description: "Username already in use.",
            position: "bottom-right",
        });
    },
    });

    const onSubmit = ({
      username,
      email,
      password,
      re_password,
    }: IForm) => {
      mutation.mutate({
        username,
        email,
        password,
        re_password,
      });
    };

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    return (
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sign up</ModalHeader>
          <ModalCloseButton />
          <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>         
            <VStack>

      {/*유저네임*/} 
      <FormControl isRequired isInvalid={Boolean(errors.username)}>
      <FormLabel>Username</FormLabel>
        <InputGroup size={"md"}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaUserCircle />
                    </Box>
                  }
                />
          <Input 
            {...register("username", {
              required: "Please write a username",
              minLength: { value: 4, message: "Minimum length should be 4 characters" },
            })}
            variant={"filled"}
            placeholder="Username"
            />
        </InputGroup>
        <FormErrorMessage>
          {errors.username && errors.username.message}
        </FormErrorMessage>
        </FormControl>

            {/*이메일
          <FormControl isRequired isInvalid={Boolean(errors.email)}>
            <FormLabel>Email</FormLabel>
              <InputGroup size={"md"}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaEnvelope />
                    </Box>
                  }
                />

          <Input
            {...register("email", {
              required: "Please enter your email correctly",
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "ex) fourteen@gmail.com",
              },
            })}
            variant={"filled"}
            placeholder="Email"
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
        </FormControl>*/}
        
            {/*비밀번호*/}
          <FormControl isRequired isInvalid={Boolean(errors.password)}>
            <FormLabel>Password</FormLabel>
            <InputGroup size={"md"}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaLock />
                    </Box>
                  }
                />
                <Input
            {...register("password", {
              required: "Password is at least 8 characters long",
              minLength: {
                value: 8,
                message:
                  "a–z + A–Z + 0-9 + !@#$%^&",
              },
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message:
                  "a–z + A–Z + 0-9 + !@#$%^&",
              },
            })}
            variant={"filled"}
            placeholder="Password"
            type={show ? "text" : "password"}
          />
        <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
        </InputRightElement>
        </InputGroup>  
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
        </FormControl>

            {/*비밀번호확인*/}
          <FormControl isRequired isInvalid={Boolean(errors.re_password)}>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaCheckCircle />
                    </Box>
                  }
                />
          <Input
            {...register("re_password", {
              required: "Please reconfirm your password",
              validate: (value) => {
                return value === watch('password') || "Please check your password"
              }
            })}
            variant={"filled"}
            placeholder="Confirm Password"
            type={show ? "text" : "password"}
          />
        <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
        </InputRightElement>
        </InputGroup>  
        <FormErrorMessage>
          {errors.re_password && errors.re_password.message}
        </FormErrorMessage>
        </FormControl>

            </VStack>
            <Button
              isLoading={mutation.isLoading}
              w="100%"
              colorScheme="purple"
              mt={4}
              type="submit"
            >
              Sign Up
            </Button>
           {/* <SocialLogin /> */}

           <Text fontSize={'xs'} mt={2}>
           *By joining 
           <Text 
           ml={1} 
           mr={1} 
           as="b"
           >
           PASHOP 
           </Text>
           ,You can receive many benefits.
          </Text>

          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }   