import { Grid, Heading, HStack, Link, VStack, Box } from "@chakra-ui/layout";
import { MoimCard } from "../../components/market/MoimCard";
import StyledButton from "../../components/market/StyledButton";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@chakra-ui/button";
import { SubHeader } from "../../components/market/SubHeader";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getHome } from "../../api";
import { LoadingPage } from "../../components/market/LoadingPage";
import { Imoim } from "../../types";
import MoimSlider from "../../components/market/MoimSlider";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function MoimHome() {
	const gridColumnSystem = {
		sm: "1fr",
        md: "1fr 1fr",
        lg: "repeat(3, 1fr)",
        xl: "repeat(4, 1fr)",
        "2xl": "repeat(5, 1fr)",
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { isLoading, data } = useQuery<Imoim[]>(["moims"], getHome);

	if (isLoading) {
		return <LoadingPage />;
	} else {
		return (

			<VStack position={"relative"}>
				<HelmetProvider>
					<Helmet>
					<title>MOIM</title>
				</Helmet>
				</HelmetProvider>

				<SubHeader
					to={"/moims/create"}
					btnName={"모임 생성하기"}
					headerTitle={"MOIM"}
					hasBtn={true}
				/>
				
				<MoimSlider />
				
			
				<Grid 
				templateColumns={gridColumnSystem} 
				gap={6} 
				pt={12}
				p={10}
				>
					{data?.map((moim) => {
						return (
							<MoimCard
								id={moim.id}
								key={moim.id}
								title={moim.title}
								hasLeader={moim.has_leader}
								moimTypes={moim.moim_types}
								topics={moim.topics}
								current={moim.current_number_of_participants}
								min={moim.min_participants}
								expirationDate={moim.expiration_date}
							/>
						);
					})}
					{data?.length === 0 ? (
						<VStack>
							<Heading fontSize={"2xl"}>모임이 없습니다.</Heading>
							<Heading fontSize={"lg"}>모임을 만들어보세요!</Heading>
						</VStack>
					) : null}
				</Grid>
			
			</VStack>
		);
	}
}