import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <VStack
      bg="linear-gradient(to bottom, #DBE6F6, #EAECEF)"
      justifyContent={"center"}
      minH="100vh"
      px={4}
    >
      <Heading 
      as="h1" 
      fontSize={{ base: "4xl", md: "6xl" }} 
      mb={8} 
      textAlign="center"
      bgGradient='linear(to-l, #7928CA, #FF0080)'
      bgClip='text'    
      >
        Hey There!
      </Heading>

      <Heading as="h2" fontSize={{ base: "lg", md: "3xl" }} mb={8} textAlign="center">
      You can now use
      <Text ml={2} as="span" color="purple.500">PASHOP</Text>
       <br />The world's best wholesale platform.
      </Heading>
      <Text fontSize={{ base: "md", md: "xl" }} mb={8} textAlign="center">
      Please go back to home and create your profile.
      </Text>
      <Link to="/">
      <Button mt={5} colorScheme={"purple"} variant={"solid"}>
          Go home &rarr;
        </Button>
      </Link>
    </VStack>
  );
}
