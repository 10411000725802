import { useForm } from "react-hook-form";
import {
    Box,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
    VStack,
    InputRightElement,
    HStack,
    useDisclosure,
  } from "@chakra-ui/react";
  import React, { useState } from "react";
  import { FaUserShield, FaLock } from "react-icons/fa";
  import SocialLogin from "./SocialLogin";
  import { useMutation, useQueryClient } from "@tanstack/react-query";
  import {
  usernameLogIn,
} from "../api";
  import SignUpModal from "../components/SignUpModal";

  interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  interface IForm {
    username: string;
    password: string;
  }

  export default function LoginModal({ isOpen, onClose }: LoginModalProps) {
    const {
      register,
      reset,
      handleSubmit,
      formState: { errors },

    } = useForm<IForm>();
    const toast = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation(usernameLogIn, {
      onSuccess: (data) => {
        toast({
          status: "success",
          title: "Welcome!",
          description: "You are logged in.",
          position: "bottom-right",
        });
        onClose();
        reset();
        queryClient.refetchQueries(["me"]);
      },
    });
    const onSubmit = ({ username, password }: IForm) => {
      mutation.mutate({ username, password });
    };

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    const {
      isOpen: isSignUpOpen,
      onClose: onSignUpClose,
      onOpen: onSignUpOpen,
    } = useDisclosure();
    

    return (
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Log in</ModalHeader>
          <ModalCloseButton />
          <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
            <VStack>
              <InputGroup size={"md"}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaUserShield />
                    </Box>
                  }
                />
              <Input
                isInvalid={Boolean(errors.username?.message)}
                {...register("username", {
                  required: "Please write a username",
                })}
                variant={"filled"}
                placeholder="Username"
              />
              </InputGroup>
              <InputGroup>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaLock />
                    </Box>
                  }
                />
              <Input
                isInvalid={Boolean(errors.password?.message)}
                {...register("password", {
                  required: "Please write a password",
                })}
                variant={"filled"}
                placeholder="Password"
                type={show ? "text" : "password"}
              />
              <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={handleClick}>
                {show ? 'Hide' : 'Show'}
              </Button>
              </InputRightElement>
              </InputGroup>
            </VStack>
            {mutation.isError ? (
            <Text color="purple.500" textAlign={"center"} fontSize="sm">
             Username or Password are wrong
            </Text>
          ) : null}
            <Button
            isLoading={mutation.isLoading}
            type="submit"
            mt={4}
            colorScheme={"purple"}
            w="100%"
            >
              Log in
            </Button>
            {/*<SocialLogin />*/}
            <Text fontSize={'sm'} mt={2}>
            Not a member yet? 
              
              <Button 
                ml={2}
                fontSize={'sm'}
                variant="link"
                onClick={onSignUpOpen} 
                colorScheme="purple"
                >
                Sign up
                </Button>
                
                <SignUpModal isOpen={isSignUpOpen} onClose={() => {
                  onSignUpClose();
                  onClose();
                }} />
              
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }