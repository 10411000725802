import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Heading,
    Input,
    InputGroup,
    Select,
    Text,
    Textarea,
    useToast,
    VStack,
    RangeSlider,
	  RangeSliderTrack,
  	RangeSliderFilledTrack,
	  RangeSliderThumb,
    VisuallyHiddenInput,
    ButtonGroup,
    HStack,
  } from "@chakra-ui/react";
  import { ReactNode, useEffect, useState, useRef } from "react";
  import { useForm } from "react-hook-form";
  import { useParams } from "react-router-dom";
  import { useMutation, useQuery } from "@tanstack/react-query";
  import {
    getAmenities,
    getCategories,
    IUploadRoomVariables,
    uploadRoom,
  } from "../api";
  import useHostOnlyPage from "../components/HostOnlyPage";
  import ProtectedPage from "../components/ProtectedPage";
  import { IAmenity, ICategory, IRoomDetail } from "../types";
  import { useNavigate } from "react-router-dom";
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import StyledButton from "../components/market/StyledButton";
  import NationList from "react-select-country-list";

  {/*업로드 룸*/}
  export default function UploadRooms() {

    const [step, setStep] = useState(1);

    const prevStep = () => {
      setStep((prev) => prev - 1);
    };

    const nextStep = () => {
      setStep((prev) => prev + 1);
    };

    const disabledBtnStyle = {
      backgroundColor: "#DFE1E1",
      cursor: "not-allowed",
    };

    interface IBaseProps {
      children: ReactNode;
    }
    const { 
      register,
      handleSubmit,
      getValues,
      setValue,
		  formState: { errors },
    } = useForm<IUploadRoomVariables>();
    const toast = useToast();
    const navigate = useNavigate();
    const mutation = useMutation(uploadRoom, {
      onSuccess: (data: IRoomDetail) => {
        toast({
          status: "success",
          title: "Brand created",
          position: "bottom-right",
        });
        navigate(`/rooms/${data.id}`);
      },
    });
    const { data: amenities } = useQuery<IAmenity[]>(["amenities"], getAmenities);
    const { data: categories } = useQuery<ICategory[]>(
      ["categories"],
      getCategories
    );
    const { roomPk } = useParams();
    const onSubmit = (data: IUploadRoomVariables) => {
        mutation.mutate(data);
      };
    
    const [capacityLimit, setCapacityLimit] = useState({
			min: 1000,
			max: 5000,
		});
    const [priceLimit, setPriceLimit] = useState({
			min: 100,
			max: 300,
		});
    const [deliveryLimit, setDeliveryLimit] = useState({
			min: 1,
			max: 30,
		});

    const Base = ({ children }: IBaseProps) => (
      <ProtectedPage>
   <VStack pb={20} mt={10} px={{ base: 10, lg: 40}}>
        <VStack p={10} pt={12} spacing={3}>
            {children}
            </VStack>
          </VStack>
      </ProtectedPage>
    );

    {/*리액트 국가 패키지 */}
    const [nationality, setCountry] = useState("");

    const handleChange = (val: string) => {
        setCountry(val);
    };
  
        
    const Step1 = () => {
      
    return (
        <>
    
              <HelmetProvider>
              <Helmet>
                <title>Upload Brand</title>
              </Helmet>
              </HelmetProvider>
              <Box pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>Brand Report</Heading>
              </Box>

            {/*네임 폼*/}
              <FormControl>
                <FormLabel>Brand(브랜드)</FormLabel>
              <Input
                {...register("name", { required: true })}
                required
                type="text"
              />
                <FormHelperText>Write the name of your Brand.</FormHelperText>
              </FormControl>

              {/*국가 폼*/}
              <FormControl>
                <FormLabel>Brand Nationality</FormLabel>
              <Select
                {...register("nationality", { required: true })}
                placeholder="Choose a nationality"
              >
                 {NationList().getData().map((nationality) => (
                    <option key={nationality.value} value={nationality.label}>
                    {nationality.label}
                    </option>
                ))}
                </Select>
              </FormControl>
            
            {/*카테고리 폼*/}
              <FormControl>
              <FormLabel>Category(카테고리)</FormLabel>
              <Select
                {...register("category", { required: true })}
                placeholder="Choose a category"
              >
                {categories?.map((category) => (
                  <option key={category.pk} value={category.pk}>
                    {category.name}
                  </option>
                ))}
              </Select>
              <FormHelperText>
                What category describes your brand?
              </FormHelperText>
            </FormControl>

            {/*년도 폼*/}
            <FormControl>
                <FormLabel>Year(제품년도)</FormLabel>
              <Input
                {...register("year", { required: true })}
                required
                type="text"
              />
                <FormHelperText>Write the Product of Year</FormHelperText>
              </FormControl>

            {/*시즌 폼*/}
            <FormControl>
                <FormLabel>Season(제품시즌)</FormLabel>
              <Select
                {...register("season", { required: true })}
                placeholder="Choose a kind"
              >
                  <option value="spring/summer">Spring/Summer</option>
                  <option value="fall/winter">Fall/Winter</option>
                </Select>
                <FormHelperText>
                  What kind of season?
                </FormHelperText>
              </FormControl>

              {/*설명 폼*/}
              <FormControl>
                <FormLabel>Product Details(상품설명)</FormLabel>
              <Textarea {...register("description", { required: true })} />
              </FormControl>

              {/*종류 폼*/}
              <FormControl>
                <FormLabel>Kind of Sales(도매/위탁)</FormLabel>
              <Select
                {...register("kind", { required: true })}
                placeholder="Choose a kind"
              >
                  <option value="wholesale">Wholesale</option>
                  <option value="dropshipping">Dropshipping</option>
                </Select>
                <FormHelperText>
                  What kind of sales are you sale?
                </FormHelperText>
              </FormControl>

              {/*홈페이지 폼*/}
              <FormControl>
                <FormLabel>Homepage(홈페이지)</FormLabel>
              <Input
                {...register("homepage", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*주소 폼*/}
              <FormControl>
                <FormLabel>Address(회사주소)</FormLabel>
              <Input
                {...register("address", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*등록자 폼*/}
              <FormControl>
                <FormLabel>Contact Person(담당자)</FormLabel>
              <Input
                {...register("person", { required: true })}
                required
                type="text"
              />
              </FormControl>

              {/*컨츄리 폼*/}
              <FormControl>
                <FormLabel>Country Of Manufacture(생산국)</FormLabel>
              <Input
                {...register("country", { required: true })}
                required
                type="text"
              />
              </FormControl>
              
              {/*생산능력 폼*/}
              <FormControl>
                <FormLabel>Production Capacity(생산가능수량): 
                  <Box mt={2} mb={5} fontWeight="bold" fontSize="md">
                    {capacityLimit.min} ~ {capacityLimit.max}/PCS
                  </Box>
                </FormLabel>
                <RangeSlider
                  defaultValue={[capacityLimit.min, capacityLimit.max]}
                  min={0}
                  max={9000}
                  step={500}
                  onChangeEnd={([min, max]) => {
                    setCapacityLimit({ min: min, max: max });
                    setValue("min_capacity", min);
                    setValue("max_capacity", max);
                  }}
                >
                  <RangeSliderTrack bg="#E3E5E5">
                    <RangeSliderFilledTrack bg="#6B4EFF" />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    boxSize={10}
                    index={0}
                    bg={"#6B4EFF"}
                    color={"white"}
                  >
                  {capacityLimit.min}
                  </RangeSliderThumb>
                  
                  <RangeSliderThumb
                    boxSize={10}
                    index={1}
                    bg={"#6B4EFF"}
                    color={"white"} 
                  >
                  {capacityLimit.max}
                  </RangeSliderThumb>
                </RangeSlider>
                <FormHelperText>
                  생산가능 수량을 입력해주세요.
                </FormHelperText>
                <VisuallyHiddenInput
                  type={"number"}
                  {...register("min_capacity", {
                    required: "최소 수량을 설정해주세요.",
                  })}
                  value={capacityLimit.min}
                />
                <VisuallyHiddenInput
                  type={"number"}
                  {...register("max_capacity", {
                    required: "최대 수량 설정해주세요.",
                  })}
                  value={capacityLimit.max}
                />
              </FormControl>

              <ButtonGroup
            gap="4"
            w={"100%"}
            display={"grid"}
            gridTemplateColumns={"1fr 1fr"}
          >
            <Button
              mt={5}
              borderRadius={150}
              bg="#6B4EFF"
              color={"white"}
              isDisabled
              _disabled={disabledBtnStyle}
            >
              &larr; Previous
            </Button>
            <Button
              mt={5}
              borderRadius={150}
              bg="#6B4EFF"
              color={"white"}
              onClick={() => {
       
                  nextStep();
              }}
            >
              Next &rarr;
            </Button>
          </ButtonGroup>
      
        </>
    );
};

const Step2 = () => {

return (
    <>
            <Box pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>Information</Heading>
            </Box>

            {/*최소수량 폼*/}
              <FormControl>
                <FormLabel>Minimum Quantity(판매최소수량/Pcs)</FormLabel>
                <InputGroup>
                <Input
                  {...register("quantity", { required: true })}
                  type="number"
                  min={0}
                />
                </InputGroup>
                <FormHelperText>
                  Ex: 150
                </FormHelperText>
              </FormControl>

              {/*평균가격 폼*/}
              <FormControl>
                <FormLabel>Wholesale Price Range(USD:달러):
                <Box mt={2} mb={5} fontWeight="bold" fontSize="md">
                    {priceLimit.min} ~ {priceLimit.max}/USD
                </Box>
                </FormLabel>
                <RangeSlider
                  defaultValue={[priceLimit.min, priceLimit.max]}
                  min={10}
                  max={1000}
                  step={50}
                  onChangeEnd={([min, max]) => {
                    setPriceLimit({ min: min, max: max });
                    setValue("min_price", min);
                    setValue("max_price", max);
                  }}
                >
                  <RangeSliderTrack bg="#E3E5E5">
                    <RangeSliderFilledTrack bg="#6B4EFF" />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    boxSize={10}
                    index={0}
                    bg={"#6B4EFF"}
                    color={"white"}
                  >
                    {priceLimit.min}
                  </RangeSliderThumb>
                  <RangeSliderThumb
                    boxSize={10}
                    index={1}
                    bg={"#6B4EFF"}
                    color={"white"}
                  >
                    {priceLimit.max}
                  </RangeSliderThumb>
                </RangeSlider>
                <FormHelperText>
                  상품의 평균 홀세일 가격을 입력해주세요.
                </FormHelperText>
                <VisuallyHiddenInput
                  type={"number"}
                  {...register("min_price", {
                    required: "최소 홀세일 가격을 설정해주세요.",
                  })}
                  value={priceLimit.min}
                />
                <VisuallyHiddenInput
                  type={"number"}
                  {...register("max_price", {
                    required: "최대 홀세일 가격을 설정해주세요.",
                  })}
                  value={priceLimit.max}
                />
              </FormControl>

              {/*결제방식 폼*/}
              <FormControl>
                <FormLabel>Payment Terms(지급방법)</FormLabel>
                <Box p={5} borderWidth="1px" rounded="md">
                  <HStack spacing={5}>
                <Checkbox 
                  {...register("paypal", { required: false } )}>
                    Paypal
                  </Checkbox>

                  <Checkbox 
                  {...register("tt", { required: false })}>
                    T/T
                  </Checkbox>
                  </HStack>
                  </Box>
                  <FormHelperText>
                  Please check the payment method.
                  </FormHelperText>
              </FormControl>

              {/*배송기간 폼*/}
              <FormControl>
                <FormLabel>Estimated Delivery Of Period(DAY:일):
                <Box mt={2} mb={5} fontWeight="bold" fontSize="md">
                    {deliveryLimit.min} ~ {deliveryLimit.max}/DAYS
                </Box>
                </FormLabel>
                <RangeSlider
                  defaultValue={[deliveryLimit.min, deliveryLimit.max]}
                  min={1}
                  max={60}
                  step={1}
                  onChangeEnd={([min, max]) => {
                    setDeliveryLimit({ min: min, max: max });
                    setValue("min_delivery", min);
                    setValue("max_delivery", max);
                  }}
                >
                  <RangeSliderTrack bg="#E3E5E5">
                    <RangeSliderFilledTrack bg="#6B4EFF" />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    boxSize={6}
                    index={0}
                    bg={"#6B4EFF"}
                    color={"white"}
                  >
                    {deliveryLimit.min}
                  </RangeSliderThumb>
                  <RangeSliderThumb
                    boxSize={6}
                    index={1}
                    bg={"#6B4EFF"}
                    color={"white"}
                  >
                    {deliveryLimit.max}
                  </RangeSliderThumb>
                </RangeSlider>
                <FormHelperText>
                  배송 기간을 설정해주세요.
                </FormHelperText>
                <VisuallyHiddenInput
                  type={"number"}
                  {...register("min_delivery", {
                    required: "최소 배송기간을 설정해주세요.",
                  })}
                  value={deliveryLimit.min}
                />
                <VisuallyHiddenInput
                  type={"number"}
                  {...register("max_delivery", {
                    required: "최대 배송기간을 설정해주세요.",
                  })}
                  value={deliveryLimit.max}
                />
              </FormControl>

              {/*운송방법 폼*/}
              <FormControl>
                <FormLabel>Transportation(배송방법)</FormLabel>
                <Box p={5} borderWidth="1px" rounded="md">
                  <HStack spacing={5}>
                <Checkbox 
                  {...register("sea", { required: false })}>
                    해상(Maritime Transport)
                  </Checkbox>

                  <Checkbox 
                  {...register("air", { required: false })}>
                    항공(Air Transportation)
                  </Checkbox>

                  <Checkbox 
                  {...register("multi", { required: false })}>
                    복합운송(Multi Modal)
                  </Checkbox>
                  </HStack>
                  </Box>
                  <FormHelperText>
                  Please select a transportation method.
                  </FormHelperText>
              </FormControl>
            
            {/*어메니티 폼*/}
            <FormControl>
              <FormLabel>Incoterms(인코텀즈)</FormLabel>
              <Grid templateColumns={"1fr 1fr 1fr"} gap={5}>
                {amenities?.map((amenity) => (
                  <Box key={amenity.pk}>
                    <Checkbox
                      value={amenity.pk}
                      {...register("amenities", { required: true })}
                    >
                      {amenity.name}
                    </Checkbox>
                    <FormHelperText>{amenity.description}</FormHelperText>
                  </Box>
                ))}
              </Grid>
            </FormControl>

            <ButtonGroup
              gap="4"
              w={"100%"}
              display={"grid"}
              gridTemplateColumns={"1fr 1fr"}
            >
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                onClick={prevStep}
              >
                &larr; Previous
              </Button>
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                isDisabled
                _disabled={disabledBtnStyle}
              >
                Next &rarr;
              </Button>
            </ButtonGroup>
            <VStack py={6}>
            {mutation.isError ? (
                              <Text color={"red.500"}>Wrong</Text>
                          ) : null}
              <StyledButton
              btnName={"Upload"}
              hasArrow={true}
              onClick={handleSubmit(onSubmit)}
              isLoading={mutation.isLoading}
            />
          </VStack>
    </>
  );
};              
switch (step) {
  case 1:
    return (
      <Base>
        <Step1 />
      </Base>
    );
  case 2:
    return (
      <Base>
        <Step2 />
      </Base>
    );
  default:
    return null;
}
};