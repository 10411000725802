import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getWishlists, getRooms } from "../api";
import { Link, useParams } from "react-router-dom";
import { IRoomList } from "../types";
import { useState } from "react";
import { IconButton, useToast } from "@chakra-ui/react";
import { FaHeart } from "react-icons/fa";

interface IWishlistPage {
  pk: number;
  name: string;
  rooms: {
    name: string;
    price: number;
    pk: number;
    year: string;
    season: string
    rating: number;
    is_owner: boolean;
    photos: string;
  };
  experiences: string;
  user: string;
}

export default function WishlistsPage() {
  const { wishlistId } = useParams<{ wishlistId: string }>();
  const { data } = useQuery<IRoomList>(["rooms"], getRooms);
  const { data: wishlists } = useQuery<IWishlistPage[]>(
    ["wishlists"],
    getWishlists
  );
  const [liked, setLiked] = useState(false);
  const toast = useToast();

  const wishlist = wishlists?.find((w) => w.pk === Number(wishlistId));

  const handleLikeClick = () => {
    if (liked) {
      // Remove item from wishlist
      setLiked(false);
      toast({
        title: `${wishlist?.name} removed from wishlist`,
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    } else {
      // Add item to wishlist
      setLiked(true);
      toast({
        title: `${wishlist?.name} added to wishlist`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Grid
        templateColumns= "repeat(3, 1fr)"
        templateRows= "repeat(3, minmax(10px, auto))"
       
        w={"100%"}
        bgColor="gray.200"
        alignItems={"center"}
        justifyItems="center"
        borderTop={"1px solid gray"}
        borderBottom={"1px solid rgb(190,190,190)"}
        py={4}
        mb={2}
      >
      <img src={wishlist?.rooms.photos} alt={wishlist?.name} />
      <h3>{wishlist?.name}</h3>
      <p>{wishlist?.experiences}</p>
      <IconButton
        aria-label={liked ? "Remove from wishlist" : "Add to wishlist"}
        icon={<FaHeart />}
        onClick={handleLikeClick}
        colorScheme={liked ? "red" : "gray"}
      />
    </Grid>
  );
}
