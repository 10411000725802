import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import {
	FormControl,
	FormHelperText,
	FormLabel,
	FormErrorMessage,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import {
	Divider,
	Grid,
	Heading,
	HStack,
	Link,
	Text,
	VStack,
	Box,
	Container,
} from "@chakra-ui/layout";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Textarea } from "@chakra-ui/textarea";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Children, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { 
	getMyPortfolios, 
	getEditLeaderApply, 
	getLeaderApply, 
	postLeaderApply, 
	ILeaderApplyVariable,
	putLeaderApply,
	IEditLeaderApplyVariable,
} from "../../api";
import { LoadingPage } from "../../components/market/LoadingPage";
import { PortfolioCard } from "../../components/market/PortfolioCard";
import StyledButton from "../../components/market/StyledButton";
import { SubHeader } from "../../components/market/SubHeader";
import useUserProfile from "../../lib/useUserProfile";
import { 
	IleaderApply, 
	IEditLeaderApply, 
	Iportfolio 
} from "../../types";
import { Button } from "@chakra-ui/button";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import ProtectedPage from "../../components/ProtectedPage";
import { useToast } from "@chakra-ui/toast";
import { useForm } from "react-hook-form";
import DeleteLeaderModal from "../../components/market/DeleteLeaderApply";

interface IApplyLeaderForm {
	portfolioList: {
		id: number;
		title: string;
		description: string;
		url: string;
	}[];
}

interface IPortfolioCardModalBtn {
	id: number;
	title: string;
	short_description: string;
	url: string;
}

const PortfolioCardModalBtn = ({
	id,
	title,
	short_description,
	url,
}: IPortfolioCardModalBtn) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Button variant={"link"} onClick={onOpen}>
				{title}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent pb={3}>
					<ModalCloseButton />
					<ModalHeader>포트폴리오 상세</ModalHeader>
					<ModalBody borderRadius={15} as={VStack} alignItems={"flex-start"}>
						<Heading size={"md"}>{title}</Heading>
						<Link color={"blue.400"}>{url}</Link>
						<Divider />
						<Heading pt={3} size={"sm"}>
							포트폴리오 설명
						</Heading>
						<Box
							p={6}
							border={"1px solid #DCDCDC"}
							borderRadius={15}
							width={"100%"}
							h={"max-content"}
						>
							{short_description}
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export const ApplyLeaderCreate = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { moimId } = useParams();
	const { isLoggedIn, userLoading } = useUserProfile();
	const navigate = useNavigate();
	useEffect(() => {
		if (!userLoading) {
			if (!isLoggedIn) {
				navigate("/signin");
			}
		}
	}, [userLoading, isLoggedIn, navigate]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ILeaderApplyVariable>();

	const toast = useToast();
	const mutation = useMutation(postLeaderApply, {
		onSuccess: (data: IleaderApply) => {
			toast({
				title: "리더 신청서가 제출되었습니다.",
				status: "success",
			});
			navigate(`/moims/${moimId}`);
		},
		onError: (error) => {
			toast({
				title: "제출 실패 : 다시 시도해주세요.",
				status: "error",
			});
		},
	});

	const onSubmit = ({ description, portfolios }: ILeaderApplyVariable) => {
		mutation.mutate({ description, portfolios, moimId });
	};
    const { data: portfoliolist } = useQuery<Iportfolio[]>(
		[`moims`, moimId, `apply-leader`, `create`], getMyPortfolios);

	console.log(portfoliolist);

		return (
			<ProtectedPage>
				<VStack>
					<SubHeader headerTitle={"리더 지원서 작성하기"} />
					<VStack spacing={3} pt={12}>
			<FormControl>
				<FormLabel>하고 싶은 말</FormLabel>
				<Textarea
						placeholder={"하고 싶은 말"}
						isInvalid={Boolean(errors.description?.message)}
						{...register("description", {
							required: "하고 싶은 말을 입력해주세요.",
						})}
					/>
				<FormHelperText>
					어떤 분야의 어떤 역량을 가지고 있는지 자세하게 적어주세요.
				</FormHelperText>
			</FormControl>
			<FormControl>
				<FormLabel>포트폴리오 선택</FormLabel>
				<VStack
					spacing={5}
					maxH={"250px"}
					overflow={"hidden"}
					overflowY={"scroll"}
					border={"1px solid #DEE3EE"}
					borderRadius={6}
				>
					<CheckboxGroup>
						<Divider />
						{portfoliolist && portfoliolist.length && portfoliolist.map((portfolio) => (
							<Container key={portfolio.id} px={6} mt={0}>
								<HStack justifyContent={"space-between"} width={"100%"} pb={3}>
									<VStack alignItems={"flex-start"}>
										<PortfolioCardModalBtn
											id={portfolio.id}
											title={portfolio.title}
											url={portfolio.url}
											short_description={portfolio.description}
										/>
										<Text fontSize={"14px"} color={"#72777A"}>
											{portfolio.url}
										</Text>
									</VStack>
									<Checkbox
										colorScheme="purple"
										value={`${portfolio.id}`}
										{...register("portfolios", { required: true })}
									>
										선택
									</Checkbox>
								</HStack>
								<Divider />
							</Container>
						))}
					</CheckboxGroup>
				</VStack>
				<FormHelperText>
					모임의 성격과 가장 잘 맞는 포트폴리오를 제출해주세요.
				</FormHelperText>
			</FormControl>
			<hr />

			<StyledButton 
						btnName={"지원서 제출하기"}
						onClick={handleSubmit(onSubmit)}
						isLoading={mutation.isLoading}
					 />
		</VStack>
				</VStack>
			</ProtectedPage>
		);
};

export const ApplyLeaderDetail = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { moimId, leaderApplyId } = useParams();
	const navigate = useNavigate();

	const { isLoading, data } = useQuery<IleaderApply>({
		queryKey: [`moims`, moimId, `apply-leader`, leaderApplyId],
		queryFn: () => getLeaderApply(leaderApplyId),
		
		retry: false,
	});

	if (isLoading) return <LoadingPage />;
	else
		return (
			<VStack spacing={6}>
				<SubHeader headerTitle={`${data?.owner.user}님의 리더 지원서`} />
				<Grid
					pt={12}
					width={"100%"}
					gridTemplateColumns={{ sm: "1fr", md: "1fr 1fr" }}
					gap={6}
				>
					<VStack>
						<Heading size={"md"} alignSelf={"flex-start"}>
							하고 싶은 말
						</Heading>
						<Box
							p={6}
							border={"1px solid #DCDCDC"}
							borderRadius={15}
							width={"100%"}
							h={"max-content"}
						>
							{data?.description}
						</Box>
					</VStack>
					<VStack alignItems={"flex-start"}>
						<Heading size={"md"}>제출한 포트폴리오</Heading>
						{data?.portfolios.map((portfolio) => (
							<PortfolioCard
								key={portfolio.id}
								id={portfolio.id}
								title={portfolio.title}
								description={portfolio.description}
								url={portfolio.url}
								viewOnly={true}
							/>
						))}
					</VStack>
				</Grid>
			</VStack>
		);
};

export const ApplyLeaderEdit = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { moimId, leaderApplyId } = useParams();
	const { isLoggedIn, userLoading } = useUserProfile();
	const navigate = useNavigate();
	useEffect(() => {
		if (!userLoading) {
			if (!isLoggedIn) {
				navigate("/signin");
			}
		}
	}, [userLoading, isLoggedIn, navigate]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IEditLeaderApplyVariable>();
	const toast = useToast();
	const mutation = useMutation(putLeaderApply, {
		onSuccess: (data: IleaderApply) => {
			toast({
				title: "리더 신청서가 수정되었습니다.",
				status: "success",
			});
			navigate(`/moims/${moimId}`);
		},
		onError: (error) => {
			toast({
				title: "수정 실패 : 다시 시도해주세요.",
				status: "error",
			});
		},
	});
	const onSubmit = ({ description, portfolios }: IEditLeaderApplyVariable) => {
		mutation.mutate({ description, portfolios, moimId, leaderApplyId });
	};
	
	const { data: Url } = useQuery<IEditLeaderApply>([`apply-leader`, leaderApplyId], getEditLeaderApply);
    const selected_portfolios = Url?.portfolios;
	const { data: portfoliolist } = useQuery<Iportfolio[]>(
		[`moims`, moimId, `apply-leader`, leaderApplyId, `create`], getMyPortfolios);

	{/*Delete Click */}
	const {
		isOpen: isDeleteOpen,
		onClose: onDeleteClose,
		onOpen: onDeleteOpen,
	} = useDisclosure();

	console.log(portfoliolist);
		return (
			<ProtectedPage>
				<VStack>
					<SubHeader headerTitle={"리더 지원서 수정하기"} />
					<VStack spacing={3} pt={12}>
			<FormControl isRequired isInvalid={Boolean(errors.description)}>
				<FormLabel>하고 싶은 말</FormLabel>
				<Textarea
						placeholder={"하고 싶은 말"}
					
						defaultValue={Url?.description}
						{...register("description", {
							required: "수정하는 내용을 적어주세요",
							minLength: { value: 1, message: "Minimum length should be 1 characters" },
						})}
					
					/>
				<FormHelperText>
					어떤 분야의 어떤 역량을 가지고 있는지 자세하게 적어주세요.
				</FormHelperText>
				<FormErrorMessage>
          		{errors.description && errors.description.message}
       			</FormErrorMessage>
			</FormControl>
			<FormControl>
				<FormLabel>포트폴리오 선택</FormLabel>
				<VStack
					spacing={5}
					maxH={"250px"}
					overflow={"hidden"}
					overflowY={"scroll"}
					border={"1px solid #DEE3EE"}
					borderRadius={6}
				>
					<CheckboxGroup>
						<Divider />
						{portfoliolist && portfoliolist.length && portfoliolist.map((portfolio) => (
							<Container key={portfolio.id} px={6} mt={0}>
								<HStack justifyContent={"space-between"} width={"100%"} pb={3}>
									<VStack alignItems={"flex-start"}>
										<PortfolioCardModalBtn
											id={portfolio.id}
											title={portfolio.title}
											url={portfolio.url}
											short_description={portfolio.description}
										/>
										<Text fontSize={"14px"} color={"#72777A"}>
											{portfolio.url}
										</Text>
									</VStack>
									<Checkbox
										colorScheme="purple"
										value={`${portfolio.id}`}
										defaultChecked={selected_portfolios?.includes(
											portfolio.id
										  )}
										{...register("portfolios")}
									>
										
										선택
									</Checkbox>
								</HStack>
								<Divider />
							</Container>
						))}
					</CheckboxGroup>
				</VStack>
				<FormHelperText>
					모임의 성격과 가장 잘 맞는 포트폴리오를 제출해주세요.
				</FormHelperText>
			</FormControl>
			<hr />

			<StyledButton 
						btnName={"지원서 수정"}
						onClick={handleSubmit(onSubmit)}
						isLoading={mutation.isLoading}
					 />
			<Button
						color = "red"
						width={327}
						borderRadius={48}
						onClick={onDeleteOpen}
						>
							삭제
					<DeleteLeaderModal 
                    isOpen={isDeleteOpen} 
                    onClose={onDeleteClose} 
                    />
						</Button>
				
		</VStack>
				</VStack>
			</ProtectedPage>
		);
};