import {
	FormControl,
	FormHelperText,
	FormLabel,
} from "@chakra-ui/form-control";
import { Heading, Text, VStack, Grid } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import { useToast } from "@chakra-ui/toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { 
	getEditCrewJoin, 
	postCrewJoin, 
	IEditCrewJoinVariable, 
	putCrewJoin, 
} from "../../api";
import ProtectedPage from "../../components/ProtectedPage";
import StyledButton from "../../components/market/StyledButton";
import useUserProfile from "../../lib/useUserProfile";
import DeleteCrewModal from "../../components/market/DeleteCrewJoin";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";

interface IForm {
	description: string;
}

export const CrewJoinCreate = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { moimId } = useParams();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IForm>();

	const toast = useToast();
	const mutation = useMutation(postCrewJoin, {
		onSuccess: (data) => {
			toast({
				title: "모임 신청서가 제출되었습니다.",
				status: "success",
			});
			navigate(`/moims/${moimId}`);
		},
		onError: (error) => {
			toast({
				title: "제출 실패 : 다시 시도해주세요.",
				status: "error",
			});
		},
	});

	const onSubmit = ({ description }: IForm) => {
		mutation.mutate({ description, moimId });
	};

	return (
		<ProtectedPage>
			<VStack spacing={3} >
				<Heading size={"md"}>모임 신청서 작성하기</Heading>
				<VStack
					pt={12}
					spacing={3}
					width={"50%"}
					h={"max-content"}
					overflow={"hidden"}
					>
				<FormControl>
					<FormLabel>하고 싶은 말</FormLabel>
					<Textarea
						placeholder={"하고 싶은 말"}
						isInvalid={Boolean(errors.description?.message)}
						{...register("description", {
							required: "하고 싶은 말을 입력해주세요.",
						})}
					/>
					<FormHelperText>
						어떤 동기에서 모임을 신청하는지 작성해주세요.
					</FormHelperText>
				</FormControl>
				</VStack>
				<hr />
				<StyledButton
					btnName={"신청하기"}
					onClick={handleSubmit(onSubmit)}
					isLoading={mutation.isLoading}
				/>
			</VStack>
		</ProtectedPage>
	);
};

export const CrewJoinEdit = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const { isLoggedIn, userLoading } = useUserProfile();

	const { moimId, crewJoinId } = useParams();
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IEditCrewJoinVariable>();
	const toast = useToast();
	const mutation = useMutation(putCrewJoin, {
		onSuccess: (data: IForm) => {
			toast({
				title: "리더 신청서가 수정되었습니다.",
				status: "success",
			});
			navigate(`/moims/${moimId}`);
		},
		onError: (error) => {
			toast({
				title: "수정 실패 : 다시 시도해주세요.",
				status: "error",
			});
		},
	});
	const onSubmit = ({ description }: IEditCrewJoinVariable) => {
		mutation.mutate({ description, moimId, crewJoinId });
	};
	const { data: Url } = useQuery<IForm>([`crew-join`, crewJoinId], getEditCrewJoin);
{/*Delete Click */}
	const {
		isOpen: isDeleteOpen,
		onClose: onDeleteClose,
		onOpen: onDeleteOpen,
	} = useDisclosure();
	return (
		
		<ProtectedPage>
		<VStack spacing={6}>
			<Heading size={"md"}>모임 신청서 작성하기</Heading>

			<VStack
					pt={12}
					spacing={3}
					width={"50%"}
					h={"max-content"}
					overflow={"hidden"}
					>
			<FormControl>
				<FormLabel>하고 싶은 말</FormLabel>
				<Textarea
					placeholder={"하고 싶은 말"}
					isInvalid={Boolean(errors.description?.message)}
					defaultValue={Url?.description}
					{...register("description", {
						required: "하고 싶은 말을 입력해주세요.",
					})}
				/>
				<FormHelperText>
					어떤 동기에서 모임을 신청하는지 작성해주세요.
				</FormHelperText>
			</FormControl>
			</VStack>
			<hr />
			<StyledButton
				btnName={"수정하기"}
				onClick={handleSubmit(onSubmit)}
				isLoading={mutation.isLoading}
			/>
			<Button
						color = "red"
						width={327}
						borderRadius={48}
						onClick={onDeleteOpen}
						>
							삭제
							<DeleteCrewModal 
                    isOpen={isDeleteOpen} 
                    onClose={onDeleteClose} 
                    />
			</Button>
			
		</VStack>
	</ProtectedPage>
		
	);
};