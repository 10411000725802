import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import {
    Box,
    chakra,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    IconButton,
    useColorModeValue,
    Image,
    useToast,
    ToastId,
    useColorMode,
  } from '@chakra-ui/react';
  import { ReactNode } from 'react';
  import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
  import { BiMailSend } from 'react-icons/bi';
  import Newsletter from "../Newletter";

  const SocialButton = ({
    children,
    label,
    href,
  }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
      <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Text>
    );
  };
  
  export default function Footer() {
    {/*로고컬러모드*/}
    const { colorMode } = useColorMode();

    {/*Email JS */}
    const toast = useToast();
    const toastIdRef = React.useRef<ToastId>();
    function addToast() {
        toastIdRef.current = toast({ 
            title: 'Subscription complete!',
            description: "Thank you for subscribing to our newsletter.",
            status: 'success',
            duration: 9000,
            isClosable: true,
        });
        window.setTimeout("window.location.reload()",2000);
      }

    const form = React.useRef(null);
    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
      "service_d2xybft",
      "template_zap4acj",
      e.currentTarget,
      "p3Xjtd-4l8fGWA5YE"
    )
      .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

    return (
      <Box
        mt={100}
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
            spacing={8}>
            <Stack spacing={6}>
              <Box>
              <Link href="/" style={{ textDecoration: "none" }}>
                <Image
                  w={{ base: "100px" }}
                  h={{ base: "30px" }}
                  src={colorMode === "light" ? "/images/logob.png" : "/images/logodark.png"}
                  fallbackSrc={ colorMode === "light" ? "/images/logob.png" : "/images/logodark.png"}
                />
              </Link>
              </Box>

              <Text fontSize={'sm'}>
                © 2023 PVMLKOREA. All rights reserved
              </Text>
              <Stack direction={'row'} spacing={6}>
                <SocialButton label={'facebook'} href={'https://www.facebook.com/profile.php?id=100091875373393'}>
                  <FaFacebook />
                </SocialButton>
                <SocialButton label={'YouTube'} href={'#'}>
                  <FaYoutube />
                </SocialButton>
                <SocialButton label={'Instagram'} href={'https://www.instagram.com/pashop_official/'}>
                  <FaInstagram />
                </SocialButton>
              </Stack>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Company</ListHeader>
              <Link href={'/aboutus'}>About us</Link>
              <Link href={'https://www.pvml.kr/weare'}>We are</Link>
              <Link href={'/pricing'}>Pricing</Link>
              <Link href={'https://www.pvml.kr/teamup'}>TeamUp</Link>
              <Link href={'https://www.pvml.kr/why'}>Why</Link>
              <Link href={'https://www.pvml.kr/process'}>Process</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Support</ListHeader>
              <Link href={'/contactus'}>Contact us</Link>
              <Link href={'/termsofservice'}>Terms of Service</Link>
              <Link href={'/privacy'}>Privacy Policy</Link>
             
            </Stack>
            <Stack align={'flex-start'}>
           
            <form ref={form} onSubmit={sendEmail}>
              <ListHeader>Subscribe to our Newsletter</ListHeader>
              <Stack direction={'row'}>
                <Input
                  type="email"
                  name="user_email"
                  placeholder={'Your email address'}
                  bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                  border={0}
                  _focus={{
                    bg: 'whiteAlpha.300',
                  }}
                />
                <IconButton
                  bg={useColorModeValue('green.400', 'green.800')}
                  color={useColorModeValue('white', 'gray.800')}
                  _hover={{
                    bg: 'green.600',
                  }}
                  aria-label="Subscribe"
                  icon={<BiMailSend />}
                  type="submit"
                  variant="solid"
                  onClick={addToast}
                />
              </Stack>
              </form>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
    );
  }