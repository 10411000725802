import { 
    List,
    ListItem,
    ListIcon,
    Box, 
    Heading, 
    Container,
    Grid, 
    GridItem, 
    Text,
    Highlight,
    useColorMode,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import { getMybusiNess } from "../../api";
import { Link, useParams,  } from "react-router-dom";
import { IBusiness } from "../../types";
import useUser from "../../lib/useUser";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function MyBusiness() {
  const { colorMode } = useColorMode();
  const { roomPk } = useParams();
  const { user } = useUser();
  const { isLoading, data: business } = useQuery<IBusiness[]>(
    ["manageBookings"],
    getMybusiNess
  );
  return (
    <Box
      mt={10}
      px={{
        base: 10,
        lg: 40,
      }}
    >
    <HelmetProvider>
    <Helmet>
    <title>Business status</title>
    </Helmet>
    </HelmetProvider>

    <Container>
    <Heading textAlign={"center"}>신청결과</Heading>


      <Box mb={4}>
        <Text as={"b"}>
        Total of {business?.length} 
        </Text>
      </Box>
      <Grid
        templateColumns={"4fr 3fr 5fr 3fr"}
        gap={3}
        w={"100%"}
        bg={colorMode === "dark" ? "gray.700" : "gray.200"}
        alignItems={"center"}
        justifyItems="center"
        borderTop={"1px solid gray"}
        borderBottom={"1px solid rgb(190,190,190)"}
        py={4}
        mb={2}
      >
        <GridItem as={"b"}>사업내용</GridItem>
        <GridItem as={"b"}>담당자</GridItem>
        <GridItem as={"b"}>이메일</GridItem>
        <GridItem as={"b"}>Available</GridItem>
      </Grid>

      {/* skeleton */}
      {business?.map((business) => (
        <Grid
          key={business.pk}
          templateColumns={"4fr 3fr 5fr 3fr"}
          gap={3}
          w={"100%"}
          bgColor="white.200"
          alignItems={"center"}
          justifyItems="center"
          borderTop={"1px solid rgb(190,190,190)"}
          borderBottom={"1px solid rgb(190,190,190)"}
          py={6}
          mb={1}
        >
          <GridItem fontWeight={"400"} noOfLines={1}>

          <Link to={`/business/${roomPk}/modify`}>
              <Text noOfLines={1} _hover={{ color: "red.500" }}>
                사업지원연결 파트너 신청
              </Text>
          </Link>
       
          </GridItem>
          <GridItem
            fontWeight={"400"}
            display="flex"
            flexDirection={"column"}
            alignItems="start"
          >
            <Box>
              <Text>{business.nameko}</Text>
            </Box>
          </GridItem>
          <GridItem
            fontWeight={"400"}
            display="flex"
            flexDirection={"column"}
            alignItems="start"
          >
            <Box>
              <Text>{business.email}</Text>
            </Box>
        </GridItem>

    {business.waiting ? (
            <GridItem
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              color={"red.500"}
              fontWeight={"400"}
            >
              <Text as={"b"}>신청대기</Text>
            </GridItem>
          ) : null}
          
    {business.completed ? (
            <GridItem
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              color={"green.500"}
              fontWeight={"400"}
            >
              <Text as={"b"}>신청완료</Text>
            </GridItem>
          ) : null}
    {business.results ? (
            <GridItem
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              color={"blue.500"}
              fontWeight={"400"}
            >
              <Text as={"b"}>결과발표</Text>
            </GridItem>
          ) : null}
        </Grid>
      ))}

    <List mt={10} mb={10} spacing={3}>
    <ListItem>
        <Text>
        <ListIcon as={MdCheckCircle} color='green.500' />
        <Highlight query='신청대기' styles={{ fontWeight:'bold', color: 'red.500' }}>
        신청대기: 브랜드 소개서(영문) 메일 발송 필요. 첨부파일 용량은 최대 50MB.
        (customerservice@pvml.kr)
         </Highlight>
        </Text>
    </ListItem>
    <ListItem>
        <Text>
        <ListIcon as={MdCheckCircle} color='green.500' />
        <Highlight query='신청완료' styles={{ fontWeight:'bold', color: 'green.500' }}>
        신청완료: 바이어 매칭 시작.
         </Highlight>
        </Text>
    </ListItem>
    <ListItem>
        <Text>
        <ListIcon as={MdCheckCircle} color='green.500' />
        <Highlight query='결과발표' styles={{ fontWeight:'bold', color: 'blue.500' }}>
        결과발표: 바이어 매칭이 완료되면 최종 결과 전달.
         </Highlight>
        </Text>
    </ListItem>
    </List>
    </Container>

    </Box>
  );
}