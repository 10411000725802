import { 
    Grid, 
    Box,
    Text, 
    Center,
    Heading,
    Button,
    useDisclosure,
    Link,
    Image,
    Icon,
    InputGroup,
    InputLeftElement,
    Input,
    VStack,
  } from "@chakra-ui/react";
  import { useQuery } from "@tanstack/react-query";
  import { getPartners } from "../../api";
  import Partner from "../../components/partner/Partner";
  import RoomSkeleton from "../../components/RoomSkeleton";
  import { IPartnerList } from "../../types";
  import { useState } from "react";
  import { FaSearch } from "react-icons/fa";
  
  export default function PartnerHome() {
    const { isLoading, data: partners } = useQuery<IPartnerList[]>(["rooms"], getPartners);
    const [searchValue, setSearchValue] = useState("");
  
    const filteredPartners =
    partners &&
    partners.filter(
        (partner) =>
        partner.company.toLowerCase().includes(searchValue.toLowerCase()) ||
        partner.type.toLowerCase().includes(searchValue.toLowerCase()) 
      );
  
    
    return (
  <Box>

      <VStack mt={30}>
        <Box w="50%">
            <InputGroup size="md">
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FaSearch} color="gray.300" />}
              />
              <Input
                type="text"
                placeholder="Search Partners..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </InputGroup>
        </Box>  
        </VStack>
      <Grid
        mt={10}
        px={{
          base: 10,
          lg: 40,
        }}
        columnGap={4}
        rowGap={8}
        templateColumns={{
          sm: "1fr 1fr",
          md: "repeat(3, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
          "2xl": "repeat(5, 1fr)",
        }}
        style={{  justifyContent: "center", alignItems: "center" }}
      >
      
        {isLoading ? (
          <>
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
            <RoomSkeleton />
          </>
        ) : null}
  
        {filteredPartners?.map((partner) => (
          <Partner
            key={partner.pk}
            pk={partner.pk}
            isOwner={partner.is_owner}
            imageUrl={partner.photos?.[0]?.file}
            company={partner.company}
            type={partner.type}
            country={partner.country}
            category={partner.category}
            order={partner.order}
           
          />
        ))}
      </Grid>
  
      </Box>
    );
  }
  