import { Box, Heading, Text } from '@chakra-ui/react';

export default function Privacy() {
  return (
    <Box  py={10} px={6}>
 
      <Heading textAlign="center" as="h2" size="xl" mt={6} mb={2}>
      PRIVACY (Privacy Policy)  
      </Heading>
      <Text text-align="justify" color={'gray.500'}>
      <br />
      <br />
      <br />
      <br />
PVMLKOREA (hereinafter referred to as the 'Company' takes care of the personal information of our customers and complies with the Law related to the ‘Stimulation of Use of Information and Communication Network & Information Protection”. The Company notifies our customers how the personal information provided by them in terms of the purpose and the way of use, and which measures are taken for the protection of the personal information through our Privacy Policy. In the event of any modification on the Privacy Policy, the Company will announce it through the notice on our Website (or individual notification).
<br />
ο This Policy shall be implemented from 18, 01, 2021.
<br />
<br />
<br />
■ Personal Information To Be Collected
<br />
The Company collects the following personal information for membership, consultation, application for service, et cetera.
<br />
ο Items: Name, Date of Birth, Log-in ID, Password, Q&A for Password, Home Number, Home Address, Mobile Phone Number, Email, Credit Card Information, Bank Account Information, Service Use History, Access Log, Cookie, Access IP Information, Payment Record
<br />
ο Method of Collection of Personal Information: Website (Membership Subscription)
<br />
<br />
<br />
■ Purpose of Collection & Use of Personal Information 
<br />
 The Company makes use of the personal information collected for the following purposes.
 <br />
ο Implementation of contract for the provision of services and provision of contents for fees calculation in accordance with the provision of services, purchase and payment, goods delivery or sending to destinations, identification for financial transactions and other financial services, collection of fees 
<br />
ο Identification in accordance with the use of membership services, personal identification, prevention of abuse of bad users and unauthorized use, confirmation of intent of membership subscription, age confirmation, complaints management, notification
<br />
<br />
<br />
■ Period of Possession & Use of Personal Information 
<br />
 As a rule, the personal information collected shall be destroyed without any delay once the purpose of its use is  achieved. Notwithstanding the foregoing, the following information shall be preserved for the period with the cause mentioned below.
 <br />
 <br />
■ Process of personal information destruction and method 
<br />
 As a rule, the personal information collected shall be destroyed without any delay once the purpose of its use is achieved. Process and the method is mentioned below. 
 <br />
ο Process of destruction
<br />
- The information that you used when you sign up will be moved to specific DB after the achievement, and it will be destroyed in specific period by related law and information protection reason. (refer the possession and period of use)
<br />
ο Way of destruction
<br />
- We destroy with technical way that we can't use again the personal information that saved electronic way. 
<br />
<br />
<br />
■  Providing personal information
<br />
The company will not provide personal information to outsider by principle. But these cases are exception.
<br />
- When the users agreed in advance.
<br />
- When it's by law or investigation purpose that investigation agency requires by fixed process and way. 
<br />
<br />
<br />
■ Consignment of personal information that collected
<br />
 The company will not consign your personal information to other companies without your permission. In future if we need, we will notice to consignment company or person and you, and take prior agreement.
 <br />
 <br />
 <br />
■ User and legal representative's rights and way to use.
<br />
 User and legal representative can always check and correct yours and under 14 aged children's personal information and also request for withdrawal.
 <br />
 To check and correct for user and under 14 aged children's personal information, and for personal information change(member information change) and withdrawal, please click "member withdrawal", pass identification process, and then can check, correct or withdrawal. Or if you contact to personal information administrator with document, phone, or e-mail, we will make action without delay. 
 <br />
 If you requested personal information error correction, we will not use and provide personal information until we correct it. Also when we provide wrong personal information to third party, we will notice to the third party without delay and correct it. 
 <br />
 Destroyed personal information that requested by user or legal representative, can't be seen, use and take care by "personal information possession and using period that collecting by OO"
 <br />
 <br />
 <br />
■ Automatic collecting of personal information's installation, manage and refusal.
<br />
 Our company manage 'cookie' that save frequently and search your information. Cookie is a tiny text file that website's server send to your browser, and it will be saved in your computer's hard disk. The company will use cookie for these purposes. 
 <br />
▶ Cookie and et cetera's using purpose
<br />
- To provide personal service and target marketing by analyzing member and visitor's access frequency and visiting hours, trace users' type and interest, and  participating at various events.
<br />
 You have all rights to install cookie. As a result, you can allow all cookie by establishing option on web browser, check every time when cookie will be saved, or reject all cookie saving. 
 <br />
▶ How to reject cookie set-up.
<br />
 For example: When you want to reject cookie set-up, you can choose all web browser's options so agree to all cookie, check every time you save, or you can reject all cookie saving. 
 <br />
:Upper part of web browser - internet option - personal information
<br />
But, when you reject cookie installation, it might be hard to provide service. 
<br />
<br />
<br />
■ Civil service about personal information
<br />
 The company designated related team and personal information administrator to protect personal information and take care of complains about personal information. 
 <br />
 <br />
 <br />
Personal information Administrator: PVML CS Team
<br />
E-mail: customerservice@pvml.kr
<br />
<br />
<br />
 You can report all civil complaint about personal information to personal information administrator or related team. Company will make answer quickly and enough about complaints.
 <br />
If you want to report or need counsel, please contact these agency below.
<br />
1. Supreme Prosecutors' Office cyber investigation team (cybercid.spo.go.kr) 
<br />
2. National Police Agency's cyber terror response center (cybercid.spo.go.kr)
      </Text>
    </Box>
  );
}