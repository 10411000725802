import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import LoginModal from "./LoginModal";

interface IProtectedPageProps {
  children: React.ReactNode;
}

export default function ProtectedPage({ children }: IProtectedPageProps) {
  const { isLoggedIn, userLoading } = useUser();
  const navigate = useNavigate();
  const cancelRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();

  useEffect(() => {
    if (!userLoading) {
      if (!isLoggedIn) {
    
      }
    }
  }, [userLoading, isLoggedIn, navigate]);

  if (!isLoggedIn) {
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsOpen(false)}
        >
          <AlertDialogOverlay>
          <AlertDialogContent
              bg={'gray.50'}
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="lg"
            >
              <AlertDialogHeader 
              fontSize="lg" 
              fontWeight="bold"
              color={'purple.500'}
              >
                Login Required
              </AlertDialogHeader>

              <AlertDialogBody>
              <Text color={'gray.600'} fontSize={'md'}>
                To access this feature, you need to log in to your account. 
                Please log in to continue.
              </Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                
                <Button 
                bg={"#6B4EFF"}
                color={"white"} 
                onClick={onLoginOpen}
                ml={3}
                >
                  Login
                </Button>
                <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }

  return <>{children}</>;
}