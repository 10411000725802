import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { FlexProps } from "@chakra-ui/layout";

function HomeVideo() {
  const direction = useBreakpointValue<"row" | "column">({ base: "column", md: "row" });
  const justifyContent = useBreakpointValue<FlexProps["justify"]>({ base: "center", md: "space-between" });
  const alignItems = useBreakpointValue<FlexProps["align"]>({ base: "center", md: "center" });

  return (
    <Flex 
    direction={direction} 
    justify={justifyContent} 
    align={alignItems} 
    p={10} gap={3}
    maxW="1200px"
    mx="auto" 
    >

      {/* 비디오 섹션 */}
      <Box
        flex="1"
        maxW="md"
        mx="auto"
        p={5}
        borderRadius="lg"
        boxShadow="xl"
        overflow="hidden"
        position="relative"
        transition="transform 0.3s ease-in-out"
        _hover={{ transform: "scale(1.05)" }}
      >
        <video width="100%" height="240px" controls autoPlay>
          <source src="/videos/HYPERCOUTURE.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Text
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          bgColor="rgba(0, 0, 0, 0.7)"
          color="white"
          textAlign="center"
          padding="0.5rem"
          fontSize="sm"
        >
          HYPER COUTURE
        </Text>
      </Box>

      {/* 텍스트 섹션 */}
      <Box 
      flex="1" 
      maxW={{ base: "100%", md: "md" }} 
      mt={{ base: "1rem", md: "0rem" }}
      p={5}
      >
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          bgGradient='linear(to-l, #5538EE, #1E90FF)'
            bgClip='text'
          textAlign={{ base: "center", md: "center" }}
          fontFamily="Raleway"
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
          lineHeight="1.5"
          fontWeight="bold"
          transition="color 0.3s ease-in-out"
          _hover={{ color: "teal.700" }}
        >
          24 S/S HYPER COUTURE NewYork Collection!
        </Text>
        <Text
          fontSize={{ base: "md", md: "lg" }}
          textAlign={{ base: "center", md: "center" }}
          fontFamily="Roboto"
          transform="skew(-10deg)"
          mt="1rem"
        >
          Designer Satoshi Shinada’s collection leaned into the free-spirited eclecticism of streetwear, 
          mixing fabrics to create hybrid pieces like a jersey-denim sweatshirt. 
        </Text>
      </Box>
    </Flex>
  );
}

export default HomeVideo;
