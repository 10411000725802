import {
  Heading,
  Box,
  Grid,
  useDisclosure,
  Link,
  Button,
  VStack,
  useColorModeValue,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBuyers } from "../../api";
import { IBuyers } from "../../types";
import Buyer from "../../components/buyer/Buyer";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import useUserProfile from "../../lib/useUserProfile";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function BuyerHome() {
  const { profile } = useUserProfile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, data: buyers } = useQuery<IBuyers[]>(
    ["buyer"],
    getBuyers
  );
  const [searchValue, setSearchValue] = useState("");

  const filteredBuyers =
    buyers &&
    buyers.filter(
      (buyer) =>
        buyer.company.toLowerCase().includes(searchValue.toLowerCase()) ||
        buyer.country.toLowerCase().includes(searchValue.toLowerCase()) ||
        buyer.offering.toLowerCase().includes(searchValue.toLowerCase()) ||
        buyer.city.toLowerCase().includes(searchValue.toLowerCase())
    );

  return (
    <Box>
      <HelmetProvider>
    <Helmet>
      <title>BUYING</title>
  </Helmet>
</HelmetProvider>

      <VStack spacing={5}>
     
        <Heading
          mt={10}
          fontSize={"2xl"}
          fontFamily={"body"}
          textAlign={"center"}
        >
          BUYING OFFER
        </Heading>

        <Box p={1}>
          <HStack>
          {profile?.buyer ? (
          <Link
            as={RouterLink}
            to={`/buyer/home/upload`}
            style={{ textDecoration: "none" }}
          >
            <Button
              size={"sm"}
              flex={1}
              fontSize={"sm"}
              rounded={"full"}
              bg={"#5538EE"}
              color={"white"}
              boxShadow={
                "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
              }
              _hover={{
                bg: "#5538EE",
                transform: "translateY(2px)",
                boxShadow: "lg",
              }}
              _focus={{
                bg: "#5538EE",
              }}
            >
              Upload
            </Button>
          </Link>
          ) : null}

          {profile?.buyer ? (
          <Link
            as={RouterLink}
            to={`/buyer/home/buyer/manager`}
            style={{ textDecoration: "none" }}
          >
            <Button
              size={"sm"}
              flex={1}
              fontSize={"sm"}
              rounded={"full"}
              bg={"#5538EE"}
              color={"white"}
              boxShadow={
                "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
              }
              _hover={{
                bg: "#5538EE",
                transform: "translateY(2px)",
                boxShadow: "lg",
              }}
              _focus={{
                bg: "#5538EE",
              }}
            >
              My Offers
            </Button>
          </Link>
          ) : null}

          {profile?.brand ? (
          <Link
            as={RouterLink}
            to={`/buyer/home/brand/manager`}
            style={{ textDecoration: "none" }}
          >
            <Button
              size={"sm"}
              flex={1}
              fontSize={"sm"}
              rounded={"full"}
              bg={"#5538EE"}
              color={"white"}
              boxShadow={
                "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
              }
              _hover={{
                bg: "#5538EE",
                transform: "translateY(2px)",
                boxShadow: "lg",
              }}
              _focus={{
                bg: "#5538EE",
              }}
            >
              My Matchings
            </Button>
          </Link>
          ) : null}
          </HStack>
        </Box>

        <Box w="50%">
          <InputGroup size="md">
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={FaSearch} color="gray.300" />}
            />
            <Input
              type="text"
              placeholder="Search buyers..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </InputGroup>
        </Box>
      </VStack>

      <Grid
        mt={10}
        px={{
          base: 5,
          lg: 40,
        }}
        columnGap={4}
        rowGap={8}
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
        }}
      >
        {isLoading ? (
          <p>Loading buyers...</p>
        ) : (
          filteredBuyers.map((buyer) => (
            <Buyer
              key={buyer.pk}
              pk={buyer.pk}
              company={buyer.company}
              country={buyer.country}
              city={buyer.city}
              created_at={buyer.created_at}
              offering={buyer.offering}
            />
          ))
        )}

      </Grid>
    </Box>
  );
}
