import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputLeftAddon,
  InputGroup,
  Select,
  Text,
  useToast,
  VStack,
  HStack,
  Textarea,
  ButtonGroup,
  Stack,
  Center,
  Avatar,
  AvatarBadge,
  IconButton,
} from "@chakra-ui/react";
import { SmallCloseIcon } from '@chakra-ui/icons';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  editProfile,
  IEditProprofileVariables,
  getMyProfile,
} from "../api";
import useHostOnlyPage from "../components/HostOnlyPage";
import ProtectedPage from "../components/ProtectedPage";
import { IProprofile } from "../types";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useUserProfile from "../lib/useUserProfile";
import useUser from "../lib/useUser";
import { ReactNode, useEffect, useState, useRef } from "react";
import StyledButton from "../components/market/StyledButton";

export default function EditMyprofile() {

  const [step, setStep] = useState(1);

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const disabledBtnStyle = {
    backgroundColor: "#DFE1E1",
    cursor: "not-allowed",
  };

  interface IBaseProps {
    children: ReactNode;
  }

  const { user } = useUser();
  const { profile } = useUserProfile();
  const { 
    register, 
    handleSubmit,
    getValues,
    formState: { errors },
   } = useForm<IEditProprofileVariables>();

  const toast = useToast();
  const navigate = useNavigate();

  const mutation = useMutation(editProfile, {
      onSuccess: (data: IProprofile) => {
          toast({
              status: "success",
              title: "Profile Edited",
              description: "Your profile has been updated.",
              position: "bottom-right",
          });
          navigate(`/`);
        },
      onError: () => {
        toast({
            status: "error",
            title: "Error!",
            description: "This email is already in use.",
            position: "bottom-right",
        });
    },
  });
  const onSubmit = ({
    avatar,
    is_host,
    user,
    first_name,
    last_name,
    company,
    email,
    gender,
    date_of_birth,
    brand,
    buyer,
    multishop,
    showroom,
    department,
    website,
    SNS,
    description
  }: IEditProprofileVariables) => {
    mutation.mutate({
    avatar,
    is_host,
    user,
    first_name,
    last_name,
    company,
    email,
    gender,
    date_of_birth,
    brand,
    buyer,
    multishop,
    showroom,
    department,
    website,
    SNS,
    description
    });
  };

  const Base = ({ children }: IBaseProps) => (
    <ProtectedPage>
 <VStack pb={20} mt={10} px={{ base: 10, lg: 40}}>
      <VStack p={10} pt={12} spacing={3}>
          {children}
          </VStack>
        </VStack>
    </ProtectedPage>
  );
      
  const Step1 = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
      <>

              <HelmetProvider>
              <Helmet>
                <title>Profile Edit</title>
              </Helmet>
              </HelmetProvider>
              <Box pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>Edit Profile</Heading>
              </Box>

          {/*유저네임 폼*/}
          <FormControl>
              <FormLabel>Username</FormLabel>
            <Input
              defaultValue={user?.username}
              type="text"
              variant={"filled"}
              readOnly
            />
              <FormHelperText>Write the name of your Brand.</FormHelperText>
            </FormControl> 

            <ButtonGroup
            gap="4"
            w={"100%"}
            display={"grid"}
            gridTemplateColumns={"1fr 1fr"}
          >
            <Button
              mt={5}
              borderRadius={150}
              bg="#6B4EFF"
              color={"white"}
              isDisabled
              _disabled={disabledBtnStyle}
            >
              &larr; Previous
            </Button>
            <Button
              mt={5}
              borderRadius={150}
              bg="#6B4EFF"
              color={"white"}
              onClick={() => {
       
                  nextStep();
              }}
            >
              Next &rarr;
            </Button>
          </ButtonGroup>
      
        </>
    );
};

const Step2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

return (
    <>
            <Box pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>Edit Profile</Heading>
            </Box>

          {/*이메일 폼*/}
          <FormControl isRequired>
              <FormLabel>Email</FormLabel>
            <Input
              defaultValue={profile?.email}
              {...register("email", { required: false })}
              required
              type="text"
            />
            <FormHelperText>
            Please check if the email address is correct.
            </FormHelperText>
            </FormControl>

          {/*이름 폼*/}
          <FormControl isRequired>
              <FormLabel>FirstName</FormLabel>
            <Input
              defaultValue={profile?.first_name}
              {...register("first_name", { required: false })}
              required
              type="text"
            />
              <FormHelperText>Write the name of your First Name.</FormHelperText>
            </FormControl>

          {/*성 폼*/}
          <FormControl isRequired>
              <FormLabel>LastName</FormLabel>
            <Input
              defaultValue={profile?.last_name}
              {...register("last_name", { required: false })}
              required
              type="text"
            />
              <FormHelperText>Write the name of your Last Name.</FormHelperText>
            </FormControl>

          {/*성병 폼*/}
          <FormControl isRequired>
              <FormLabel>Gender</FormLabel>
            <Select
              defaultValue={profile?.gender}
              {...register("gender", { required: false })}
              placeholder="Choose a gender"
            >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Select>
              <FormHelperText>
              What is your gender?
              </FormHelperText>
            </FormControl>

          {/*생일 폼*/}
          <FormControl isRequired>
              <FormLabel>Birthday</FormLabel>
              <Input
                type="date"
                defaultValue={profile?.date_of_birth}
                {...register("date_of_birth", {
                  required: "When is your birthday?",
                })}
              />
              <FormHelperText>
              When is your birthday?
              </FormHelperText>
            </FormControl>              

            <ButtonGroup
					gap="4"
					w={"100%"}
					display={"grid"}
					gridTemplateColumns={"1fr 1fr"}
				>
					<Button
            mt={5}
						borderRadius={150}
						bg="#6B4EFF"
						color={"white"}
						onClick={prevStep}
					>
						&larr; Previous
					</Button>
					<Button
            mt={5}
						borderRadius={150}
						bg="#6B4EFF"
						color={"white"}
						onClick={() => {
              if (
								!getValues([
									"email",
									"first_name",
									"last_name",
                  "gender",
                  "date_of_birth",
								]).includes("")
							)
								nextStep();
						}}
					>
						Next &rarr;
					</Button>
				</ButtonGroup>
			</>
		);
	};

const Step3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    
        <Box pb={5}  px={{ base: 10, lg: 40 }}>
              <Heading textAlign={"center"}>Edit Profile</Heading>
              </Box>

          {/*회사브랜드 폼*/}
          <FormControl isRequired>
              <FormLabel>Company/Brand</FormLabel>
            <Input
              defaultValue={profile?.company}
              {...register("company", { 
                required: true,
              })}
              required
              type="text"
            />
              <FormHelperText>
              Company or Brand name is required.
              </FormHelperText>
            </FormControl>              

            {/*웹사이트 폼*/}
            <FormControl isRequired>
              <FormLabel>Website</FormLabel>
            <InputGroup size='sm'>
            <InputLeftAddon children='https://' />
            <Input
              defaultValue={profile?.website}
              {...register("website", { required: true })}
              required
              type="text"
            />
            </InputGroup>
              <FormHelperText>
              Please write down your homepage address.
              </FormHelperText>
            </FormControl>

            {/*SNS 폼*/}
            <FormControl>
              <FormLabel>SNS(INSTAGRAM)</FormLabel>
            <Input
              defaultValue={profile?.SNS}
              {...register("SNS", { required: false })}
              required
              type="text"
            />
              <FormHelperText>
              Please write down your SNS account or address.
              </FormHelperText>
            </FormControl>

           {/*스토어 폼*/}
           <FormControl isRequired>
           <FormLabel>Store Type</FormLabel>
           <Box p={5} borderWidth="1px" rounded="md">
            <HStack spacing={5}>
           <Checkbox 
            defaultChecked={profile?.brand}
            {...register("brand", { required: false })}>
              Brand
            </Checkbox>

            <Checkbox 
            defaultChecked={profile?.buyer}
            {...register("buyer", { required: false })}>
              Buyer
            </Checkbox>

            <Checkbox 
            defaultChecked={profile?.multishop}
            {...register("multishop", { required: false })}>
              Multishop
            </Checkbox>
            </HStack>
            <br/>
            <HStack spacing={5}>
            <Checkbox 
            defaultChecked={profile?.showroom}
            {...register("showroom", { required: false })}>
              Showroom
            </Checkbox>

            <Checkbox 
            defaultChecked={profile?.department}
            {...register("department", { required: false })}>
              Department Store
            </Checkbox>
            </HStack>
            </Box>
            <FormHelperText>
            Please check the appropriate box.
            </FormHelperText>
            </FormControl>

            {/*설명 폼*/}
            <FormControl>
              <FormLabel>Description</FormLabel>
            <Textarea 
            defaultValue={profile?.description}
            {...register("description", { required: false })} />
            </FormControl>

            <ButtonGroup
              gap="4"
              w={"100%"}
              display={"grid"}
              gridTemplateColumns={"1fr 1fr"}
            >
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                onClick={prevStep}
              >
                &larr; Previous
              </Button>
              <Button
                mt={5}
                borderRadius={150}
                bg="#6B4EFF"
                color={"white"}
                isDisabled
                _disabled={disabledBtnStyle}
              >
                Next &rarr;
              </Button>
            </ButtonGroup>
            <VStack py={6}>
            {mutation.isError ? (
                              <Text color={"red.500"}>Wrong</Text>
                          ) : null}
              <StyledButton
              btnName={"Edit"}
              hasArrow={true}
              onClick={handleSubmit(onSubmit)}
              isLoading={mutation.isLoading}
            />
          </VStack>
    </>
  );
};              
switch (step) {
  case 1:
    return (
      <Base>
        <Step1 />
      </Base>
    );
  case 2:
    return (
      <Base>
        <Step2 />
      </Base>
    );
  case 3:
    return (
      <Base>
        <Step3 />
      </Base>
    );
  default:
    return null;
}
};