import { 
  Grid, 
  Box,
  Text, 
  Center,
  Heading,
  Button,
  useDisclosure,
  Link,
  Image,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  VStack,
  Flex,
  keyframes,
  Container,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getRooms } from "../api";
import Room from "../components/Room";
import RoomSkeleton from "../components/RoomSkeleton";
import { IRoomList } from "../types";
import SignUpModal from "../components/SignUpModal";
import { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HomeSlider from "../components/slider/HomeSlider";
import HomeVideo from "../components/slider/HomeVideo";
import HomeClient from "../components/slider/HomeClient";
import useUser from "../lib/useUser";
import MyBookings from "./MyBookings"
import ManageBookings from "./ManageBookings"
import useUserProfile from "../lib/useUserProfile";

export default function Home() {
  useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  const { profile } = useUserProfile();
  const { userLoading, isLoggedIn, user } = useUser();
  const { isLoading, data: getrooms } = useQuery<IRoomList[]>(["rooms"], getRooms);
  const [searchValue, setSearchValue] = useState("");

  const filteredRooms =
  getrooms &&
  getrooms.filter(
      (room) =>
      room.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      room.year.toLowerCase().includes(searchValue.toLowerCase()) ||
      room.season.toLowerCase().includes(searchValue.toLowerCase())
    );
  
  const {
    isOpen: isSignUpOpen,
    onClose: onSignUpClose,
    onOpen: onSignUpOpen,
  } = useDisclosure();

  {/*텍스트 롤링배너 */}
  const textList = [
    'IEY',
    'WILLIWAWSTUDIO',
    'FREAKS',
    'BORNETE',
    'PIEMUU',
    'VLACK VEAST',
  ];
  
  
  const marquee = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }

  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

  
const [currentTextIndex, setCurrentTextIndex] = useState(0);

useEffect(() => {
  const interval = setInterval(() => {
    setCurrentTextIndex((currentTextIndex + 1) % textList.length);
  }, 3000);

  return () => clearInterval(interval);
}, [currentTextIndex]);


  return (
    
<Box>
<HelmetProvider>
    <Helmet>
      <title>PASHOP</title>
  </Helmet>
</HelmetProvider>

<Center 

  as='b' 
  bg='black' 
  h={{ base: '80px', md: '80px', lg: '80px' }} 
  color='white'
  fontSize={{ base: 'sm', md: 'lg', lg: 'lg' }}
  px={{ base: 2, md: 4, lg: 8 }}
  fontFamily= 'Patricia' 
  textAlign={"center"}
>
  Shop around 30+ Global top designer brands in PASHOP. 
  <br />2024 new collection forward-order.
</Center>


{!isLoggedIn ? (
    <>

    <Box 
      p={10} 
      maxW='32rem'
      mt={5}

    >
  <Heading 
  mb={4}
  >
  The World's Premier Wholesale Management Platform</Heading>
  <Text 
  fontSize='xl'
  fontFamily= 'Patricia' 
  >
  Global reach, seamless order management
  <br />The future of digital wholesale is PASHOP.
  </Text>
  
  <Button 
  onClick={onSignUpOpen} 
  size='lg' 
  colorScheme='purple' 
  mt='24px'
  flex={1}
  fontSize={'sm'}
  rounded={'full'}
  bg={'#5538EE'}
  color={'white'}
  boxShadow={
      '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
    }
    _hover={{
      bg: '#5538EE',
      transform: 'translateY(2px)',
      boxShadow: 'lg',
    }}
    _focus={{
      bg: '#5538EE',
    }}
  
  >
    Create a free account
  </Button>
  <SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} />
</Box>
</>
          ) : (
            <>
            {(profile?.buyer || profile?.showroom || profile?.multishop || profile?.department) && 
            <Box
            overflowY="scroll"
            maxHeight="50vh"
            css={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'gray.200',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'red.500',
              },
            }}
            >
              <MyBookings />
            </Box>
            }

          {profile?.brand &&
            <Box
            overflowY="scroll"
            maxHeight="50vh"
            css={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'gray.200',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'red.500',
              },
            }}
            >
              <ManageBookings />
            </Box>
            }
            </>
)}

<Box mt={10}>
  <HomeSlider />
</Box>

<Box mt={-10}>
<HomeClient />
</Box>

<Box mt={100}>
<Text
        textAlign={'center'}
        fontWeight={'bold'}
        fontFamily="Patricia"
        fontSize={{ base: '2xl', md: '4xl' }}
        transform="skew(-10deg)"
      >
        What's New+
      </Text>

      </Box>
      <Box>
      <HomeVideo />
</Box>


<Box
      mt={10}
      whiteSpace="nowrap"
      bg="black"
      h="50px"
      w="100%"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      overflow="hidden"
      position="relative"
    >
      {textList.map((text, index) => (
        <Box
          key={index}
          position="absolute"
          whiteSpace="nowrap"
          left={`${index * 30}%`}
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="White" 
          fontSize="md"
          fontWeight="bold"
          animation={`${marquee} 10s linear infinite`}
          animation-timing-function="linear"
          transitionDelay={`${index}s`}
          marginRight="20px"
        >
          {text}
        </Box>
      ))}
    </Box>

 {/* <Image mt={10} src="iey.png" fallbackSrc='/images/iey.png' /> */}

    <VStack mt={30}>
      <Box w="50%">
          <InputGroup size="md">
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={FaSearch} color="gray.300" />}
            />
            <Input
              type="text"
              placeholder="Search Brands..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </InputGroup>
      </Box>  
      </VStack>
    <Grid
      mt={10}
      px={{
        base: 10,
        lg: 40,
      }}
      columnGap={4}
      rowGap={8}
      templateColumns={{
        base: "1fr",
        sm: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(3, 1fr)",
        xl: "repeat(4, 1fr)",
        "2xl": "repeat(5, 1fr)",
      }}
      style={{ justifyItems: "center", alignItems: "center" }}
    >
    
      {isLoading ? (
        <>
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
          <RoomSkeleton />
        </>
      ) : null}

      {filteredRooms?.map((room) => (
        <Room
          key={room.pk}
          pk={room.pk}
          isOwner={room.is_owner}
          imageUrl={room.photos[0]?.file}
          name={room.name}
          rating={room.rating}
          year={room.year}
          season={room.season}
          nationality={room.nationality}
          order={room.order}
          free={room.free}
          isNew={room.new}
          hot={room.hot}
          best={room.best}
          premium={room.premium}
        />
      ))}
    </Grid>

    </Box>
  );
}
