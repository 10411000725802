import { useForm } from "react-hook-form";
import {
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Text,
  } from "@chakra-ui/react";
  import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
  import { useNavigate, useParams } from "react-router-dom";
  import { 
    IDeletePartnerVariables,
    deletePartners, 
  } from "../../api";
  import { useRef } from "react";


  interface DeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  interface IDeleteForm {
    roomPk: string;
  }
  
  export default function DeletePartner({ isOpen, onClose }: DeleteModalProps) {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const { roomPk } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm<IDeleteForm>();
      console.log(errors);
    const mutation = useMutation(deletePartners, {
      onSuccess: () => {
        toast({ 
          status: "success",
          title: "DELETE",
          description: "Create again Partner",
          position: "bottom-right",
        });
        navigate(`/partners/home`);
        onClose();
        queryClient.refetchQueries(["me"]);

      },
    });

    const onSubmit = (data: IDeletePartnerVariables) => {
      if (roomPk) {
          data["roomPk"] = roomPk;
          mutation.mutate(data);
      }
  };

    return (
        
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody >
            Are you sure you want to delete your Partner?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button 
                isLoading={mutation.isLoading}
                colorScheme="red" 
                ml={3}
                type="submit"
                >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
    )
  }