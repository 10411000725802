import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    Stack,
    VStack,
    HStack,
    Button,
    Link,
    Badge,
    useColorModeValue,
    Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
  } from '@chakra-ui/react';
  import useUserProfile from "../../lib/useUserProfile";
  import { Link as RouterLink } from "react-router-dom";
  import StyledButton from "../../components/market/StyledButton";

  export default function MoimAvatar() {
  const { profile } = useUserProfile();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const BtnModal = () => (
		<Modal onClose={onClose} size={"sm"} isOpen={isOpen} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>내 모임</ModalHeader>
				<ModalCloseButton />
				<ModalBody as={VStack} spacing={3} pb={6}>
					<Link
						as={RouterLink}
						to={"moims/crew"}
						_hover={{ textDecoration: "none" }}
					>
						<StyledButton btnName={"크루로 참여한 모임"} />
					</Link>
					<Link
						as={RouterLink}
						to={"moims/leader"}
						_hover={{ textDecoration: "none" }}
					>
						<StyledButton btnName={"리더로 지원한 모임"} />
					</Link>
					<Link
						as={RouterLink}
						to={"moims/owner"}
						_hover={{ textDecoration: "none" }}
					>
						<StyledButton btnName={"내가 생성한 모임"} />
					</Link>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
    return (
      <Center py={6}>
        <Box
          maxW={'320px'}
          h={"lg"}
          w={'full'}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'lg'}
          p={6}
          textAlign={'center'}>
          <Avatar
            size={'xl'}
            name={profile?.company} 
            src={profile?.avatar} 
            right={2}
            top={6} 
            mb={4}
            pos={'relative'}
            _after={{
              content: '""',
              w: 4,
              h: 4,
              bg: 'green.300',
              border: '2px solid white',
              rounded: 'full',
              pos: 'absolute',
              bottom: 0,
              right: 3,
            }}
          />
          <Heading mt={10} fontSize={'2xl'} fontFamily={'body'}>
          {profile?.first_name} {profile?.last_name} 
          </Heading>
          <Text fontWeight={600} color={'gray.500'} mb={4}>
            @{profile?.company}
          </Text>
          <Text
            textAlign={'center'}
            color={useColorModeValue('gray.700', 'gray.400')}
            px={3}>
            <Text color={"blue.500"}>
            #모임은 
            </Text>
            디자이너 분들에게만 제공되는 
            <br/>국내 서비스입니다.
            <br/>포트폴리오를 등록해주세요.
           
          </Text>
  
          <Stack mt={8} direction={'row'} spacing={4}>
            <Button
            size={"lg"}
            flex={1}
            fontSize={'md'}
            rounded={'full'}
            _focus={{
            bg: 'gray.200',
              }}
            onClick={onOpen}  
              >
              내모임
            </Button>

            <BtnModal />
            
            <Link
					as={RouterLink}
					to={"portfolios"}
					_hover={{ textDecoration: "none" }}
				>
            <Button
            size={"lg"}
            flex={1}
            fontSize={'md'}
            rounded={'full'}
            bg={'#5538EE'}
            color={'white'}
            boxShadow={
                '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
              }
              _hover={{
                bg: '#5538EE',
              }}
              _focus={{
                bg: '#5538EE',
              }}>
              포트폴리오 관리
            </Button>
            </Link>
          </Stack>
        </Box>
      </Center>
    );
  }