import {
    Box,
    Button,
    Divider,
    Heading,
    List,
    ListIcon,
    ListItem,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { FaCheckCircle } from 'react-icons/fa';
  
  const options1 = [
    { id: 1, desc: '신청기업의 타켓군 대상으로 설문조사' },
    { id: 2, desc: '최대 10문항, 최소 10개 회신 결과 제공' },
  ];
  
  const options2 = [
    { id: 1, desc: '신청기업의 타켓군 대상으로 설문조사' },
    { id: 2, desc: '최대 10문항, 최소 10개 회신 결과 제공' },
  ];
  
  const options3 = [
    { id: 1, desc: '신청기업이 희망하는 해외 수입업체와 대리 면담 진행' },
  ];
  
  const options4 = [
    { id: 1, desc: '신청기업이 희망하는 해외 국가로 출장 지원' },
    { id: 2, desc: '해외 바이어와 상담 주선, 교신 지원' },
    { id: 3, desc: '현지 유통망 입점 및 프로젝트 참가 지원' },
    { id: 4, desc: '기업 부담금 100%' },
  ];
  
  interface PackageTierProps {
    title: string;
    options: Array<{ id: number; desc: string }>;
    typePlan: string;
    checked?: boolean;
  }
  const PackageTier = ({
    title,
    options,
    typePlan,
    checked = false,
  }: PackageTierProps) => {
    const colorTextLight = checked ? 'white' : 'purple.600';
    const bgColorLight = checked ? 'purple.400' : 'gray.300';
  
    const colorTextDark = checked ? 'white' : 'purple.500';
    const bgColorDark = checked ? 'purple.400' : 'gray.300';
  
    return (
      <Stack
        
        py={3}
        maxWidth={'100%'}
        justifyContent={{
            base: 'center',
            md: 'space-around',
          }}
        direction={{
          base: 'column',
          md: 'row',
        }}
        wrap="nowrap"
        alignItems={{ md: 'center' }}
        >
        <Heading 
        p={5}
        textAlign={{ base: 'center', md: 'start' }}
        width={{ base: '100%', md: '300px' }}
        size={'md'}
        >
        {title}
        </Heading>
        <List 
        spacing={5} 
        textAlign={{ base: 'center', md: 'start' }}
        width={{ base: '100%', md: '500px' }}
        >
          {options.map((desc, id) => (
            <ListItem key={desc.id} pl={6}>
              <ListIcon as={FaCheckCircle} color="green.500" />
              {desc.desc}
            </ListItem>
          ))}
        </List>
        <Heading 
        p={5}
        size={'lg'} 
        width={{ base: '100%', md: '200px' }}
        >
            {typePlan}
            </Heading>
     
      </Stack>
    );
  };
  const ThreeTierPricingHorizontal = () => {
    return (
      <Box py={6} px={5} m={'100vh'}>
        <Stack spacing={4} width={'100%'} direction={'column'}>
          <Stack
            p={5}
            alignItems={'center'}
            justifyContent={{
              base: 'flex-start',
              md: 'space-around',
            }}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Stack
              width={{
                base: '100%',
                md: '40%',
              }}
              textAlign={'center'}>
              <Heading size={'lg'}>
                해외시장정보조사 <Text color="purple.400">글로벌 대행 서비스</Text>
              </Heading>
            </Stack>
            <Stack
              width={{
                base: '100%',
                md: '60%',
              }}
              >
              <Text textAlign={'start'}
              >
                맞춤형 수출지원을 통해 해외 타겟 시장에 대한 정보, 바이어 파트너 발굴, 바이어 미팅등 
                기업에서 필요한 전문 서비스를 제공합니다. 별도 서비스는 단일 제공 상품입니다. (VAT별도)
              </Text>
            </Stack>
          </Stack>
          <Divider />
          
   
          <PackageTier title={'소비자트렌드설문조사'} typePlan="90만원" options={options1} />
          <Divider />
          <PackageTier
            title={'샘플테스트조사'}
            checked={true}
            typePlan="90만원"
            options={options2}
          />
          <Divider />
          <PackageTier title={'대리면담지원'} typePlan="90만원" options={options3} />
          <Divider />
          <PackageTier title={'해외출장지원'} typePlan="300만원" options={options4} />
          

        </Stack>
      </Box>
    );
  };
  
  export default ThreeTierPricingHorizontal;