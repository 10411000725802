import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Skeleton,
  Text,
  VStack,
  Stack,
  Card, 
  CardHeader, 
  CardBody,
  StackDivider, 
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Spacer,
  Collapse,
  useColorMode,
  useDisclosure,
  Input,
  FormControl,
  Flex,
  InputGroup,
  InputRightAddon,
  useToast,
  Tag,
  Tooltip,
  IconButton, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalCloseButton,
} from "@chakra-ui/react";
import { StarIcon } from '@chakra-ui/icons';
import { FaPencilAlt, FaStar, FaCrown } from "react-icons/fa";
import { MdDelete, MdAddCircleOutline, MdPhotoLibrary } from "react-icons/md";
import { useMutation, useQuery } from "@tanstack/react-query";
import Calendar from "react-calendar";
import "../calendar.css";
import "react-calendar/dist/Calendar.css";
import { 
  checkBooking, 
  createBooking,
  getRoom, 
  getRoomReviews, 
  getAmenities,
  getRoomAmenities,
} from "../api";
import { IAmenity, IReview, IRoomDetail, IWishlist } from "../types";
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useNavigate, useParams } from "react-router-dom";
import useUser from "../lib/useUser";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { useForm } from "react-hook-form";
import ReviewModal from "./UploadReviews";
import DeleteModal from "./DeleteRoom";
import { formatDate } from "../lib/utils";
import useUserProfile from "../lib/useUserProfile";
import ProtectedPage from "../components/ProtectedPage"
import { QuestionOutlineIcon } from '@chakra-ui/icons'
export interface IBooking {
  pk: string;
  check_in: string;
  check_out: string;
  guests: number;
}

export default function RoomDetail() {
  useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  const { register, handleSubmit } = useForm<IBooking>();
  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation(createBooking, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Successfully booked",
        isClosable: true,
      });
      navigate("/");
    },
  });
  const { user } = useUser();
  const { profile } = useUserProfile();
  const { roomPk } = useParams();
  const { isLoading, data } = useQuery<IRoomDetail>([`rooms`, roomPk], getRoom);
  const { data: reviewsData } = useQuery<IReview[]>(
    [`rooms`, roomPk, `reviews`],
    getRoomReviews
  );
  {/*부킹시스템 */}
  const [dates, setDates] = useState<Date[]>();
  const handleDateChange = (value: Date | Date[]) => {
    if (value instanceof Date) {
      setDates([value]);
    } else {
      setDates(value);
    }
  };
  
  const { data: checkBookingData, isLoading: isCheckingBooking } = useQuery(
    ["check", roomPk, dates],
    checkBooking,
    {
      cacheTime: 0,
      enabled: dates !== undefined,
    }
    );
    const onSubmit = (data: IBooking) => {
      if (dates && roomPk) {
        data["pk"] = roomPk;
        data["check_in"] = formatDate(dates[0]);
        data["check_out"] = formatDate(dates[1]);
        mutation.mutate(data);
      }
    };
{/*모두어메니티 */}
const { data: allamenities } = useQuery<IAmenity[]>(["amenities"], getAmenities);
{/*개인어메니티 */}
  const { data: amenities, isLoading: isAmenitiesLoading } = useQuery<
  IAmenity[]
>([`rooms`, roomPk, `amenities`], getRoomAmenities);

{/*Collapse */}
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

{/*Review Modal Click */}
  const {
    isOpen: isReviewOpen,
    onClose: onReviewClose,
    onOpen: onReviewOpen,
  } = useDisclosure();

{/*Delete Click */}
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

{/*Edit room Click */}
  const onEditClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/rooms/${roomPk}/edit`);
};
{/*Photos room Click */}
  const onPhotoRoomClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/rooms/${roomPk}/photoroom`);
};
{/*Upload Photo Click */}
  const onCameraClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/rooms/${roomPk}/photos`);
};

const addSchemaToUrl = (url: string | undefined) => {
  if (url && !url.startsWith('http')) {
    return `http://${url}`;
  }
  return url;
};

{/*premium company modal */}
const { 
  isOpen: premiumIsOpen, 
  onOpen: premiumOnOpen, 
  onClose: premiumOnClose 
} = useDisclosure();

  return (
    <ProtectedPage>
    <Box
      pb={40}
      mt={10}
      px={{
        base: 10,
        "2xl": 80,
      }}
      maxW={{ base: "100%", md: "auto" }}
    >
      {/*웹 상단 탭*/}
      <HelmetProvider>
      <Helmet>
        <title>{data ? data.name : "Loading..."}</title>
      </Helmet>
      </HelmetProvider>
      {/*제목명*/}
      <VStack align="flex-start"> 
      <HStack spacing="25px" flexWrap="wrap" minHeight="100px">
      <Skeleton 
      height={"43px"}
      isLoaded={!isLoading}
      >
      <Heading>
        {data?.name}
      </Heading>
      </Skeleton>
      </HStack>

      <HStack mt={5} spacing="10px">
      {/*카메라버튼*/}
      {data?.is_owner ? (
                    <Button onClick={onCameraClick}>
                    <MdPhotoLibrary />
                    </Button>
                ) : null}

      {/*수정버튼*/}
      {data?.is_owner ? (
                    <Button onClick={onEditClick}>
                        <FaPencilAlt />
                    </Button>
                ) : null}
      {/*삭제버튼*/}
      {data?.is_owner ? (
                    <Button onClick={onDeleteOpen}>
                    <MdDelete />
                    <DeleteModal 
                    isOpen={isDeleteOpen} 
                    onClose={onDeleteClose} 
                    />
                    </Button>
                ) : null}
      </HStack>
      </VStack>

      {/*사진 그리드 시작*/}
      <Grid
        mt={8}
        rounded="xl"
        overflow={"hidden"}
        gap={2}
        height="100%"
        templateRows={{
          base: "1fr 1fr", // 기본 화면 크기
          sm: "1fr 1fr", // 작은 화면에서 2개의 행
          md: "1fr 1fr", // 중간 화면에서 1개의 행
        }}
        templateColumns={{
          base: "repeat(1fr)", // 기본 화면 크기
          sm: "repeat(1fr)", // 작은 화면에서 2개의 열
          md: "repeat(4, 1fr)", // 중간 화면에서 4개의 열
        }}
      >
        <PhotoProvider speed={() => 800}>
        {data?.photos.slice(0,4).map((photo, index) => (
          <GridItem
            colSpan={index === 0 ? 2 : 1}
            rowSpan={index === 0 ? 2 : 1}
            overflow={"hidden"}
            key={photo.pk}
          >
            <Skeleton isLoaded={!isLoading} h="100%" w="100%">
            {data?.photos && data.photos.length > 0 ? (
      <PhotoView src={photo.file}>
                <Image
                  objectFit={"cover"}
                  w="100%"
                  h="100%"
                  src={photo.file}
                  alt=""
                />
      </PhotoView>
              ) : null}
            </Skeleton>
          </GridItem>
        ))}
        </PhotoProvider>
      <Button 
        onClick={onPhotoRoomClick}
        height="100%"
      >
      <Text>More</Text>
      <MdAddCircleOutline />
      </Button>
    </Grid>

      {/*사진 아래 호스트 명*/}
  
      <Grid
      mt={10}
      px={{
        base: 10,
        lg: 10,
        "2xl": 30,
      }}
  
      gap={30}
      templateColumns={{
        base: "1fr",
        sm: "1fr",
        md: "2fr 1fr",
        lg: "3fr 1fr",
        xl: "4fr 1fr",
        "2xl": "5fr 1fr",
      }}
      justifyContent="center"
    >
        <Box 
        w='100%'
        px={{ base: 5 }}
        >
          <Box p="3">
          <HStack justifyContent={"flex-start"}>
            <VStack alignItems={"flex-start"}>
            
            {data?.premium ? (
            <Text
            bgGradient='linear(to-l, #7928CA, #FF0080)'
            bgClip='text'
            fontSize='6xl'
            fontWeight='extrabold'
              >
              Hello
          </Text>
            ) :
            <Text
            bgGradient="linear(to-l, #000000, #000000)"
            bgClip="text"
            fontSize='6xl'
            fontWeight='bold'
            >
              Hello
          </Text>
            }
              <Skeleton isLoaded={!isLoading} height={"30px"}>
              {data?.premium ? (
                <Heading
                bgGradient='linear(to-l, #7928CA, #FF0080)'
                bgClip='text'
                fontWeight='extrabold'       
                fontSize="2xl"
                textTransform='uppercase'
                >
                @{data?.name}
                </Heading>
              ) :
              <Heading
                bgGradient='linear(to-l, #000000, #000000)'
                bgClip='text'
                fontWeight='bold'       
                fontSize="2xl"
                textTransform='uppercase'
                >
                @{data?.name}
                </Heading>
            }
              </Skeleton>

              <Skeleton isLoaded={!isLoading} height={"20px"}>
              {data?.premium ? (
              
                <HStack mt={"20%"}>
                <Flex direction='row' align='center'>
                <Text fontSize='sm' mr={1}>
                  👑
                </Text>
                <Heading
                bgGradient='linear(to-l, #DC143C, #800000)'
                bgClip='text'
                fontWeight='bold'       
                fontSize="lg"
                >
                premium
                </Heading>
                </Flex>
                <IconButton
                aria-label="Info"
                icon={<QuestionOutlineIcon />}
                onClick={premiumOnOpen}
                size="sm"
                bg="transparent"
                boxSize={4}
              />  
                </HStack>
                
              ) : null 
              }
               <Modal isOpen={premiumIsOpen} onClose={premiumOnClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Information</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    Premium Company are those certified by PASHOP after 
                    reviewing their company size, overseas export experience, 
                    social responsibility, sustainability, and product quality 
                    and safety. The certification mark provides buyers 
                    with reliability and supports overseas expansion.
                  </ModalBody>
                </ModalContent>
              </Modal>
              </Skeleton>

              <Skeleton isLoaded={!isLoading} height={"30px"}>
                <HStack justifyContent={"flex-start"} mt={"20%"}>
                <Tag
                  borderRadius={100}
                  fontSize={"xs"}
                  bg={"#6B4EFF"}
                  color={"white"}
                  textTransform='uppercase'
                >
                    #{data?.year} 
                  </Tag>
                  <Text>∙</Text>
                  <Tag
                    borderRadius={100}
                    fontSize={"xs"}
                    textTransform='uppercase'
                  >
                  #{data?.season} 
                  </Tag>
                </HStack >
                </Skeleton>
                
                <Skeleton isLoaded={!isLoading} height={"10px"}>
                <HStack justifyContent={"flex-start"} mt={"20%"}>
                <Tag
                  borderRadius={100}
                  fontSize={"xs"}
                  bg={"#6B4EFF"}
                  color={"white"}
                  textTransform='uppercase'
                >
                  #{data?.kind} 
                </Tag>
                  <Text>∙</Text>
                  <Tag
                    borderRadius={100}
                    fontSize={"xs"}
                    textTransform='uppercase'
                  >
                    {data?.nationality} 
                  </Tag>
                  </HStack >
              </Skeleton>
            </VStack>
            <Spacer />
            <Avatar 
              mt={{ base: "7", md: "3"}}
              name={data?.owner.username}
              size={{ base: "md", md: "lg"}}
              src={data?.owner.avatar}
            />
          </HStack>
          </Box>
    </Box>
<VStack />

{/*설명 부분 */}
<Box px={{ base: 5 }}>
<Box 
  mt={"10%"}
  borderWidth="1px" 
  borderRadius="lg" 
  overflow="hidden"
  >
  <Tabs size={{ base: "sm", md: "md" }} isFitted variant="enclosed">
    <TabList>
      <Tab as="i">Company</Tab>
      <Tab as="i">Info</Tab>
      <Tab as="i">Review</Tab>
    </TabList>

    <TabPanels>
    <TabPanel>
      <Card mt={"4"}>
  <CardHeader>
    <Heading size='md'>Brand Report</Heading>
  </CardHeader>
  <CardBody>
    <Stack divider={<StackDivider />} spacing='4'>
    <Box>
        <Heading size='xs' textTransform='uppercase'>
          Brand
        </Heading>
        <Text pt='2' fontSize='sm'>
        {data?.name}
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
        Category
        </Heading>
        <Text pt='2' fontSize='sm'>
        {data?.category.name}
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Biz. Type
        </Heading>
        <Text pt='2' fontSize='sm' textTransform='uppercase'>
        {data?.kind}
        </Text>
      </Box>
      <Box>
      <Heading size='xs' textTransform='uppercase'>
    Homepage
  </Heading>
  <Text pt='2' fontSize='sm'>
    <span
      onClick={() => window.open(addSchemaToUrl(data?.homepage), '_blank')}
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
    >
      {data?.homepage}
    </span>
  </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Email
        </Heading>
        <Text pt='2' fontSize='sm'>
        {profile?.email}
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Address
        </Heading>
        {profile?.is_host ? (
        <Text pt='2' fontSize='sm'>
        {data?.address}
        </Text>
        ) : "*************************"}
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Contact Person
        </Heading>
        {profile?.is_host ? (
        <Text pt='2' fontSize='sm' textTransform='uppercase'>
        {data?.person}
        </Text>
        ) : "*********"}
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
        Country of Manufacture
        </Heading>
        <Text pt='2' fontSize='sm' textTransform='uppercase'>
        {data?.country}
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
        Production Capacity
        </Heading>
        <Text pt='2' fontSize='sm'>
        {data?.min_capacity}~{data?.max_capacity}
        <Tag 
          fontSize={"xs"}  
          borderRadius={100}
          textTransform='uppercase'
        >
           pcs
        </Tag>
        </Text>
      </Box>
    </Stack>
  </CardBody>
</Card>
</TabPanel>

{/*가격 페이먼트 배송 부분 */}
<TabPanel>
<Card mt={"4"}>
  <CardHeader>
    <Heading size='md'>Price/Payment/Delivery</Heading>
  </CardHeader>
  <CardBody>
    <Stack divider={<StackDivider />} spacing='4'>
    <Box>
        <Heading size='xs' textTransform='uppercase' >
        Minimum Quantity
        </Heading>
        <Text pt='2' fontSize='sm'>
        {data?.quantity}
        <Tag 
          fontSize={"xs"}  
          borderRadius={100}
          textTransform='uppercase'
        >
          pcs
        </Tag>
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
       Wholesale Price Range
        </Heading>
        <Text pt='2' fontSize='sm'>
        ${data?.min_price}~${data?.max_price}
        <Tag 
          fontSize={"xs"}  
          borderRadius={100}
          textTransform='uppercase'
        >
        usd
        </Tag>
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
        Payment Terms
        </Heading>
       <HStack>
       {data?.paypal ? 
          <Text pt='2' fontSize='sm'>PAYPAL</Text>
         : null
        }  
        {data?.tt ? 
          <Text pt='2' fontSize='sm'>T/T</Text>
         : null
        }  
        </HStack>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
        Estimated Delivery of Period
        </Heading>
        <Text pt='2' fontSize='sm'>
        {data?.min_delivery}~{data?.max_delivery}
        <Tag 
          fontSize={"xs"}  
          borderRadius={100}
          textTransform='uppercase'
        >
          days
        </Tag>
        </Text>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
        Transportation
        </Heading>
        <HStack>
        {data?.sea ? 
          <Text pt='2' fontSize='sm'>MARITIME TRANSPORT</Text>
         : null
        }  
        {data?.air ? 
          <Text pt='2' fontSize='sm'>AIR TRANSPORTATION</Text>
         : null
        }  
        {data?.multi ? 
          <Text pt='2' fontSize='sm'>MULTI MODAL</Text>
         : null
        }  
        </HStack>
      </Box>
 
    <TableContainer mt={"4"}>
      <Table variant='striped' colorScheme='gray'>
    <TableCaption>Incoterms 2020 updates for global trade</TableCaption>
    <Thead>
      <Tr>
        <Th>Incoterms</Th>
        <Th>Descriptions</Th>
      </Tr>
    </Thead>

      <Tbody>
        {amenities?.map((amenity) => (
      <Tr
      key={amenity.pk}
      >
        <Td>{amenity.name}</Td>
        <Td>{amenity.description}</Td>
      </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
  </Stack>
  </CardBody>
</Card> 
</TabPanel>

{/*리뷰 부분 */}
<TabPanel>      
      <Box mt={"4"} w={"100%"}>
            <Heading mb={5} fontSize={"xl"}>
              <HStack justifyContent={"flex-start"}>
                <FaStar /> <Text>{data?.rating}</Text>
                <Text>∙</Text>
                <Text>
                  {reviewsData?.length} review
                  {reviewsData?.length === 1 ? "" : "s"}
                </Text>
              </HStack>
            </Heading>

            <Button mt={30} onClick={onReviewOpen} colorScheme={"purple"}>
              Write a Review
            </Button>
            <ReviewModal isOpen={isReviewOpen} onClose={onReviewClose} />

            <Container mt={10} maxW="container.lg" marginX="auto">
            <Collapse startingHeight={400} in={show}>
        <Grid
          w={"100%"}
          gap={10}
          mt={5}
          templateColumns={"repeat(auto-fit, minmax(280px, 1fr))"}
        >
          {reviewsData?.map((review, index) => (
            <VStack alignItems={"flex-start"} key={index} w={"100%"}>
              <Box 
              w={"100%"} 
              h={"100%"} 
              p={3} 
              border={"1px solid #DEE3EE"}
              >
                <HStack w={"100%"} justifyContent={"space-between"}>
                  <Avatar
                    name={review.user.username}
                    src={review.user.avatar}
                    size="sm"
                  />
                  <VStack spacing={0} alignItems={"flex-start"} ml={4}>
                    <Heading fontSize={"sm"}>{review.user.username}</Heading>
                    <HStack spacing={1}>
                      <Box as="span" fontSize="sm">
                        {review.rating}
                      </Box>
                      <Box as="span" color="gray.500">
                        <StarIcon w={3} h={3} color="yellow.400" />
                      </Box>
                    </HStack>
                  </VStack>
                </HStack>

                <Text 
                mt={3}
                whiteSpace="pre-wrap"
                overflowY="auto"
                maxHeight="70px"
                >
                  {review.payload}
                </Text>
              </Box>
            </VStack>
          ))}
        </Grid>
      </Collapse>
                <Button size="sm" onClick={handleToggle} mt="1rem">
         {show ? "Less" : "More"} Reviews
      </Button>
              </Container>
        
              </Box>
            </TabPanel>
      </TabPanels>
  </Tabs>
</Box>
</Box>

{/*오른쪽 설명 부분 */}
<Box px={{ base: 5 }}>
<VStack>

  <Box 
  p="4" 
  mt={"13%"}
  borderWidth="1px" 
  borderRadius="lg" 
  overflowY="scroll"
  >
      <Box  display="flex" alignItems="baseline">
          <Text as="b" borderRadius='full' px='2' colorScheme='teal'>
          PRODUCT DETAILS
          </Text>
      </Box>
      <Box
          p="3"
          as='h4'
          lineHeight='tight'
          h={"lg"}
          whiteSpace="pre-wrap"
        >
        <div dangerouslySetInnerHTML={{ __html: data?.description }} />
      </Box>
      </Box>
      

          {/*캘린더 부분 */}
          <Box 
          as="form" 
          onSubmit={handleSubmit(onSubmit)} 
          pt={10} 
          border="none"
         
          >
            <Calendar 
              formatDay={(locale, date) =>
                date.toLocaleString('en', { day: 'numeric' })
                        }
              locale="en-EN"            
              goToRangeStartOnSelect
              onChange={handleDateChange}
              minDate={new Date()}
              selectRange
            />
            {/*부킹시스템 부분 */}
            <Box mt={5} boxShadow="sm" rounded="md" >
            <FormControl 
            rounded="lg"
            >
            {/* <FormLabel>Guests</FormLabel> */}
            <InputGroup>
              <Input
                {...register("guests")}
                defaultValue={1}
                required
                type="number"
                min={1}
              />
              <InputRightAddon children={"Guests"} />
            </InputGroup>
          </FormControl>
          </Box>
         {/*체크부킹 부분 */}
          <Button
            disabled={!checkBookingData?.ok}
            isLoading={isCheckingBooking && dates !== undefined}
            my={5}
            w="100%"
            colorScheme={"purple"}
            type="submit"
          >
            Make booking
          </Button>
          {!isCheckingBooking && !checkBookingData?.ok ? (
            <Text color="purple.500">Can't book on those dates, sorry.</Text>
          ) : null}
          </Box>
          </VStack>
          </Box>
        </Grid>
    </Box>
    </ProtectedPage>
  );
}