import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import { formatDate } from "./lib/utils";
import { IBooking } from "./routes/RoomDetail";



const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : "https://backend.pashop.kr/api/v1/",
  withCredentials: true,
});


export const getRooms = () =>
  instance.get("rooms/").then((response) => response.data);

export const getWomens = () =>
  instance.get("rooms/womens").then((response) => response.data);

export const getMens = () =>
  instance.get("rooms/mens").then((response) => response.data);

export const getUnisex = () =>
  instance.get("rooms/unisex").then((response) => response.data);  

export const getRoom = ({ queryKey }: QueryFunctionContext) => {
  const [_, roomPk] = queryKey;
  return instance.get(`rooms/${roomPk}`).then((response) => response.data);
  };

{/*업로드리뷰*/}  
export interface IUploadReviewVariables {
  payload: string;
  rating: number;
  roomPk: string;
}
export const uploadReview = (variables: IUploadReviewVariables) =>
    instance
        .post(`rooms/${variables.roomPk}/reviews`, variables, {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        })
        .then((response) => response.data);


{/*리뷰*/}
export const getRoomReviews = ({ queryKey }: QueryFunctionContext) => {
    const [_, roomPk] = queryKey;
    return instance
      .get(`rooms/${roomPk}/reviews`)
      .then((response) => response.data);
  };
{/*로그아웃*/}
export const logOut = () =>
  instance
    .post(`users/log-out`, null, {
      headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
{/*카카오로그인*/}
export const kakaoLogin = (code: string) =>
  instance
    .post(
      `users/kakao`,
      { code },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.status);
{/*구글로그인*/}
export const googleLogin = (code: string) =>
instance
  .post(
    `users/google`,
    { code },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  )
  .then((response) => response.status);
{/*로그인*/}
  export interface IUsernameLoginVariables {
    username: string;
    password: string;
  }
  export interface IUsernameLoginSuccess {
    ok: string;
  }
  export interface IUsernameLoginError {
    error: string;
  }
  
  export const usernameLogIn = ({
    username,
    password,
  }: IUsernameLoginVariables) =>
  instance.post(
    `users/log-in`,
    { username, password },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
    )
    .then((response) => response.status);
{/*회원가입*/}
    export interface IUsernameSignUpVariables {
      username: string;
      email: string;
      password: string;
      re_password: string;
    }
    export interface IUsernameSignUpSuccess {
    ok: string;
    }
    export interface IUsernameSignUpError {
    error: string;
    }
    export const usernameSignUp = ({
      username,
      email,
      password,
      re_password,
    }: IUsernameSignUpVariables) =>
      instance.post(
          `/users/`,
          { username, email, password, re_password },
          {
            headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
          }
        )
        .then((response) => response.status);
{/*업로드룸-개인어메니티*/}
export const getRoomAmenities = ({ queryKey }: QueryFunctionContext) => {
      const [_, roomPk] = queryKey;
return instance
      .get(`rooms/${roomPk}/amenities`)
      .then((response) => response.data);
            };
{/*업로드룸-ALL어메니티*/}
export const getAmenities = () =>
  instance.get(`rooms/amenities`).then((response) => response.data);
{/*업로드룸-카테고리*/}
export const getCategories = () =>
  instance.get(`categories`).then((response) => response.data);

{/*업로드룸*/}  
  export interface IUploadRoomVariables {
    name: string;
    nationality: string;
    category: number;
    year: string;
    season: string;
    kind:string;
    homepage: string;
    address: string;
    person: string;
    country: string;
    max_capacity: number;
    min_capacity: number;
    quantity: number;
    max_price: number;
    min_price: number;
    paypal :boolean;
    tt: boolean;
    max_delivery: number;
    min_delivery: number;
    sea :boolean;
    air: boolean;
    multi: boolean;
    amenities: number[];
    description: string;
    price: number;
    file: string;
  }
  
  export const uploadRoom = (variables: IUploadRoomVariables) =>
    instance
      .post(`rooms/`, variables, {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      })
      .then((response) => response.data);  

{/*체크부킹*/}  
  type CheckBookingQueryKey = [string, string?, Date[]?];

  export const checkBooking = ({
    queryKey,
      }: QueryFunctionContext<CheckBookingQueryKey>) => {
      const [_, roomPk, dates] = queryKey;
      if (dates) {
        const [firstDate, secondDate] = dates;
        const checkIn = formatDate(firstDate);
        const checkOut = formatDate(secondDate);
          return instance
            .get(
              `rooms/${roomPk}/bookings/check?check_in=${checkIn}&check_out=${checkOut}`
            )
            .then((response) => response.data);
        }
      };     
      
{/*Edit Room*/}
export interface IEditRoomVariables extends IUploadRoomVariables {
  roomPk: string;
}
export const editRoom = (variables: IEditRoomVariables) =>
  instance
      .put(`rooms/${variables.roomPk}/`, variables, {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data);

    
{/*Delete Room*/}    
export interface IDeleteRoomVariables {
        roomPk: string;
      }
export const deleteRoom = (variables: IDeleteRoomVariables) =>
          instance
              .delete(`rooms/${variables.roomPk}`,  {
                  headers: {
                      "X-CSRFToken": Cookie.get("csrftoken") || "",
                  },
              })
              .then((response) => response.data);

{/*헤더 아바타 및 회원정보*/}
export const getMe = () =>
  instance.get(`users/me`).then((response) => response.data);
   
{/*프로필수정*/} 
export interface IEditProprofileVariables {
  avatar: string;
  is_host: boolean;
  user: string;
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  gender: string;
  date_of_birth: string;
  brand: boolean;
  buyer: boolean;
  multishop: boolean;
  showroom: boolean;
  department: boolean;
  website: string;
  SNS: string;
  description: string;
}

export const editProfile = (variables: IEditProprofileVariables) =>
  instance
    .put(`users/my-profile`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);    

{/*Change Password*/}
export interface IChangePwVariables {
  old_password: string;
  new_password: string;
  re_new_password: string;
}
export const changePW = (variables: IChangePwVariables) =>
  instance
  .put(`users/change-password`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  })
  .then((response) => response.data);  

{/* 예약 시스템*/}
export const createBooking = (variables: IBooking) =>
  instance
    .post(`rooms/${variables.pk}/bookings`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.status);
{/* 예약 캔슬 정보*/}
export const getBookings = () =>
  instance.get(`bookings/me`).then((response) => response.data);

export const getManageBookings = () =>
  instance.get(`bookings/manage`).then((response) => response.data);

export const cancelBooking = (bookingPk: number) =>
  instance
    .post(`bookings/me/${bookingPk}/cancel`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.status);

{/*Wishlists*/}

  export const getWishlists = () =>
  instance.get(`wishlists/`).then((response) => response.data);

{/*Create Business */}
export interface IBusinessVariables  {
  pk: number;
  nameko: string;
  nameen: string;
  email: string;
  phone: string;
  hscode: string;
  productko: string;
  producten: string;
  handlers: string;
  references: string;
  target: string;
  competitors: string;
  clients: string;
  waiting: boolean;
  completed: boolean;
  results: boolean;
}
export const busiNess = (variables: IBusinessVariables) =>
  instance
      .post(`business/`, variables, {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data);  
{/*My Business */}
export const getMybusiNess = () =>
  instance.get(`business/`).then((response) => response.data);

{/*Edit Business */}

export interface IEditBusinessVariables extends IBusinessVariables {
  roomPk: string;
}
export const putBusiness = (variables: IEditBusinessVariables) =>
  instance
      .put(`business/${variables.roomPk}`, variables, {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data);

{/*get Business */}
export const getBusiness = ({ queryKey }: QueryFunctionContext) => {
  const [_, roomPk] = queryKey;
return instance
  .get(`business/${roomPk}`)
  .then((response) => response.data);
        };


{/*마켓 모임 시작*/}
export const getHome = () =>
	instance.get(`moims/`).then((response) => response.data);

export const getMoim = ({ queryKey }: QueryFunctionContext) => {
	const [_, moimId] = queryKey;

	return instance.get(`moims/${moimId}`).then((response) => response.data);
};

export const getMoimOwner = ({ queryKey }: QueryFunctionContext) => {
	const [_, moimId] = queryKey;

	return instance
		.get(`moims/${moimId}/owner`)
		.then((response) => response.data);
};

export const getMyProfile = () =>
	instance.get(`users/my-profile`).then((response) => response.data);

export const getMyPortfolios = () => {
	return instance
    .get(`users/my-profile/urls`)
		.then((response) => response.data);
};

export const getPortfolio = (portfolioId: string) => {
	return instance
		.get(`portfolios/urls/${portfolioId}`)
		.then((response) => response.data);
};

export interface IPortfolioVariables  {
  id: number;
  title: string;
  description: string;
  url: string;
  is_owner: boolean;
}
export const postPortfolio = (variables: IPortfolioVariables) =>
  instance
      .post(`portfolios/urls`, variables, {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data);  

export interface IEditPortfolioVariables extends IPortfolioVariables {
  portfolioId: number;
}
export const putPortfolio = ({portfolioId}: IEditPortfolioVariables) =>
  instance
      .put(`portfolios/urls/${portfolioId}`,  {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data); 

export const getMyMoimListAsLeader = () => {
	return instance
		.get(`users/my-profile/moims/leader`)
		.then((response) => response.data);
};

export const getMyMoimListAsCrew = () => {
	return instance
		.get(`users/my-profile/moims/crew`)
		.then((response) => response.data);
};

export const getMyMoimListAsOwner = () => {
	return instance
		.get(`users/my-profile/moims/owner`)
		.then((response) => response.data);
};

export const getCrewJoin = (crewJoinId: string) => {
	return instance
		.get(`moims/crew-join/${crewJoinId}`)
		.then((response) => response.data);
};

export const getEditCrewJoin = ({ queryKey }: QueryFunctionContext) => {
  const [_, crewJoinId] = queryKey;
  return instance.get(`moims/crew-join/${crewJoinId}`).then((response) => response.data);
  };

export const getLeaderApply = (leaderApplyId: string) => {
	return instance
		.get(`moims/leader-apply/${leaderApplyId}`)
		.then((response) => response.data);
};

export const getEditLeaderApply = ({ queryKey }: QueryFunctionContext) => {
  const [_, leaderApplyId] = queryKey;
  return instance.get(`moims/leader-apply/${leaderApplyId}`).then((response) => response.data);
  };

export interface IChooseLeaderVariables {
    moimId: string;
    id: number;
		username: string;
		

    }
export const chooseLeader = (variables: IChooseLeaderVariables) =>
        instance
            .post(`moims/${variables.moimId}/owner/choose-leader`, 
            variables, 
            {
                headers: {
                  "X-CSRFToken": Cookie.get("csrftoken") || "",
              },
          })
          .then((response) => response.data);   
            
interface ICrewJoinVariable {
	description: string;
	moimId: string;
}

export const postCrewJoin = ({ description, moimId }: ICrewJoinVariable) =>
	instance
		.post(
			`moims/${moimId}/crew-join`,
			{ description },
			{
				headers: {
					"X-CSRFToken": Cookie.get("csrftoken") || "",
				},
			}
		)
		.then((response) => response.data);

export interface IEditCrewJoinVariable {
      description: string;
      moimId: string;
      crewJoinId: string;
}

export const putCrewJoin = ({ description, crewJoinId 
    }: IEditCrewJoinVariable) =>
      instance
        .put(
          `moims/${crewJoinId}/crew-join/edit`,
          { description },
          {
            headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
          }
        )
        .then((response) => response.data);   

export interface IDeleteCrewJoinVariables {
  crewJoinId: string;
    }
export const deleteCrewJoin = (variables: IDeleteCrewJoinVariables) =>
        instance
            .delete(`moims/${variables.crewJoinId}/crew-join/edit`,  {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                },
            })
            .then((response) => response.data);        
    export interface ILeaderApplyVariable {
      description: string;
      portfolios: number;
      moimId: string;
    }
    
    export const postLeaderApply = (variables: ILeaderApplyVariable) =>
      instance
        .post(`moims/${variables.moimId}/leader-apply`, variables, {
          headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
        })
        .then((response) => response.data);  
export interface IEditLeaderApplyVariable {
      description: string;
      portfolios: number;
      moimId: string;
      leaderApplyId: string;
      
    }
export const putLeaderApply = ({ description, portfolios, leaderApplyId 
}: IEditLeaderApplyVariable) =>
	instance
		.put(
			`moims/${leaderApplyId}/leader-apply/edit`,
			{ description, portfolios  },
			{
				headers: {
					"X-CSRFToken": Cookie.get("csrftoken") || "",
				},
			}
		)
		.then((response) => response.data);        

export interface IDeleteLeaderApplyVariables {
    leaderApplyId: string;
    }
export const deleteLeaderApply = (variables: IDeleteLeaderApplyVariables) =>
        instance
            .delete(`moims/${variables.leaderApplyId}/leader-apply/edit`,  {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                },
            })
            .then((response) => response.data);
              
export interface IMoimVariables {
	title: string;
	topics: string;
	moim_types: string;
	description: string;
	target_amount: string;
	min_participants: number;
	max_participants: number;
	first_date: string;
	total_moim_times: number;
	is_online: boolean;
	location: string;
	expiration_date: string;
	owner: number;
}

export const postMoim = ({
	title,
	topics,
	moim_types,
	description,
	target_amount,
	min_participants,
	max_participants,
	first_date,
	total_moim_times,
	is_online,
	location,
	expiration_date,
	owner,
}: IMoimVariables) => {
	return instance
		.post(
			`moims/`,
			{
				title,
				topics: [topics],
				moim_types: [moim_types],
				description,
				target_amount: Number(target_amount),
				min_participants: Number(min_participants),
				max_participants: Number(max_participants),
				first_date,
				total_moim_times,
				is_online,
				location,
				expiration_date,
				owner: { id: owner },
			},
			{
				headers: {
					"X-CSRFToken": Cookie.get("csrftoken") || "",
					"Content-Type": "application/json",
				},
			}
		)
		.then((response) => response.data);
};

export const closedMoims = (moimID: number) =>
  instance
    .post(`moims/${moimID}/owner/change-state`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.status);

{/*마켓 모임 끝*/}




{/*Create Buyer */}
export interface IBuyersVariables  {
  pk: number;
  user: string;
  company: string;
  ceo: string;
  address: string;
  country: string;
  city: string;
  homepage: string;
  year: string;
  employees: string;
  annual: string;
  introduction: string;
  title: string;
  category: string;
  type: string;
  usage: string;
  volume: string;
  offering: string;
  expiration_date: number;
  first_date: number;
  match: boolean;
  created_at: number;
  updated_at: number;
}
export const postBuyers = (variables: IBuyersVariables) =>
  instance
      .post(`buyers/`, variables, {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data);  
{/*Get buyer */}
export const getBuyers = () =>
  instance.get(`buyers/`).then((response) => response.data);

{/*Edit buyer */}

export interface IEditBusinessVariables extends IBusinessVariables {
  buyerPk: string;
}
export const putBuyers = (variables: IEditBusinessVariables) =>
  instance
      .put(`buyers/${variables.buyerPk}`, variables, {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data);

{/*Get PK Buyer */}
export const getPkBuyers = ({ queryKey }: QueryFunctionContext) => {
  const [_, buyerPk] = queryKey;
return instance
  .get(`buyers/${buyerPk}`)
  .then((response) => response.data);
        };  

{/*Get matchings */}        
export const getMatchings = (buyerPk: string) => {
	return instance
		.get(`buyers/matchings/${buyerPk}`)
		.then((response) => response.data);
};

export const getEditMatchings = ({ queryKey }: QueryFunctionContext) => {
  const [_, buyerPk] = queryKey;
  return instance.get(`buyers/${buyerPk}/matchings`).then((response) => response.data);
  };

export const getBuyerMatching = ({ queryKey }: QueryFunctionContext) => {
  const [_, buyerPk] = queryKey;
  return instance.get(`buyers/matchings/${buyerPk}`).then((response) => response.data);
  };  
  
export interface IMatchingsVariable {
    description: string;
    room: number;
    buyerPk: string;
  }
export const postMatchings = ({ description, room, buyerPk 
    }: IMatchingsVariable) =>
        instance
          .post(
            `buyers/${buyerPk}/matchings`,
            { description, room },
            {
              headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
              },
            }
          )
          .then((response) => response.data);
  
export interface IEditMatchingVariable {
        description: string;
        room: number;
        buyerPk: string;
        matchPk: string;
  }
  
export const putMatchings = ({ description, room, buyerPk 
      }: IEditMatchingVariable) =>
        instance
          .put(
            `buyers/${buyerPk}/matchings/edit`,
            { description, room },
            {
              headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
              },
            }
          )
          .then((response) => response.data);   
  
export interface IDeleteMatchingsVariables {
  matchPk: string;
      }
export const deleteMatchings = (variables: IDeleteMatchingsVariables) =>
          instance
              .delete(`buyers/${variables.matchPk}/matchings/edit`,  {
                  headers: {
                      "X-CSRFToken": Cookie.get("csrftoken") || "",
                  },
              })
              .then((response) => response.data);   

{/* 바이어매칭 정보*/}
export const getBuyerMatch = () =>
  instance.get(`buyers/me`).then((response) => response.data);

export const getBuyerManage = () =>
  instance.get(`buyers/manage`).then((response) => response.data);

export const cancelBuyerMatch = (buyerPk: number) =>
  instance
    .post(`buyers/me/${buyerPk}/cancel`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.status);      

{/* 파트너 정보*/}
export const getPartners = () =>
  instance.get("partners/").then((response) => response.data);
  
export const getKorea = () =>
  instance.get("partners/korea").then((response) => response.data);
  
export const getWorld = () =>
  instance.get("partners/world").then((response) => response.data);
  
export const getPartner = ({ queryKey }: QueryFunctionContext) => {
  const [_, roomPk] = queryKey;
  return instance.get(`partners/${roomPk}`).then((response) => response.data);
  };    

{/*업로드파트터-개인컨셉*/}
export const getConcepts = ({ queryKey }: QueryFunctionContext) => {
  const [_, roomPk] = queryKey;
return instance
  .get(`partners/${roomPk}/concepts`)
  .then((response) => response.data);
        };
{/*업로드파트너-ALL컨셈*/}
export const getAllConcepts = () =>
instance.get(`partners/concepts`).then((response) => response.data);

{/*업로드파트너*/}  
export interface IPostPartnersVariables {
company: string;
category: number;
type: string;
country: string;
city:string;
address: string;
tel: string;
homepage: string;
sns: string;
list: string;
concept: number[];
description: string;
first_name: string;
last_name: string;
email: string;
phone: string;
}

export const postPartners = (variables: IPostPartnersVariables) =>
instance
  .post(`partners/`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  })
  .then((response) => response.data);
  
{/*Edit 파트너*/}
export interface IPutPartnersVariables extends IPostPartnersVariables {
  roomPk: string;
}
export const putPartners = (variables: IPutPartnersVariables) =>
  instance
      .put(`partners/${variables.roomPk}/`, variables, {
          headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
      })
      .then((response) => response.data);

    
{/*Delete 파트너*/}    
export interface IDeletePartnerVariables {
        roomPk: string;
      }
export const deletePartners = (variables: IDeletePartnerVariables) =>
          instance
              .delete(`partners/${variables.roomPk}`,  {
                  headers: {
                      "X-CSRFToken": Cookie.get("csrftoken") || "",
                  },
              })
              .then((response) => response.data);  

{/*업로드 포토*/} 
export const getUploadURL = () =>
  instance
    .post(`medias/photos/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);


{/*클라우드플레어*/} 
export interface IUploadImageVarialbes {
  file: FileList;
  uploadURL: string;
}
export const uploadImage = ({ file, uploadURL }: IUploadImageVarialbes) => {
  const form = new FormData();
  form.append("file", file[0]);
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

{/*룸 포토*/}  
export interface ICreatePhotoVariables {
  description: string;
  file: string;
  roomPk: string;
}
export const createPhoto = ({
  description,
  file,
  roomPk,
}: ICreatePhotoVariables) =>
  instance
    .post(
      `rooms/${roomPk}/photos`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

{/*파트너샵 포토*/}     
export interface IPhotoPartnerVariables {
  description: string;
  file: string;
  roomPk: string;
}
export const partnerPhoto = ({
  description,
  file,
  roomPk,
}: IPhotoPartnerVariables) =>
  instance
    .post(
      `partners/${roomPk}/photos`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);