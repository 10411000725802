import { useForm } from "react-hook-form";
import {
  Avatar,
  Button,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Text,
  Textarea,
  HStack,
  Spacer,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  } from "@chakra-ui/react";
  import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
  import { useParams } from "react-router-dom";
  import { 
    IUploadReviewVariables,
    uploadReview, 
  } from "../api";
  import useUser from "../lib/useUser";
  import useUserProfile from "../lib/useUserProfile";

  interface ReviewModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  
  interface IReviewForm {
    payload: string;
    rating: number;
    roomPk: string;
  }

  export default function ReviewModal({ isOpen, onClose }: ReviewModalProps) {
    const { profile } = useUserProfile();
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm<IReviewForm>();
    console.log(errors);
    const { roomPk } = useParams();
    const toast = useToast();
    const queryClient = useQueryClient();
    const { userLoading, isLoggedIn, user } = useUser();
    const mutation = useMutation(uploadReview, {
      onSuccess: () => {
        toast({ 
          status: "success",
          title: "Upload Review",
          description: "Your review has been uploaded.",
          position: "bottom-right",
        });
        onClose();
        queryClient.refetchQueries(["me"]);
        reset();
      },
    });

    const onSubmit = (data: IUploadReviewVariables) => {
      if (roomPk) {
          data["roomPk"] = roomPk;
          mutation.mutate(data);
      }
  };
  const refreshPage = ()=>{
    window.setTimeout("window.location.reload()",2000);
 }
    return (
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>REVIEW</ModalHeader>
          <ModalCloseButton />
          <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>         
            <VStack align="flex-start" alignItems="flex-start" >
      {/*Avatar*/}
      <HStack gridColumn="1 / 3">
      <Avatar 
      mt={3}
      name={profile?.company} 
      src={profile?.avatar} 
      size={"md"} />
      <Spacer />
      <Text 
        p={1}
        align="center" 
        alignItems="center" 
        as="i"
        >
        {profile?.company} 
      </Text>
      </HStack>
      {/*rating*/} 
      <FormControl isRequired isInvalid={Boolean(errors.rating)}>
      <FormLabel mt={5}>Rating⭐️</FormLabel>
      <InputGroup size={"md"}>
        <NumberInput step={1} min={1} max={5}>
          <NumberInputField {...register("rating")}/>
            <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
        </InputGroup>
                <FormHelperText>
                Write a score between 1 and 5
                </FormHelperText>
        <FormErrorMessage>
          {errors.rating && errors.rating.message}
        </FormErrorMessage>
        </FormControl>

      {/*payload*/} 
      <FormControl isRequired isInvalid={Boolean(errors.payload)}>
      <FormLabel mt={5}>Comments</FormLabel>
        <InputGroup size={"md"}>
          <Textarea
            {...register("payload", {
              required: "Please write comments",
              minLength: { value: 4, message: "Minimum length should be 4 characters" },
            })}
            />
        </InputGroup>
            <FormHelperText>
            Please write comments
            </FormHelperText>
        <FormErrorMessage>
          {errors.payload && errors.payload.message}
        </FormErrorMessage>
        </FormControl>
            </VStack>

            <Button
              isLoading={mutation.isLoading}
              w="100%"
              colorScheme="purple"
              mt={5}
              type="submit"
              onClick={refreshPage}
            >
                    UPLOAD REVIEW
            </Button>

          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }   