import { 
  Box, 
  VStack, 
  Grid, 
  GridItem, 
  Text, 
  Heading,
  useColorMode,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getManageBookings, getRooms } from "../api";
import { Link, useParams,  } from "react-router-dom";
import { IRoomList, IManageBooking } from "../types";
import useUserProfile from "../lib/useUserProfile";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function ManageBookings() {
  const { colorMode } = useColorMode();
  const { roomPk } = useParams();
  const { profile } = useUserProfile();
  const { data } = useQuery<IRoomList>(["rooms"], getRooms);
  const { isLoading, data: booking } = useQuery<IManageBooking[]>(
    ["manageBookings"],
    getManageBookings
  );
  return (
    <Box
      mt={10}
      px={{
        base: 10,
        lg: 20,
      }}
    >

  <HelmetProvider>
      <Helmet>
        <title>PASHOP</title>
    </Helmet>
  </HelmetProvider>

      <Text 
      display={"block"} 
      mb={8} 
      as={"b"} 
      fontSize={{ base: "20px", lg: "30px" }}
      >
        Manage Bookings
      </Text>
      <Box mb={4}>
        <Text as={"b"}>
        Total of {booking?.length} 
        </Text>
      </Box>
      <Grid
        templateColumns={{
          base: "1fr 1fr",    // 스크린 크기가 'base'일 때의 값
          md: "repeat(3, 1fr)",      // 스크린 크기가 'md'일 때의 값
          lg: "repeat(6, 1fr)",      // 스크린 크기가 'lg'일 때의 값
        }}
        gap={3}
        w={"100%"}
        bg={colorMode === "dark" ? "#FFDB58" : "#FFDB58"}
        alignItems={"center"}
        justifyItems="center"
        borderTop={"1px solid gray"}
        borderBottom={"1px solid rgb(190,190,190)"}
        py={4}
        mb={2}
        color="black"
      >
        <GridItem as={"b"}>Brand</GridItem>
        <GridItem as={"b"}>Booked by</GridItem>
        <GridItem as={"b"}>Guests</GridItem>
        <GridItem as={"b"}>Available</GridItem>
        <GridItem as={"b"}>In</GridItem>
        <GridItem as={"b"}>Out</GridItem>
        
      </Grid>

      {/* skeleton */}
      {booking?.map((booking) => (
        <Grid
          key={booking.id}
          templateColumns={{
            base: "1fr 1fr",    // 스크린 크기가 'base'일 때의 값
            md: "repeat(3, 1fr)",      // 스크린 크기가 'md'일 때의 값
            lg: "repeat(6, 1fr)",      // 스크린 크기가 'lg'일 때의 값
          }}
          gap={3}
          w={"100%"}
          bgColor="white.200"
          alignItems={"center"}
          justifyItems="center"
          borderTop={"1px solid rgb(190,190,190)"}
          borderBottom={"1px solid rgb(190,190,190)"}
          py={6}
          mb={1}
          fontWeight={"400"} fontSize={{ base: "12px", lg: "16px" }}
        >
          <GridItem fontWeight={"400"} noOfLines={1}>

          <Link to={`/rooms/${booking.room.id}`}>
            <Text noOfLines={1} _hover={{ color: "red.500" }}>
              {booking.room.name}
            </Text>
          </Link>
       
          </GridItem>
          <GridItem
            fontWeight={"400"}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <Box>
              <Text>{booking.user.company}</Text>
            </Box>
            <Box>
              <Text>{booking.user.first_name}{booking.user.last_name}</Text>
            </Box>
            <Box>
              <Text>{booking.user.email}</Text>
            </Box>

          </GridItem>
          
          <GridItem fontWeight={"400"}>
            {booking.guests} Guest
            {booking.guests === 1 ? "" : "s"} 
          </GridItem>

          {booking.not_canceled ? (
            <GridItem
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              color={"blue.500"}
              fontWeight={"400"}
            >
              <Text as={"b"}>Available</Text>

            </GridItem>
          ) : (
            <GridItem
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              color={"red.500"}
              fontWeight={"400"}
            >
              <Text as={"b"}>Canceled</Text>

            </GridItem>
          )}
          
          <GridItem fontWeight={"400"}>{booking.check_in}</GridItem>
          <GridItem fontWeight={"400"}>{booking.check_out}</GridItem>
          
         
        </Grid>
      ))}
       {booking?.length === 0 ? (
						<VStack>
							<Heading fontSize={"lg"}>No bookings found!</Heading>
						</VStack>
					) : null}
    </Box>
  );
}