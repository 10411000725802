import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    Image,
    Flex,
    VStack,
    Button,
    Heading,
    SimpleGrid,
    StackDivider,
    useColorModeValue,
    VisuallyHidden,
    Link,
    List,
    ListItem,
    useDisclosure,
  } from "@chakra-ui/react";
  import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
  import { MdLocalShipping } from 'react-icons/md';
  import { useQuery } from "@tanstack/react-query";
  import { 
    getPkBuyers, 
    getRoom,
  } from "../../api";
  import { IBuyers, IRoomDetail } from "../../types";
  import React, { useEffect } from 'react';
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
  import { useForm } from "react-hook-form";
  import useUserProfile from "../../lib/useUserProfile";
  import ProtectedPage from "../../components/ProtectedPage"
  
  export default function BuyerDetail() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const { isLoggedIn } = useUserProfile();
    const { profile } = useUserProfile();
    const navigate = useNavigate();
    const { buyerPk, matchPk } = useParams();
    const { isLoading, data } = useQuery<IBuyers>(
        [`buyers`, buyerPk, matchPk], getPkBuyers);

  {/*Delete Click */}
    const {
      isOpen: isDeleteOpen,
      onClose: onDeleteClose,
      onOpen: onDeleteOpen,
    } = useDisclosure();
  
  {/*Edit room Click */}
    const onEditClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      navigate(`/`);
  };
 
    return (
      <ProtectedPage>
    <Box>
        {/*웹 상단 탭*/}
        <HelmetProvider>
        <Helmet>
          <title>{data ? data.company : "Loading..."}</title>
        </Helmet>
        </HelmetProvider>

        <Container maxW={'7xl'}>
      <SimpleGrid
        columns={{ base: 1, lg: 1 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 18, md: 24 }}>
        
        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={'header'}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
              Buying Offer
            </Heading>
            
            <Text
              color={useColorModeValue('gray.900', 'gray.400')}
              fontWeight={300}
              fontSize={'2xl'}>
              @{data?.company}
            </Text>
           
          </Box>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={'column'}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.200', 'gray.600')}
              />
            }>
          
            <Box>
            <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('yellow.500', 'yellow.300')}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                Offer
              </Text>
              <Text fontSize={'lg'}>
                {data?.offering}
              </Text>
              </Box>
       
            <Box>
              <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('yellow.500', 'yellow.300')}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                Offer Detail
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Title:
                  </Text>{' '}
                  {data?.title}
                </ListItem>
                  <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Category:
                  </Text>{' '}
                  {data?.category}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Type:
                  </Text>{' '}
                  {data?.type}
                </ListItem>
                </List>
                <List spacing={2}>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Usage:
                  </Text>{' '}
                  {data?.usage}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Volume of order:
                  </Text>{' '}
                  {data?.volume}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Available Period:
                  </Text>{' '}
                  {data?.first_date}~{data?.expiration_date}
                </ListItem>
                </List>
              </SimpleGrid>
            </Box>
            <Box>
              <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('yellow.500', 'yellow.300')}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                CORPORATE PROFILE
              </Text>

              <List spacing={2}>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Company Name:
                  </Text>{' '}
                  {data?.company}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  CEO:
                  </Text>{' '}
                  {data?.ceo}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Address:
                  </Text>{' '}
                  {data?.address}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Country:
                  </Text>{' '}
                  {data?.country}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  City:
                  </Text>{' '}
                  {data?.city}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Homepage:
                  </Text>{' '}
                  {data?.homepage}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Established Year:
                  </Text>{' '}
                  {data?.year}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  No. of Employees:
                  </Text>{' '}
                  {data?.employees}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Annual Sales:
                  </Text>{' '}
                  {data?.annual}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Introduction:
                  </Text>{' '}
                  {data?.introduction}
                </ListItem>
              </List>
            </Box>
          </Stack>

        <Link 
        as={RouterLink}
        to={`/buyer/${buyerPk}/matchings/create`}
        style={{ textDecoration: "none" }}
        >
          <Button
            rounded={'none'}
            w={'full'}
            mt={8}
            size={'lg'}
            py={'7'}
            bg={'#5538EE'}
            color={'white'}
            textTransform={'uppercase'}
            _hover={{
              transform: 'translateY(2px)',
              boxShadow: 'lg',
              
            }}>
            Match now
          </Button>
        </Link>

        </Stack>
      </SimpleGrid>
    </Container>
    
    </Box>
    </ProtectedPage>
    );
  }