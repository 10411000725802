import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import KakaoConfirm from "./routes/KakaoConfirm";
import GoogleConfirm from "./routes/GoogleConfirm";
import NotFound from "./routes/NotFound";
import RoomDetail from "./routes/RoomDetail";
import UploadPhotos from "./routes/UploadPhotos";
import UploadRooms from "./routes/UploadRoom";
import EditRoom from "./routes/EditRoom";
import PhotoRoom from "./routes/PhotoRoom";
import ChangePassword from "./routes/ChangePw";
import WishlistsPage from "./routes/WishlistsPage";
import ManageBookings from "./routes/ManageBookings";
import MyBookings from "./routes/MyBookings";
import CreateBusiness from "./routes/business/CreateBusiness";
import MyBusiness from "./routes/business/MyBusiness";
import EditBusiness from "./routes/business/EditBusiness";
import TestimonialCard from "./components/footer/AboutUs";
import Pricing from "./components/footer/Pricing";
import Privacy from "./components/footer/Privacy";
import Terms from "./components/footer/TermsOf";
import ContactForm from "./components/footer/ContactUs";
import MyFaqForm from './components/footer/Faq';
import Womens from "./routes/category/Womens";
import Mens from "./routes/category/Mens";
import Unisex from "./routes/category/Unisex";
import EditMyprofile  from "./routes/Editmyprofile";
import BuyerHome  from "./routes/buyer/BuyerHome";
import BuyerDetail  from "./routes/buyer/BuyerDetail";
import PartnerKorea  from "./routes/partner/PartnerKorea";
import PartnerWorld  from "./routes/partner/PartnerWorld";
import PartnerHome  from "./routes/partner/PartnerHome";
import PartnerDetail  from "./routes/partner/PartnerDetail";
import PartnerUpload  from "./routes/partner/PartnerUpload";
import PartnerEdit  from "./routes/partner/PartnerEdit";
import PartnerPhotos  from "./routes/partner/PartnerPhoto";
import BuyerPost  from "./routes/buyer/BuyerPost";
import {
  BrandManage,
  BuyerManage,  
} from "./routes/buyer/BuyerManage";
import { 
  MatchingCreate, 
  MatchingsEdit,
} from "./routes/buyer/BuyerMatch";



import { MoimRoot, MoimCreate, MoimDetail, MoimOwner } from "./routes/market/Moims";
import {
	ApplyLeaderCreate,
	ApplyLeaderDetail,
	ApplyLeaderEdit,
} from "./routes/market/ApplyLeader";
import { CrewJoinCreate, CrewJoinEdit } from "./routes/market/CrewJoin";
import MoimHome from "./routes/market/MoimHome";
import {
	PortfolioRoot,
	PortfolioCreate,
	PortfolioEdit,
} from "./routes/market/Portfolios";
import {
	MyProfileRoot,
	MyProfile,
	MyPortfolioList,
	MyMoimListAsCrew,
	MyMoimListAsLeader,
	MyMoimListAsOwner,
} from "./routes/market/MyProfile";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "partners/:roomPk/photos",
        element: <PartnerPhotos />,
      },
      {
        path: "partners/:roomPk/edit",
        element: <PartnerEdit />,
      },
      {
        path: "partners/upload",
        element: <PartnerUpload />,
      },
      {
        path: "partners/home/korea",
        element: <PartnerKorea />,
      },
      {
        path: "partners/home/world",
        element: <PartnerWorld />,
      },
      {
        path: "partners/home",
        element: <PartnerHome />,
      },
      {
        path: "partners/:roomPk",
        element: <PartnerDetail />,
      },
      {
        path: "buyer/home/upload",
        element: <BuyerPost />,
      },
      {
        path: "buyer/home/buyer/manager",
        element: <BuyerManage />,
      },
      {
        path: "buyer/home/brand/manager",
        element: <BrandManage />,
      },
      {
        path: "buyer/home",
        element: <BuyerHome />,
      },
      {
        path: "buyer/:buyerPk",
        element: <BuyerDetail />,
      },
      {
        path: "buyer/:buyerPk/matchings/create",
        element: <MatchingCreate />,
      },
      {
        path: "buyer/:buyerPk/matchings/edit",
        element: <MatchingsEdit />,
      },
      {
        path: "users/myeditprofile",
        element: <EditMyprofile />,
      },
      {
        path: "womens",
        element: <Womens />,
      },
      {
        path: "mens",
        element: <Mens />,
      },
      {
        path: "unisex",
        element: <Unisex />,
      },
      {
        path: "rooms/uploadrooms",
        element: <UploadRooms />,
      },
      {
        path: "rooms/:roomPk",
        element: <RoomDetail />,
      },
      {
        path: "rooms/:roomPk/edit",
        element: <EditRoom />,
      },
      {
        path: "rooms/:roomPk/photoroom",
        element: <PhotoRoom />,
      },
      {
        path: "rooms/:roomPk/photos",
        element: <UploadPhotos />,
      },
      {
        path: "users/changepw",
        element: <ChangePassword />,
      },
      {
        path: "users/wishlists",
        element: <WishlistsPage />,
      },
      {
        path: "mybookings",
        element: <MyBookings />,
      },
      {
        path: "manage-bookings",
        element: <ManageBookings />,
      },
      {
        path: "business",
        element: <CreateBusiness />,
      },
      {
        path: "business/status",
        element: <MyBusiness />,
      },
      {
        path: "business/modify",
        element: <EditBusiness />,
      },
      {
        path: "aboutus",
        element: <TestimonialCard />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "termsofservice",
        element: <Terms />,
      },
      {
        path: "contactus",
        element: <ContactForm />,
      },
      {
        path: "faq",
        element: <MyFaqForm />,
      },
      {
        path: "social",
        children: [
          {
            path: "kakao",
            element: <KakaoConfirm />,
          },
          {
            path: "google",
            element: <GoogleConfirm />,
          },
        ],
      },

      {
        path: "moimhome",
        element: <MoimHome />,
      },

      {
				element: <MoimRoot />,
				path: "moims",
				children: [
					{
						path: "create",
						element: <MoimCreate />,
					},
					{
						path: ":moimId",
						element: <MoimDetail />,
					},
					{
						path: ":moimId/owner",
						element: <MoimOwner />,
					},
					{
						path: ":moimId/apply-leader/create",
						element: <ApplyLeaderCreate />,
					},
					{
						path: ":moimId/apply-leader/:leaderApplyId",
						element: <ApplyLeaderDetail />,
					},
					{
						path: ":moimId/apply-leader/:leaderApplyId/edit",
						element: <ApplyLeaderEdit />,
					},
					{
						path: ":moimId/crew-join/create",
						element: <CrewJoinCreate />,
					},
					{
						path: ":moimId/crew-join/:crewJoinId/edit",
						element: <CrewJoinEdit />,
					},
				],
			},

      {
				path: "portfolios",
				element: <PortfolioRoot />,
				children: [
					{
						path: "create",
						element: <PortfolioCreate />,
					},
					{
						path: ":portfolioId/edit",
						element: <PortfolioEdit />,
					},
				],
			},
			{
				path: "my-profile",
				element: <MyProfileRoot />,
				children: [
					{
						path: "",
						element: <MyProfile />,
					},
					{
						path: "portfolios",
						element: <MyPortfolioList />,
					},
					{
						path: "moims/crew",
						element: <MyMoimListAsCrew />,
					},
					{
						path: "moims/leader",
						element: <MyMoimListAsLeader />,
					},
					{
						path: "moims/owner",
						element: <MyMoimListAsOwner />,
					},
				],
			},

    ],
  },
]);

export default router;