import { 
    Box, 
    Button, 
    Grid, 
    GridItem, 
    Text,
    Container,
    VStack,
    HStack,
    Heading,
    Divider,
    Flex,
    Badge,
    Table,
    Tbody,
    Td,
    TableCaption,
    TableContainer,
    Th,
    Thead,
    Tr,
    useToast,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { 
    getBuyerManage,
    getBuyerMatch,
    cancelBuyerMatch,
    getEditMatchings, 
    getRooms 
} from "../../api";
import { Link, useParams,  } from "react-router-dom";
import { IBuyerManage } from "../../types";
import useUserProfile from "../../lib/useUserProfile";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import ProtectedPage from "../../components/ProtectedPage";


interface IManageModalBtn {
	pk: number;
	name: string;
	year: string;
	season: string;
    homepage: string;
    address: string;
}

const ManageCardModalBtn = ({
	pk,
	name,
	year,
	season,
    homepage,
    address,
}: IManageModalBtn) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Button color={"blue"} variant={"link"} onClick={onOpen}>
				{name}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent pb={3}>
					<ModalCloseButton />
					<ModalHeader>Details</ModalHeader>
					<ModalBody borderRadius={15} as={VStack} alignItems={"flex-start"}>
						
						<Divider />
						<Heading pt={3} size={"sm"}>
							Info.
						</Heading>
						<Box
							p={6}
							border={"1px solid #DCDCDC"}
							borderRadius={15}
							width={"100%"}
							h={"max-content"}
						>
							{year}{season}
						</Box>
                        <Box
							p={6}
							border={"1px solid #DCDCDC"}
							borderRadius={15}
							width={"100%"}
							h={"max-content"}
						>
							{homepage}
						</Box>
                        <Box
							p={6}
							border={"1px solid #DCDCDC"}
							borderRadius={15}
							width={"100%"}
							h={"max-content"}
						>
							{address}
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export const BrandManage = () => {
  const { buyerPk } = useParams();
  const { profile } = useUserProfile();
  const { isLoading, data: match } = useQuery<IBuyerManage[]>(
    ["BuyerManage"],
    getBuyerMatch
  );

  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation(cancelBuyerMatch, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Successfully canceled!",
        isClosable: true,
      });
      queryClient.refetchQueries(["BuyerManage"]);
    },
  });
  const onClick = (pk: number) => {
    mutation.mutate(pk);
};

  return (
    <ProtectedPage>
        <VStack spacing={10}>
          <Heading mt={10} size={"xl"}>
            My Matching
            <Text>for BRAND</Text>
          </Heading>
          <Box>
            <Text mt={5} as={"b"} size={"lg"}>
              Total of {match?.length}
            </Text>
            </Box>
            <TableContainer>
          <Table variant="striped" colorScheme="gray" w="100%">
            <TableCaption>
            <Alert status='error'>
                <AlertIcon />
                <AlertTitle>Click carefully!</AlertTitle>
                <AlertDescription>If you click the cancel button, you cannot rematch it again.</AlertDescription>
                </Alert>
            </TableCaption>
            <Thead >
              <Tr>
                <Th>Buyer Offer</Th>
                <Th>My Info</Th>
                <Th>Matching</Th>
              </Tr>
            </Thead>
            <Tbody>
              {match?.map((matching) => (
                <Tr key={matching.pk}>
                  <Td>
                    <VStack alignItems={"flex-start"}>
                      <Text as="b" fontSize={"14px"}>
                        COMPANY:
                      </Text>
                      <Text>{matching.buyer.company}</Text>
                      <Text as="b" fontSize={"14px"}>
                        CEO:
                      </Text>
                      <Text>{matching.buyer.ceo}</Text>
                      <Text as="b" fontSize={"14px"}>
                        OFFERING:
                      </Text>
                      <Text 
                      style={{ whiteSpace: 'pre-wrap' }}
                      noOfLines={[3]}
                      >
                      {matching.buyer.offering}
                      </Text> 
                    </VStack>
                  </Td>
                  <Td>
                    <VStack alignItems={"flex-start"}>
                  
                      <Text as="b" fontSize={"14px"}>
                        NAME:
                      </Text>
                      <Text>
                        {matching.user.first_name} {matching.user.last_name}
                      </Text>
                      <Text as="b" fontSize={"14px"}>
                        EMAIL:
                      </Text>
                      <Text>{matching.user.email}</Text>
                      <Text as="b" fontSize={"14px"}>
                        COMPANY:
                      </Text>
                      <Text>{matching.user.company}</Text>
                      <Text as="b" fontSize={"14px"}>
                        WEBSITE:
                      </Text>
                      <Text>{matching.user.website}</Text>
                    </VStack>
                  </Td>
                
                  <Td>
                  <VStack alignItems={"flex-start"}>
                    {matching.not_canceled ? (
                    <VStack>
                      <Badge font-size="2em" colorScheme='green'>
                        Matching
                    </Badge>
                     
                    <Button
                        mt={20}
                        borderRadius={50}
                        bg="#6B4EFF"
                        color={"white"}
						onClick={() => onClick(matching.pk)}
						>
							Cancel
	
						</Button>
                    </VStack>
                    ) : (
                      <Badge font-size="1em" colorScheme="red">Canceled</Badge>
                    )}
                    </VStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          </TableContainer>
        </VStack>
      </ProtectedPage>
  );
};  

export const BuyerManage = () => {
    const { buyerPk } = useParams();
    const { profile } = useUserProfile();
    const { isLoading, data: match } = useQuery<IBuyerManage[]>(
      ["BuyerManage"],
      getBuyerMatch
    );
    
    return (
      <ProtectedPage>
        <VStack spacing={10}>
          <Heading mt={10} size={"xl"}>
            My Matching
            <Text>for BUYER</Text>
          </Heading>
          <Box>
            <Text mt={5} as={"b"} size={"lg"}>
              Total of {match?.length}
            </Text>
            </Box>
            <TableContainer>
          <Table variant="striped" colorScheme="gray" w="100%">
            <TableCaption>Matching results</TableCaption>
            <Thead >
              <Tr>
                <Th>My Offer</Th>
                <Th>Brand Info</Th>
                <Th>Matching</Th>
              </Tr>
            </Thead>
            <Tbody>
              {match?.map((matching) => (
                <Tr key={matching.pk}>
                  <Td>
                    <VStack alignItems={"flex-start"}>
                      <Text as="b" fontSize={"14px"}>
                        COMPANY:
                      </Text>
                      <Text>{matching.buyer.company}</Text>
                      <Text as="b" fontSize={"14px"}>
                        CEO:
                      </Text>
                      <Text>{matching.buyer.ceo}</Text>
                      <Text as="b" fontSize={"14px"}>
                        OFFERING:
                      </Text>
                      
                      <Text 
                     style={{ whiteSpace: 'pre-wrap' }}
                     noOfLines={[3]}
                      >
                      {matching.buyer.offering}
                      </Text>
                    
                    </VStack>
                  </Td>
                  <Td>
                    <VStack alignItems={"flex-start"}>
                      {matching.room &&
                        matching.room.length &&
                        matching.room.map((room) => (
                          <Box key={room.pk}>
                            <Text as="b" fontSize={"14px"}>
                              BRAND:
                            </Text>
                            <ManageCardModalBtn
                              pk={room.pk}
                              name={room.name}
                              year={room.year}
                              season={room.season}
                              homepage={room.homepage}
                              address={room.address}
                            />
                          </Box>
                        ))}
                      <Text as="b" fontSize={"14px"}>
                        NAME:
                      </Text>
                      <Text>
                        {matching.user.first_name} {matching.user.last_name}
                      </Text>
                      <Text as="b" fontSize={"14px"}>
                        EMAIL:
                      </Text>
                      <Text>{matching.user.email}</Text>
                      <Text as="b" fontSize={"14px"}>
                        COMPANY:
                      </Text>
                      <Text>{matching.user.company}</Text>
                      <Text as="b" fontSize={"14px"}>
                        WEBSITE:
                      </Text>
                      <Text>{matching.user.website}</Text>
                    </VStack>
                  </Td>
                  <Td>
                    {matching.not_canceled ? (
                      <Badge colorScheme="green">Matching</Badge>
                    ) : (
                      <Badge colorScheme="red">Canceled</Badge>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          </TableContainer>
        </VStack>
      </ProtectedPage>
    );
  };