import {
    Heading,
    Avatar,
    Box,
    Link,
    Text,
    VStack,
    HStack,
    Button,
    useColorModeValue,
	  useDisclosure,
    Tag,
    Grid, 
    GridItem
  } from '@chakra-ui/react';
  import useUserProfile from "../../lib/useUserProfile";
  import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
  import { useQuery } from "@tanstack/react-query";
  import { getBuyers } from "../../api";
  import { IBuyers } from "../../types";


  interface IBuyersProps {
    pk: number;
    company: string;
    country: string;
    city: string;
    offering: string;
    created_at: number;
    }

  export default function Buyer({
    pk,
    company,
    country,
    city,
    offering,
    created_at,
    }: IBuyersProps) {
        
    const { buyerPk } = useParams();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLoading, data } = useQuery<IBuyers>(
      ["buyer"], getBuyers);

    return (
        <Link 
        as={RouterLink}
        to={`/buyer/${pk}`}
        style={{ textDecoration: "none" }}
        >
        
        <Box
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'lg'}
          rounded={'lg'}
          p={4}
          textAlign={'center'}
          h={"13em"}
          >
        <HStack justify="space-between">
        <VStack p={1} w={"30%"}>
          <Avatar
            size={'md'}
            name={company} 
            right={2}
            top={6} 
            mb={4}
            pos={'relative'}
            _after={{
              content: '""',
              w: 3,
              h: 3,
              bg: 'green.300',
              border: '2px solid white',
              rounded: 'full',
              pos: 'absolute',
              bottom: 0,
              right: 2,
            }}
          />
          <Heading mt={10} fontSize={'md'} fontFamily={'body'}>
          @{company}
          </Heading>
          </VStack>

          <Box p={1} w={"70%"}>
          <Text
            noOfLines={[3]}
            textAlign={'start'}
            color={useColorModeValue('gray.700', 'gray.400')}
            px={1}
            >
            {offering}
          </Text>
          </Box>

          </HStack>
          <HStack mt={3}>
          <Tag
          borderRadius={100}
          fontSize={"xs"}
          bg={"#6B4EFF"}
          color={"white"}
		    >
        #{country}
        </Tag>
        <Tag 
          fontSize={"xs"}  
          borderRadius={100}>
				#{city}
		</Tag>
        
        <Text fontWeight={600} color={'gray.500'} fontSize={"sm"}>
            {created_at}
        </Text>
        
        </HStack>

        </Box>
    </Link>
    );
  }