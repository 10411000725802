import { BsSun, BsMoonStarsFill } from 'react-icons/bs';
import { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  LightMode,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  ToastId,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
  MenuGroup,
  MenuDivider,
  VStack,
  Link,
  LinkBox, 
  LinkOverlay,
  Flex,
  keyframes,
  Image,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useBreakpointValue,
} from "@chakra-ui/react";
import { 
  HamburgerIcon, 
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { useLocation, useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import { Text } from "@chakra-ui/react"
import useUser from "../lib/useUser";
import useUserProfile from "../lib/useUserProfile";
import { logOut } from "../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import logoLight from "../public/images/logob.png";
import logoDark from "../public/images/logodark.png";

  
  export default function Header() {
    const { userLoading, isLoggedIn, user } = useUser();
    const { profile } = useUserProfile();
    const location = useLocation().pathname;
    const {
      isOpen: isLoginOpen,
      onClose: onLoginClose,
      onOpen: onLoginOpen,
    } = useDisclosure();
    const {
      isOpen: isSignUpOpen,
      onClose: onSignUpClose,
      onOpen: onSignUpOpen,
    } = useDisclosure();
    const { toggleColorMode } = useColorMode();
    const { colorMode } = useColorMode();
    
    {/*다크모드 일때 로고 색상 바꾸기*/}
    const logoTextColor = useColorModeValue("black.500", "white.500");
    const logoColor = useColorModeValue("red.500", "red.500");
    const Icon = useColorModeValue(BsMoonStarsFill, BsSun);
    const toast = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const toastId = useRef<ToastId>();
    const mutation = useMutation(logOut, {
      onMutate: () => {
        toastId.current = toast({
          title: "Login out...",
          description: "Sad to see you go...",
          status: "loading",
          duration: 10000,
          position: "bottom-right",
        });
      },
      onSuccess: () => {
        if (toastId.current) {
          queryClient.refetchQueries(["me"]);
          toast.update(toastId.current, {
            status: "success",
            title: "Done!",
            description: "See you later!",
          });
        }
      },
    });
    const onLogOut = async () => {
      mutation.mutate();
    };
  
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    
    {/*아바타 리플 */}
    const size = '96px';
    const color = 'teal';
  
    const pulseRing = keyframes`
    0% {
      transform: scale(0.33);
    }
    40%,
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
    `;

    return (
      <Box bg={useColorModeValue('white.900', 'gray.800')} px={10}>

      <Flex
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}
          >

        <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -8 }}
            display={{ base: 'flex', md: 'none' }}
            >
            
        <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
        </Flex>

        <Flex flex={{ base: 10 }} 
        justify={{ base: 'center', md: 'start' }}>
       
        <Stack>
              {/*로고*/}
              <Link href="/" style={{ textDecoration: "none" }}>
                <Image
                  src={colorMode === "light" ? "/images/logob.png" : "/images/logodark.png"}
                  fallbackSrc={ colorMode === "light" ? "/images/logob.png" : "/images/logodark.png"}
                  style={{
                    width: "130px",
                    height: "70px",
                    padding: "20px",
                    minWidth: "130px",
                  }}
                />
              </Link>
        </Stack>
  
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>
      </Flex>
  
    {/*오른쪽 아바타 메뉴 시작*/}
    <Flex 
    ml={{ base: -3, sm: 0 }}
    alignItems={'center'}
    >
    <HStack 
    flex={{ base: 1, md: 0 }}
    justify={'flex-end'}
    direction={'row'}
    spacing={{ base: 0, md: 3 }}
    >
     
        <IconButton
            onClick={toggleColorMode}
            variant={"ghost"}
            aria-label="Toggle dark mode"
            icon={<Icon />}
        />
        {!userLoading ? (
          !isLoggedIn ? (
            <>
              <Button 
              fontSize={'sm'}
              onClick={onLoginOpen}
              >
                Log in
              </Button>
              <LightMode>
                <Button 
                display={{ base: 'none', md: 'inline-flex' }}
                fontSize={'sm'}
                onClick={onSignUpOpen} 
                color={'white'} 
                bg={'purple.400'}
                >
                Sign up
                </Button>
              </LightMode>
            </>
          ) : (
            <Box>
            <Menu>
              <MenuButton>

    {/*오른쪽 아바타 리플*/}  
      <Box
        as="div"
        position="relative"
        w={size}
        h={size}
        _before={{
          content: "''",
          position: 'absolute',
          display: 'block',
          width:{ base: '100%', md: '150%' },
          height:{ base: '100%', md: '150%' },
          boxSizing: 'border-box',
          marginLeft: { base: '-16.2%', md: '-33.2%' },
          marginTop: { base: '-0.15%', md: '-25%' },
          borderRadius: '50%',
          
          bgColor: color,
          animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
        }}>
            <Avatar 
            name={profile?.company} 
            src={profile?.avatar} 
            size={{ base: "sm", md: "md"}}
            right={{ base: 4, md: 2}}
            top={{ base: 8, md: 6}}
            />
       </Box>
  
              </MenuButton>
              <MenuList>
              <MenuGroup title='PROFILE'>
              
              <Link href="/users/myeditprofile">
                    <MenuItem>My Profile</MenuItem>
                  </Link>
                  <Link href="/users/changepw">
                    <MenuItem>Password</MenuItem>
                  </Link>
                  
              </MenuGroup>
              <MenuDivider />

              {profile?.brand && (
              <>
              <MenuGroup title='BRAND'>
              {/* 
              <Link href="/users/Wishlists">
                    <MenuItem>Wishlist</MenuItem>
              </Link>
              */}
              <Link href="/rooms/uploadrooms">
                <MenuItem>Upload Brand</MenuItem>
              </Link>
              
              <Link href="/manage-bookings">
                <MenuItem>Manage Bookings</MenuItem>
              </Link>

              <Link href="/buyer/home/brand/manager">
                <MenuItem>My Matchings(buying)</MenuItem>
              </Link>
             
              </MenuGroup>
              <MenuDivider />

              <MenuGroup title='BUSINESS(Only Brand)'>
              
              <Link href="/business">
                    <MenuItem>사업파트너 신청</MenuItem>
              </Link>
                
              
              <Link href="/business/status">
                    <MenuItem>신청결과</MenuItem>
              </Link>
             
              </MenuGroup>
              <MenuDivider />

              <MenuGroup title='MOIM(Only Brand)'>
              <Link href="/my-profile">
                    <MenuItem>내프로필</MenuItem>
              </Link>
              
               {/*
              {profile?.is_host ? (
              <Link href="/my-profile/moims/crew">
                    <MenuItem>크루로 참여한 모임</MenuItem>
              </Link>
                ) : null}
              {profile?.is_host ? (
              <Link href="/my-profile/moims/leader">
                    <MenuItem>리더로 참여한 모임</MenuItem>
              </Link>
                ) : null}
              {profile?.is_host ? (
              <Link href="/my-profile/moims/owner">
                    <MenuItem>내가 생성한 모임</MenuItem>
              </Link>
                ) : null}
                */}
              </MenuGroup>
              <MenuDivider />
              </>
                )}

              {profile?.showroom && profile?.multishop && profile?.department &&(
                <>
                  <MenuGroup title='PARTNERS'>
                    <Link href="/partners/upload">
                      <MenuItem>Upload partner</MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                </>
              )}

              {profile?.buyer &&(
                <>
                  <MenuGroup title='BUYER'>
                    <Link href="/buyer/home/upload">
                      <MenuItem>Upload Offer</MenuItem>
                    </Link>

                    <Link href="/buyer/home/buyer/manager">
                      <MenuItem>My Offers</MenuItem>
                    </Link>

                    <Link href="/mybookings">
                      <MenuItem>My Bookings(brand)</MenuItem>
                    </Link>
                  </MenuGroup>
                  <MenuDivider />
                </>
              )}
              

              <MenuGroup title='Help'>
                <Link href={'/faq'}>
                <MenuItem>FAQ</MenuItem>
                </Link>
              </MenuGroup>
              <MenuDivider />
            
                <MenuItem onClick={onLogOut}>Log out</MenuItem>

              </MenuList>
            </Menu>
            </Box>
          )
        ) : null}
    </HStack>
    <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
    <SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} />
    </Flex>

    {/*오른쪽 아바타 메뉴 끝*/}
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    );
  }
  
  const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  
    return (
      <Stack direction={'row'} spacing={4} mt={6}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={2}
                  href={navItem.href ?? '#'}
                  isExternal={navItem.target === '_blank'}
                  fontSize={'md'}
                  fontWeight={700}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>
  
              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
      </Stack>
    );
  };
  
  const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
      <Link
        href={href}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('purple.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'purple.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'purple.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    );
  };
  
  const MobileNav = () => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{md: 'none', lg: 'flex' }}
        justifyContent="flex-start"
        >
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    );
  };
  
  const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          as={Link}
          href={href ?? '#'}
          justify={'flex-start'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text
            fontWeight={500}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
              children.map((child) => (
                <Link key={child.label} py={2} href={child.href}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };
  
  interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
    target?: string;
  }
  
  const NAV_ITEMS: Array<NavItem> = [
    {
      label: 'HOME',
      href: '/',
    },
    {
      label: 'BRANDS',
      children: [
        {
          label: 'WOMENS',
          subLabel: 'Trending Design to inspire you',
          href: '/womens',
        },
        {
          label: 'MENS',
          subLabel: 'Up-and-coming Designers',
          href: '/mens',
        },
        {
          label: 'UNISEX',
          subLabel: 'The most trendy Korean designer',
          href: '/unisex',
        },
      ],
    },
    /* 파트너에 KOREA 부분 넣을떄 /partners/home/korea 주소 삽입*/
    {
      label: 'PARTNERS',
      children: [
        {
          label: 'WORLD',
          subLabel: 'List of world-famous showroom & multistore',
          href: '/partners/home/world',
        },
      ],
    },
    {
      label: 'BUYING',
      href: '/buyer/home',
    },
    {
      label: 'MAGAZINE',
      href: 'http://www.pashopmagazine.kr',
      target: '_blank',
    },
  
  ];

  