import { FaCamera, FaHeart, FaRegHeart, FaStar } from "react-icons/fa";
import {
  Box,
  Button,
  Grid,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
  Tag,
  Icon,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { useRef, useState, useEffect } from 'react';
import { FaCrown } from "react-icons/fa";

interface IRoomProps {
  imageUrl: string;
  name: string;
  year: string;
  nationality: string;
  season: string;
  rating: number;
  pk: number;
  isOwner: boolean;
  order: number;
  free: boolean;
  isNew: boolean;
  hot: boolean;
  best: boolean;
  premium: boolean;
  }
    
export default function Room({
  pk,
  imageUrl,
  name,
  rating,
  year,
  nationality,
  season,
  isOwner,
  order,
  isNew,
  hot,
  best,
  premium,
  }: IRoomProps) {
    
  const gray = useColorModeValue("gray.600", "gray.300");
  const navigate = useNavigate();
  const onCameraClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/rooms/${pk}/photos`);
  };
{/*하트 기능*/}
const [isFilled, setIsFilled] = useState<boolean[]>([]);

useEffect(() => {
  const savedIsFilled = localStorage.getItem(`isFilled-${pk}`);
  if (savedIsFilled !== null) {
    setIsFilled(savedIsFilled.split(',').map(str => str === 'true'));
  } else {
    setIsFilled(new Array(3).fill(false)); // 3개의 하트 상태 배열로 초기화
  }
}, [pk]);

function handleClick(index: number) {
  setIsFilled(prevState => {
    const newState = [...prevState];
    newState[index] = !newState[index];
    localStorage.setItem(`isFilled-${pk}`, newState.toString());
    return newState;
  });
}

  return (
    
      <VStack alignItems={"flex-start"}>
        <Link to={`/rooms/${pk}`}>
        <Box
            w="100%"
            position="relative"
            overflow={"hidden"}
            mb={3}
            rounded="2xl"
          >
          {imageUrl ? (
            <Image 
            objectFit={"cover"} 
            minH="20em" 
            width="300px" 
            height="300px"
            src={imageUrl} />
          ) : (
            <Box 
            minH="20em" 
            width="300px" 
            height="300px"
            w="100"
            position="relative"
            p={20} 
            bg="gray.300" >
              <Text fontWeight={600}>
              No Images {order}
              </Text>
            </Box>
            
          )}
          <Button
            variant={"unstyled"}
            position="absolute"
            top={0}
            right={0}
            onClick={onCameraClick}
            color="white"
          >
            {isOwner ? <FaCamera size="20px" /> : null}
          </Button>
        </Box>
        </Link>

        <Box>
          <Grid gap={2} templateColumns={"6fr 1fr"}>
          <HStack alignItems={"flex-start"}>
          {isNew ? 
          <Text
            bgGradient='linear(to-l, #1E90FF, #00BFFF)'
            bgClip='text'
            fontSize='sm'
            fontWeight='extrabold'
          >
          NEW
          </Text>
            : null
          }  
          {hot ? 
          <Text
            bgGradient='linear(to-l, #FF4500, #FF0000)'
            bgClip='text'
            fontSize='sm'
            fontWeight='extrabold'
          >
          HOT
          </Text>
            : null
          }  
          {best ? 
          <Text
            bgGradient='linear(to-l, #00FF7F, #32CD32)'
            bgClip='text'
            fontSize='sm'
            fontWeight='extrabold'
          >
          BEST
          </Text>
            : null
          }  
          {premium ? 
         <Box>
          <Flex direction='row' align='center'>
          <Text fontSize='sm' mr={1}>
            👑
          </Text>
          <Text
            bgGradient='linear(to-l, #DC143C, #800000)'
            bgClip='text'
            fontSize='sm'
            fontWeight='extrabold'
          >
          PREMIUM
          </Text>
          </Flex>
          </Box>
            : null
          }  
      </HStack>
      </Grid>
      
        <HStack spacing={1} alignItems="center" justifyContent="space-between">
            <Text 
             display={"block"} 
             as="b" 
             noOfLines={1} 
             fontSize="md" 
             textTransform='uppercase'
             overflow="hidden"
             >
              {name}
            </Text>
            <HStack>
            <FaStar size={12} />
            <Text fontSize={"sm"}>{rating}</Text>
            </HStack>
        </HStack>
        

          <Tag
          mt={"3"}
          borderRadius={100}
          fontSize={"xs"}
          bg={"#1B4EFF"}
          color={"white"}
          textTransform='uppercase'
		    >
          #{nationality}
        </Tag>
          <HStack mt={3}>
          <Tag
          borderRadius={100}
          fontSize={"xs"}
          bg={"#6B4EFF"}
          color={"white"}
          textTransform='uppercase'
		    >
          #{year}
        </Tag>
        <Tag 
          fontSize={"xs"}  
          borderRadius={100}
          textTransform='uppercase'
          >
          #{season}
        </Tag>
        
        {/*하트클릭*/}
        <HStack mt={3}>
          {Array.from({ length: 1 }).map((_, index) => (
            <Box key={index} onClick={() => handleClick(index)}>
              <Icon
                as={isFilled[index] ? FaHeart : FaRegHeart}
                color={isFilled[index] ? 'red.500' : 'gray.500'}
              />
            </Box>
          ))}
        </HStack>

        </HStack>
      </Box>

        {/*<Text fontSize={"sm"} color={gray}>
          <Text as="b">${price}</Text> / night
        </Text>*/}
        </VStack>
    
    
  );
}






