import { FaCamera, FaHeart, FaRegHeart, FaStar } from "react-icons/fa";
import {
  Box,
  Button,
  Grid,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
  Tag,
  Icon,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { useState, useEffect } from 'react';

interface ICategory {
    pk: number;
    name: string;
    kind: string;
  }

interface IPartnerProps {
  imageUrl: string;
  company: string;
  type: string;
  country: string;
  category: ICategory;
  pk: number;
  isOwner: boolean;
  order: number;
  }
  
export default function Partner({
  pk,
  imageUrl,
  company,
  type,
  country,
  category,
  isOwner,
  order,
  }: IPartnerProps) {
    

  const gray = useColorModeValue("gray.600", "gray.300");
  const navigate = useNavigate();
  const onCameraClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/rooms/${pk}/photos`);
  };

  {/*하트 기능*/}
const [isFilledpartner, setIsFilledpartner] = useState<boolean[]>([]);

useEffect(() => {
  const savedIsFilledpartner = localStorage.getItem(`isFilledpartner-${pk}`);
  if (savedIsFilledpartner !== null) {
    setIsFilledpartner(savedIsFilledpartner.split(',').map(str => str === 'true'));
  } else {
    setIsFilledpartner(new Array(3).fill(false)); // 3개의 하트 상태 배열로 초기화
  }
}, [pk]);

function handleClick(index: number) {
  setIsFilledpartner(prevState => {
    const newState = [...prevState];
    newState[index] = !newState[index];
    localStorage.setItem(`isFilledpartner-${pk}`, newState.toString());
    return newState;
  });
}

  return (
    
      <VStack alignItems={"flex-start"}>
        <Link to={`/partners/${pk}`}>
      <Box
          w="100%"
          position="relative"
          overflow={"hidden"}
          mb={3}
          rounded="2xl"
        >
          {imageUrl ? (
           <Image 
           objectFit={"cover"} 
           minH="20em" 
           width="300px" 
           height="300px"
           src={imageUrl} />
          ) : (
            <Box 
            minH="20em" 
            width="300px" 
            height="300px"
            w="100"
            position="relative"
            p={20} 
            bg="gray.300" >
              <Text fontWeight={600}>
              No Images {order}
              </Text>
            </Box>
          )}
          <Button
            variant={"unstyled"}
            position="absolute"
            top={0}
            right={0}
            onClick={onCameraClick}
            color="white"
          >
            {isOwner ? <FaCamera size="20px" /> : null}
          </Button>
        </Box>
        </Link>

        <Box>
          <Grid gap={2} templateColumns={"6fr 1fr"}>
            <Text 
            display={"block"} 
            as="b" 
            noOfLines={1} 
            fontSize="md" 
            textTransform='uppercase'
            overflow="hidden"
            >
              {company}
            </Text>

          </Grid>
          <Tag
          mt={"3"}
          borderRadius={100}
          fontSize={"xs"}
          bg={"#1B4EFF"}
          color={"white"}
          textTransform='uppercase'
		    >
                #{country}
        </Tag>
          <HStack mt={3}>
          
          <Tag
          borderRadius={100}
          fontSize={"xs"}
          bg={"#6B4EFF"}
          color={"white"}
          textTransform='uppercase'
		    >
                #{type}
        </Tag>
        <Tag 
          fontSize={"xs"}  
          borderRadius={100}
          textTransform='uppercase'
          >
				#{category.name}
		</Tag>

    {/*하트클릭*/}
    <HStack mt={3}>
          {Array.from({ length: 1 }).map((_, index) => (
            <Box key={index} onClick={() => handleClick(index)}>
              <Icon
                as={isFilledpartner[index] ? FaHeart : FaRegHeart}
                color={isFilledpartner[index] ? 'red.500' : 'gray.500'}
              />
            </Box>
          ))}
    </HStack>
        </HStack>
        </Box>

        </VStack>
    
  );
}






